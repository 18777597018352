import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../common/DataContext";
import { MAPBOX_TOKEN } from "../constants";
import "./styles.css";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapGL from "react-map-gl";
import viewCone from "../../images/viewCone.png";
import Hotkeys from "react-hot-keys";
import triangle from "../../images/triangle.png";
import threesixtyCone from "../../images/360-cone.png";
import viewCone360 from "../../images/viewCone360.png";
import { VectorTileLayerComponent } from "./MapComponents";
import { AuthContext } from "../../common/AuthContext";
export const Map = () => {
  const {
    mapRef,
    currentImagery,
    select_feature,
    cycle_feature,
    handleSetSelectedView,
    handle_autoplay,
    update_url,
    tilequery,
    findClosestFeatureByDistance,
    filter_features_by_properties,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);

  const [mapUrl, setMapUrl] = useState("mapbox://devinmarkley.public");
  useEffect(() => {
    if (!user) {
      setMapUrl("mapbox://devinmarkley.public");
    } else {
      setMapUrl("mapbox://kaart.quandary");
    }
    //   async function getOrgMap()  {
    //     fetcher("organization/map_url")
    //       .then((response) => {
    //         setMapUrl(response['url'])
    //       })
    //       .catch((error) => {
    //         console.log(`Network error:`, error);
    //       });
    //   };
    //   getOrgMap()
    // }
  }, [user]);

  const loadMapIcons = () => {
    try {
      const map = mapRef?.current?.getMap(); // Access the map object using useMap hook
      if (map) {
        map.loadImage(viewCone, function (error, image) {
          if (error) {
            console.error("Error loading viewCone.png:", error);
            return;
          }
          map.addImage("viewCone", image, { sdf: true });
        });
        map.loadImage(triangle, function (error, image) {
          if (error) {
            console.error("Error loading pointicon.png:", error);
            return;
          }
          map.addImage("pointicon", image, { sdf: true });
        });
        map.loadImage(threesixtyCone, function (error, image) {
          if (error) {
            console.error("Error loading threesixtyCone.png:", error);
            return;
          }
          map.addImage("threesixtyCone", image, { sdf: true });
        });
        map.loadImage(viewCone360, function (error, image) {
          if (error) {
            console.error("Error loading viewCone360.png:", error);
            return;
          }
          map.addImage("viewCone360", image, { sdf: true });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClick = async (e) => {
    e.preventDefault();
    let potentialFeatures = await tilequery(e.lngLat.lat, e.lngLat.lng, 25);
    try {
      potentialFeatures = potentialFeatures["features"];
    } catch (e) {
      return;
    }
    let filteredFeatures = filter_features_by_properties(potentialFeatures);
    let closestFeature = findClosestFeatureByDistance(filteredFeatures);
    select_feature(closestFeature);
    update_url(closestFeature);
  };

  const onload = () => {
    try {
      loadMapIcons();
      // Get the fragment identifier (everything after the '#')
      const fragmentIdentifier = window.location.hash.slice(1);

      // Split the fragment by 'key=' to get everything after 'key' (including the '=')
      const keyValue = fragmentIdentifier.split("key=")[1];

      // If there's data after 'key=', extract it, otherwise handle the case where 'key' is missing or at the end
      if (keyValue) {
        // You can further process 'keyValue' here (decode or parse if needed)
        let decodedkeyValue = decodeURIComponent(keyValue);
        const feature = JSON.parse(decodedkeyValue);
        select_feature(feature);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="map-viewer">
        <Hotkeys
          keyName="left,right,space,a,A,shift+a,shift+A,d,shift+left,shift+right,shift+d,ctrl+shift+left,ctrl+shift+right,ctrl+shift+a,ctrl+shift+d,q,e,1,2,3,4"
          allowRepeat
          onKeyDown={(keyName) => {
            switch (keyName) {
              case "space": {
                handle_autoplay();
                break;
              }

              case "1": {
                handleSetSelectedView("Left");
                break;
              }

              case "2": {
                handleSetSelectedView("Front");
                break;
              }
              case "3": {
                handleSetSelectedView("Right");
                break;
              }
              case "4": {
                handleSetSelectedView("360");
                break;
              }
              case "a":
              case "A":
              case "shift+a":
              case "shift+A":
                cycle_feature("down");
                break;

              case "d" || "D":
                cycle_feature("up");
                break;
              default:
                break;
            }
          }}
        >
          <ReactMapGL
            key="reactMap"
            ref={mapRef}
            onRender={(event) => event.target.resize()}
            dragRotate={false}
            touchPitch={false}
            reuseMap={true}
            maxPitch={75}
            width={"100%"}
            height={"100%"}
            mapboxAccessToken={MAPBOX_TOKEN}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            mapStyle={currentImagery}
            onClick={(e) => onClick(e)}
            onLoad={() => {
              onload();
            }}
            hash={"mapHash"}
          >
            <VectorTileLayerComponent
              key={"devinmarkley.quandary"}
              url={mapUrl}
              id={"devinmarkley.quandary"}
            />
          </ReactMapGL>
        </Hotkeys>
      </div>
    </>
  );
};
