export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoia2FhcnQiLCJhIjoiY20wbXdpNHBmMDc5YzJscHp5OWc5Nmg2dSJ9._-_qiltBRipYKB5WLVSLkA";

export const paint_style_line_geo = {
  "line-color": [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    "#FF9F52",
    "#040F3C",
  ],
  "line-opacity": 1.0,
  "line-width": 2.5,
};

export const paint_style_line = {
  "line-color": [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    "#FF9F52",
    "#040F3C",
  ],
  "line-opacity": 1.0,
  "line-width": 2.5,
};

export const imageryType = {
  SAT: "mapbox://styles/mapbox/satellite-v8",
  LIGHT: "mapbox://styles/mapbox/light-v8",
  DARK: "mapbox://styles/mapbox/dark-v8",
  STREETS: "mapbox://styles/mapbox/streets-v12",
  SATSTREETS: "mapbox://styles/mapbox/satellite-streets-v12",
  OUTDOORS: "mapbox://styles/mapbox/outdoors-v11",
};
Object.freeze(imageryType); // this line makes the Enum immutable

/*
If these enums are modified, please update relevant 
enums/intflags in backend/api_utils/decorators.py
*/
export const TeamRole = {
  VIEWER: 2,
  CREATOR: 4,
  VIEW_CREATE: 8,
};
Object.freeze(TeamRole);

export const TeamMemberFunction = {
  MEMBER: 1,
  MANAGER: 2,
};
Object.freeze(TeamMemberFunction);

export const intToEnumType = (int, enumType) => {
  for (const _enum in enumType) {
    if (enumType.hasOwnProperty(_enum) && enumType[_enum] === int) {
      return _enum;
    }
  }
};

// PROD;
export const API_URL = "https://viewer.kaart.com/api/";
export const SSO_URL = "https://my.kaart.com/api/";

// //DEV
// export const API_URL = "http://dev.localhost:5002/api/";
// export const SSO_URL = "http://dev.localhost:5001/api/";
