import React, { useContext, useEffect, useState } from "react";
import csvtojson from "csvtojson";
import { poster } from "../../calls";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import useToggle from "../../hooks/useToggle.js";
import { Table, TableBody } from "@mui/material";
import { Navigate } from "react-router-dom";
import "./styles.css";
import {
  AddUserModal,
  DeleteUserModal,
  ModifyUserModal,
  USER_TABLE_HEAD,
} from "./userComponents";
import {
  ButtonDivComponent,
  CardMediaStyle,
  ListHead,
  TableCard,
  TableEntryCell,
  TableEntryRow,
  TopDiv,
} from "../commonComponents/commonComponents";

export const AdminUsersPage = () => {
  const {
    fetchOrgUsers,
    orgUsers,
    setOrgUsers,
    removeUser,
    modifyUser,
    inviteUser,
    isLoading,
    toggleIsLoading,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);
  const [addOpen, toggleAddOpen] = useToggle(false);
  const [deleteOpen, toggleDeleteOpen] = useToggle(false);
  const [modifyOpen, toggleModifyOpen] = useToggle(false);
  const [userSelected, setUserSelected] = useState(null);
  const [userSelectedName, setUserSelectedName] = useState(null);
  const [roleSelected, setRoleSelected] = useState(null);
  const [inviteEmail, setInviteEmail] = useState(null);
  const [form, setForm] = useState({ name: "", desc: "" });
  const [redirect, setRedirect] = useState(false);
  // const fileInputRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      toggleIsLoading();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user === null) {
      setRedirect(true);
    }
    if (user !== null && user.role !== "admin") {
      setRedirect(true);
    }
    fetchOrgUsers();
    // eslint-disable-next-line
  }, []);

  const handleAddOpen = () => {
    toggleAddOpen(!addOpen);
  };

  const handleDeleteOpen = () => {
    if (userSelected !== null) {
      toggleDeleteOpen();
    } else {
      alert("No User Selected");
    }
  };

  const handleModifyOpen = () => {
    if (userSelected !== null) {
      toggleModifyOpen();
    } else {
      alert("No User Selected");
    }
  };

  const handleSetUserSelected = (id, name) => {
    setUserSelected(id);
    setUserSelectedName(name);
  };

  const handleRoleSelected = (e) => {
    setRoleSelected(e);
  };

  const handleSetInviteEmail = (e) => {
    setInviteEmail(e);
  };

  const do_modify_user = () => {
    if (userSelected) {
      modifyUser(userSelected, roleSelected);
      toggleModifyOpen();
    }
  };

  const do_remove_user = () => {
    if (userSelected) {
      removeUser(userSelected);
      toggleDeleteOpen();
    }
  };

  const do_invite_user = () => {
    if (inviteEmail) {
      inviteUser(inviteEmail, "mikro");
      alert("Invitation Email Sent");
      toggleAddOpen();
    }
  };

  const handleFileSelect = async (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContents = event.target.result;
        const json = await csvtojson().fromString(fileContents);
        try {
          let response = poster(json, "user/import_users");
        } catch (error) {
          console.error("Error importing users:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <>
      <AddUserModal
        addOpen={addOpen}
        form={form}
        handleAddOpen={handleAddOpen}
        handleSetInviteEmail={handleSetInviteEmail}
        setForm={setForm}
        do_invite_user={do_invite_user}
      />
      <DeleteUserModal
        deleteOpen={deleteOpen}
        selected_user={userSelected}
        userSelectedName={userSelectedName}
        handleDeleteOpen={handleDeleteOpen}
        do_remove_user={do_remove_user}
      />
      <ModifyUserModal
        modifyOpen={modifyOpen}
        roleSelected={roleSelected}
        handleModifyOpen={handleModifyOpen}
        handleRoleSelected={handleRoleSelected}
        do_modify_user={do_modify_user}
      />

      <TopDiv>
        <ButtonDivComponent
          handleFileSelect={handleFileSelect}
          role={"admin"}
          button1={true}
          button2={true}
          button3={true}
          button4={true}
          button1_text={"Add"}
          button2_text={"Edit"}
          button3_text={"Delete"}
          button4_text={"Import"}
          button1_action={handleAddOpen}
          button2_action={handleModifyOpen}
          button3_action={handleDeleteOpen}
          button4_action={() => {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = ".csv";
            input.onchange = handleFileSelect;
            input.click();
          }}
        />
      </TopDiv>
      <TableCard
        style={{
          height: "80vh",
        }}
      >
        <CardMediaStyle />
        <Table>
          <ListHead
            headLabel={USER_TABLE_HEAD}
            tableData={orgUsers}
            updateData={setOrgUsers}
            vw={"40vw"}
          />
          <TableBody style={{ width: "100vw" }}>
            {orgUsers &&
              orgUsers.slice().map((row) => {
                const { value, name, role, joined } = row;
                return (
                  <TableEntryRow
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(145, 165, 172, 0.5)",
                        cursor: "pointer",
                      },
                    }}
                    align="center"
                    key={row}
                    tabIndex={-1}
                    onClick={() => handleSetUserSelected(value, name)}
                    selected={userSelected === value}
                  >
                    <TableEntryCell entry={<strong>{name}</strong>} />
                    <TableEntryCell entry={role} />
                    <TableEntryCell entry={joined} />
                  </TableEntryRow>
                );
              })}
          </TableBody>
        </Table>
      </TableCard>

      {!redirect ? null : <Navigate push to="/login" />}
    </>
  );
};
