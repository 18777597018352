import styled from "styled-components";

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
`;

export const Image = styled.img`
  right: 0;
  color: #fff;
  width: 100%;
  height: 100%;
`;

export const Fullscreen = styled.button`
  top: 1%;
  right: 1%;
  font-size: 20px;
  position: absolute;
  background-color: transparent;
  color: transparent;
  border: none;
  margin-right: 0;
  margin-top: 0;
  border-radius: 50%;
  max-width: 0.1vw;
  max-height: 0.1vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: -5;
`;

export const Button = styled.button`
  box-sizing: inherit;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
  text-transform: none;
  border-radius: 6px;
  margin-top: 1em;
  margin-bottom: 1em;
  color: white;
  padding: 10px 10px;
  border: none;

  &:hover {
    opacity: 0.5;
  }

  background-color: black;
  margin-left: 0.5em;
  top: 0;
  left: 0;
  position: absolute;
`;
