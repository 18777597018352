import React, { useContext } from "react";
import Select from "react-select";
import { useEffect, useState } from "react";
import { Input, TextArea, SelectWrapper } from "../../AdminTeamsPage/styles";
import { Modal, Divider } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TeamMembersTable } from "./teamUsersTable";
import { ProjectTeamsTable } from "../../../components/TableHubs/TripHub/projectTeamsTable";
import {
  CancelButton,
  CloseButton,
  ConfirmButton,
  ModalWrapper,
  SectionTitle,
  SectionSubtitle,
} from "../../commonComponents/commonComponents";
import { DataContext } from "../../../common/DataContext";

export const TEAM_TABLE_HEAD = [
  { id: "name", label: <strong>Team Name</strong>, alignLeft: true },
  { id: "desc", label: <strong>Description</strong>, alignLeft: true },
  { id: "created", label: <strong>Date Created</strong>, alignLeft: true },
  {
    id: "assignments",
    label: <strong>Total Assignments</strong>,
    alignLeft: true,
  },
  { id: "members", label: <strong>Total Members</strong>, alignLeft: true },
];

// ADD TEAM MODAL //
export const AddTeamModal = (props) => {
  const { createTeam } = useContext(DataContext);
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.addOpen) {
        props.handleAddOpen(); // Call the function to close the modal
      }
    }
  };

  const handle_create_team = () => {
    createTeam(props.form.name, props.form.desc, props.type);
    props.handleAddOpen();
  };

  return (
    <Modal open={props.addOpen} key="add" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleAddOpen} />
        <SectionTitle title_text={<strong>Create a new team</strong>} />
        <SectionSubtitle
          subtitle_text={
            <strong>The new team will be added to your organization</strong>
          }
        />
        <Divider />
        <TeamNameField form={props.form} setForm={props.setForm} />
        <TeamDescField form={props.form} setForm={props.setForm} />
        <Divider />
        <div style={{ marginBottom: "1vh" }}>
          <AddTeamButtons
            form={props.form}
            handleAddOpen={props.handleAddOpen}
            handle_create_team={handle_create_team}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};

// ADD TEAM BUTTONS //
export const AddTeamButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <CancelButton
        cancel_action={props.handleAddOpen}
        cancel_text={"Cancel"}
      />
      <ConfirmButton
        confirm_text={"Create"}
        confirm_action={props.handle_create_team}
      />
    </div>
  );
};

// TEAM NAME FIELD //
export const TeamNameField = (props) => {
  return (
    <Input
      type="text"
      placeholder="Team Name"
      name="name"
      style={{ marginLeft: "2.5vw" }}
      value={props.form.name}
      onChange={(e) => {
        props.setForm({ ...props.form, [e.target.name]: e.target.value });
      }}
    />
  );
};

// TEAM DESCRIPTION FIELD //
export const TeamDescField = (props) => {
  return (
    <TextArea
      style={{
        paddingLeft: "1vw",
        paddingRight: "1vw",
        margin: "auto",
        marginTop: "1vh",
        marginBottom: "1vh",
      }}
      rows="4"
      placeholder="Description"
      name="desc"
      value={props.form.desc}
      onChange={(e) => {
        props.setForm({ ...props.form, [e.target.name]: e.target.value });
      }}
    />
  );
};

// DELETE TEAM MODAL  //
export const DeleteTeamModal = (props) => {
  const { deleteTeam } = useContext(DataContext);
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.deleteOpen) {
        props.handleDeleteOpen(); // Call the function to close the modal
      }
    }
  };

  const handle_delete_team = () => {
    deleteTeam(props.teamSelected);
    props.setTeamSelected(null);
    props.handleDeleteOpen();
  };
  return (
    <Modal open={props.deleteOpen} key="delete" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleDeleteOpen} />
        <SectionTitle
          title_text={
            <strong>
              Are you sure you want to delete {props.teamSelectedName}?
            </strong>
          }
        />
        <div style={{ marginBottom: "1vh", marginTop: "3vh" }}>
          <DeleteTeamButtons
            handleDeleteOpen={props.handleDeleteOpen}
            handle_delete_team={handle_delete_team}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};

// DELETE TEAM BUTTONS //
export const DeleteTeamButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <CancelButton
        cancel_action={props.handleDeleteOpen}
        cancel_text={"Cancel"}
      />
      <ConfirmButton
        confirm_action={props.handle_delete_team}
        confirm_text={"Delete"}
      />
    </div>
  );
};

// MODIFY PROJECT MODAL //
export const ModifyTeamModal = (props) => {
  const { modifyTeamDetails } = useContext(DataContext);
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.modifyOpen) {
        props.handleModifyOpen(); // Call the function to close the modal
      }
    }
  };

  const handle_modify_team_details = () => {
    modifyTeamDetails(props.teamSelected, props.form.name, props.form.desc);
    props.handleModifyOpen();
  };

  return (
    <Modal open={props.modifyOpen} key="modify" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleModifyOpen} />
        <SectionTitle
          title_text={<strong>Edit Team {props.teamSelectedName}</strong>}
        />
        <Tabs
          style={{
            textAlign: "center",
            width: "100%",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <TabList className="tabheader">
            <Tab selectedClassName="activetab">
              <strong>Name & Details</strong>
            </Tab>
            <Tab selectedClassName="activetab">
              <strong>Team Members</strong>
            </Tab>
          </TabList>
          <TabPanel>
            <TeamDetailsSection form={props.form} setForm={props.setForm} />
            <Divider />
            <ModifyTeamButtons
              form={props.form}
              handleModifyOpen={props.handleModifyOpen}
              do_modify_team_details={handle_modify_team_details}
            />
          </TabPanel>
          <TabPanel>
            <TeamMembersTable
              members={"assigned"}
              team_id={props.teamSelected}
              teamSelectedName={props.teamSelectedName}
              close_action={props.handleModifyOpen}
            />
          </TabPanel>
        </Tabs>
      </ModalWrapper>
    </Modal>
  );
};

// TEAM DETAILS SECTION //
export const TeamDetailsSection = (props) => {
  return (
    <>
      <SectionTitle title_text={<strong>Team Name</strong>} />
      <Input
        style={{ marginBottom: "2vh" }}
        value={props.form.name}
        type="text"
        name="name"
        placeholder={props.form.name}
        onChange={(e) => {
          props.setForm({ ...props.form, [e.target.name]: e.target.value });
        }}
      />
      <SectionTitle title_text={<strong>Description</strong>} />
      <TextArea
        style={{ margin: "auto", marginBottom: "2vh" }}
        value={props.form.desc}
        rows="4"
        name="desc"
        placeholder={props.form.desc}
        onChange={(e) => {
          props.setForm({ ...props.form, [e.target.name]: e.target.value });
        }}
      />
    </>
  );
};

// MODIFY TEAM BUTTONS //
export const ModifyTeamButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        marginBottom: "2vh",
      }}
    >
      <CancelButton
        cancel_action={props.handleModifyOpen}
        cancel_text={"Cancel"}
      />
      <ConfirmButton
        confirm_action={props.do_modify_team_details}
        confirm_text={"Accept"}
      />
    </div>
  );
};

// ASSIGN TEAM MODAL //
export const AssignTeamModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.assignOpen) {
        props.handleAssignOpen(); // Call the function to close the modal
      }
    }
  };
  return (
    <Modal open={props.assignOpen} key="assign" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleAssignOpen} />
        <SectionTitle
          title_text={<strong>Change Team Project Assignments</strong>}
        />
        <div style={{ marginBottom: "3vh" }}>
          <SectionSubtitle
            subtitle_text={
              <strong>
                The selected teams will be assigned to: {props.projectName}
              </strong>
            }
          />
        </div>
        <ProjectTeamsTable project_id={props.project_selected} assign={true} />
        <AssignTeamButtons
          form={props.form}
          cancel_action={props.handleAssignOpen}
          confirm_text={"Add"}
          assign_action={props.handle_assign_team}
          assignSelected={props.assignSelected}
        />
      </ModalWrapper>
    </Modal>
  );
};

// ASSIGN TEAM MODAL //
export const UnassignTeamModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.unassignOpen) {
        props.handleUnassignOpen(); // Call the function to close the modal
      }
    }
  };
  return (
    <Modal open={props.unassignOpen} key="unassign" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleUnassignOpen} />
        <SectionTitle title_text={<strong>Unassign team</strong>} />
        <div style={{ marginBottom: "2vh" }}>
          <SectionSubtitle
            subtitle_text={
              <strong>
                Are you sure you want to remove {props.teamName} from{" "}
                {props.projectName}?
              </strong>
            }
          />
        </div>
        <AssignTeamButtons
          form={props.form}
          cancel_action={props.handleUnassignOpen}
          assign_action={props.do_unassign_team}
          confirm_text={"Remove"}
        />
      </ModalWrapper>
    </Modal>
  );
};

// SELECT PROJECT SECTION //
export const SelectProjectArea = (props) => {
  return (
    <>
      <SectionTitle
        title_text={"Assigned Projects"}
        style={{ marginBottom: "2vh" }}
      />
      <SelectWrapper style={{ marginBottom: "2vh" }}>
        <Select
          isClearable
          value={props.unassignSelected}
          onChange={(e) => {
            props.setUnassignSelected(e);
          }}
          options={props.assignedProjects}
        />
      </SelectWrapper>
      <SectionTitle
        title_text={"Other Projects"}
        style={{ marginBottom: "2vh" }}
      />
      <SelectWrapper style={{ marginBottom: "2vh" }}>
        <Select
          isClearable
          value={props.assignSelected}
          onChange={(e) => {
            props.setAssignSelected(e);
          }}
          options={props.unassignedProjects}
        />
      </SelectWrapper>
    </>
  );
};

// ASSIGN TEAM BUTTONS //
export const AssignTeamButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        marginBottom: "2vh",
      }}
    >
      <CancelButton
        cancel_action={props.cancel_action}
        cancel_text={"Cancel"}
      />
      <ConfirmButton
        confirm_text={props.confirm_text}
        confirm_action={props.assign_action}
      />
    </div>
  );
};
