import { AuthContext } from "../../common/AuthContext";
import { DataContext } from "../../common/DataContext";
import { PreloaderIcon } from "../../components/Preloader";
import { SSOControl } from "../../components/SSOControl";
import useToggle from "../../hooks/useToggle";
import React, { useContext, useState, useEffect } from "react";
import kaartLogo from "../../images/20-KAART-Color.svg";
import { Navigate } from "react-router-dom";
import {
  LoginButton,
  LoginForm,
  LoginImage,
  LoginInput,
  LoginPage,
} from "./styles";

export const Login = () => {
  const { fetching, isLoading, toggleIsLoading } = useContext(DataContext);
  const { user, SSO_Login, loginRedirect } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, toggleRedirect] = useToggle(false);

  useEffect(() => {
    if (isLoading) {
      toggleIsLoading();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && user.role) {
      toggleRedirect();
    }
    // eslint-disable-next-line
  }, [user]);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <LoginPage>
        <LoginForm
          //TRIGGERS THE LOGIN API CALL ON FORM SUBMIT
          onSubmit={(e) => {
            e.preventDefault();
            SSO_Login(email, password);
          }}
        >
          <LoginImage src={kaartLogo} alt="Kaart Logo" />
          <LoginInput
            name="email"
            type="text"
            autoComplete="username"
            placeholder="Enter your email"
            value={email}
            onChange={onEmailChange}
          />
          <LoginInput
            name="password"
            type="password"
            autoComplete="current-password"
            placeholder="Enter your password"
            value={password}
            onChange={onPasswordChange}
          />
          <LoginButton type="submit">
            {fetching ? <PreloaderIcon /> : "Login"}
          </LoginButton>
        </LoginForm>
        {/* <div>---------------------- or ----------------------</div> */}
        <SSOControl integrations="viewer" />
      </LoginPage>
      {!redirect ? null : <Navigate push to={loginRedirect} />}
    </>
  );
};
