// IMPORTS
import React, { useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";
import { API_URL } from "../../components/constants";
import { CardMediaStyle } from "../../components/commonComponents/commonComponents";
import { TableCell, ImageInfoTable, TableTitle, CloseIcon } from "./styles";

//COMPONENT DECLARATION & SETUP - IMAGE NOTES & METADATA WIDGET ON MAP PAGE
export const Metablock = (props) => {
  //STATES FROM DATA CONTEXT

  const {
    imageDetails,
    handleToggleMetaBlock,
    sequenceFilename,
    sequenceDate,
    selectedSequence,
  } = useContext(DataContext);

  // useEffect(() => {
  //   console.log(imageDetails)
  //   // eslint-disable-next-line
  // }, [imageDetails]);

  //COMPONENT RENDER
  return (
    <>
      {props.selectionType === "image" ? (
        <>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              zIndex: "999",
              height: "auto",
            }}
          >
            <CardMediaStyle />
            <ImageInfoTable style={{ marginLeft: "1vw", height: "auto" }}>
              <CloseIcon
                onClick={() => handleToggleMetaBlock()}
                style={{ position: "absolute", top: 0, right: "24px" }}
              />

              <TableCell style={{ textAlign: "left" }}>
                <TableTitle>Original Video:</TableTitle>{" "}
                {imageDetails && imageDetails.original_video
                  ? imageDetails.original_video
                  : "N/A"}
              </TableCell>

              <TableCell style={{ textAlign: "left" }}>
                <TableTitle>Sequence ID:</TableTitle>
                {imageDetails && imageDetails.sequence_id}
              </TableCell>

              <TableCell style={{ textAlign: "left" }}>
                <TableTitle>Date:</TableTitle>{" "}
                {imageDetails ? imageDetails.timestamp : ""}
              </TableCell>

              <TableCell style={{ textAlign: "left" }}>
                <TableTitle>Latitude:</TableTitle>{" "}
                {imageDetails && imageDetails.lat ? imageDetails.lat : ""}
              </TableCell>
              <TableCell style={{ textAlign: "left" }}>
                <TableTitle>Longitude:</TableTitle>{" "}
                {imageDetails && imageDetails.long ? imageDetails.long : ""}
              </TableCell>
            </ImageInfoTable>
          </div>
        </>
      ) : props.selectionType === "sequence" ? (
        <>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              zIndex: "999",
              height: "auto",
            }}
          >
            <CardMediaStyle />
            <ImageInfoTable
              style={{
                marginLeft: "1vw",
                marginTop: "2vh",
                height: "auto",
                // backgroundColor:'lightcoral'
              }}
            >
              <CloseIcon
                onClick={(e) => handleToggleMetaBlock(e)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: "24px",
                  zIndex: "999",
                }}
              />

              <TableCell style={{ textAlign: "left" }}>
                <TableTitle>Original Video:</TableTitle>{" "}
                {sequenceFilename && sequenceFilename
                  ? sequenceFilename
                  : "N/A"}
              </TableCell>

              <TableCell style={{ textAlign: "left" }}>
                <TableTitle>Sequence ID:</TableTitle>
                {selectedSequence && selectedSequence}
              </TableCell>

              <TableCell style={{ textAlign: "left" }}>
                <TableTitle>First Image Date & Time:</TableTitle>{" "}
                {sequenceDate ? sequenceDate : ""}
              </TableCell>
            </ImageInfoTable>
          </div>
        </>
      ) : null}
    </>
  );
};
