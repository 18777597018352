import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logouticon from "../../images/right-from-bracket-solid.svg";
import mapicon from "../../images/map-location-dot-solid.svg";
import upload_icon from "../../images/cloud-arrow-up-solid.svg";
import team_icon from "../../images/people-group-solid.svg";
import users_icon from "../../images/user-solid.svg";
import drive_icon from "../../images/car-side-solid.svg";
import expand_icon from "../../images/Open Menu.svg";
import minimize_icon from "../../images/Close Menu.svg";
import "./styles.css";

import {
  Header,
  KaartLogoOpen,
  MenuItem,
  MenuItemTop,
  ProjectIcon,
  ProjectIconContainer,
  RoleBarWrapper,
  RoleHeader,
  RoleSubHeader,
} from "./styles.js";

const Sidebar = (props) => {
  // COMPONENT STATES & SETTERS //
  const [role, setRole] = useState("");
  const [name, setName] = useState("");

  // DATA CONTEXT STATES AND FUNCTIONS //
  const { sidebarOpen, handleSetSidebarState } = useContext(DataContext);

  const { user, logout } = useContext(AuthContext);

  useEffect(() => {
    if (user && user.name && user.role) {
      setName(user.name);
      setRole(user.role);
    }
  }, [user]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          Width: "15vw",
          height: "100%",
          backgroundColor: "white",
          boxShadow: "1px 1px 2px 1px gray",
          alignItems: "left",
          zIndex: 999,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: sidebarOpen ? "flex-end" : "flex-start",
          }}
        >
          <div onClick={() => handleSetSidebarState()}>
            <img
              style={{
                height: "4vh",
                width: "4vw",
              }}
              src={sidebarOpen ? minimize_icon : expand_icon}
              alt="whitelogo"
            />
          </div>
        </div>
        {sidebarOpen ? (
          <MenuItemTop>
            <RoleBarWrapper>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <KaartLogoOpen />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <RoleHeader>{name}</RoleHeader>
                  <RoleSubHeader>{role}</RoleSubHeader>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "black",
                  height: ".05vh",
                }}
              />
            </RoleBarWrapper>
          </MenuItemTop>
        ) : (
          <></>
        )}
        <NavLink to="/viewer" style={{ textDecoration: "none" }}>
          <MenuItem>
            <ProjectIconContainer>
              <ProjectIcon src={mapicon} />
            </ProjectIconContainer>
            <Header>{sidebarOpen ? "Map" : ""}</Header>
          </MenuItem>
        </NavLink>
        {/*{role === "admin" ? (*/}
        {/*  <>*/}
        {/*    /!*<NavLink to={"/AdminDash"} style={{ textDecoration: "none" }}>*!/*/}
        {/*    /!*  <MenuItem>*!/*/}
        {/*    /!*    <ProjectIconContainer>*!/*/}
        {/*    /!*      <ProjectIcon src={dashicon} />*!/*/}
        {/*    /!*    </ProjectIconContainer>*!/*/}
        {/*    /!*    <Header>{sidebarOpen ? "Dashboard" : ""}</Header>*!/*/}
        {/*    /!*  </MenuItem>*!/*/}
        {/*    /!*</NavLink>*!/*/}
        {/*  </>*/}
        {/*) : (*/}
        {/*  <>*/}
        {/*    <NavLink to={"/dashboard"} style={{ textDecoration: "none" }}>*/}
        {/*      <MenuItem>*/}
        {/*        <ProjectIconContainer>*/}
        {/*          <ProjectIcon src={dashicon} />*/}
        {/*        </ProjectIconContainer>*/}
        {/*        <Header>{sidebarOpen ? "Dashboard" : ""}</Header>*/}
        {/*      </MenuItem>*/}
        {/*    </NavLink>*/}
        {/*  </>*/}
        {/*)}*/}
        {role === "admin" ? (
          <>
            <NavLink to={"/AdminTripPage"} style={{ textDecoration: "none" }}>
              <MenuItem>
                <ProjectIconContainer>
                  <ProjectIcon src={drive_icon} />
                </ProjectIconContainer>
                <Header>{sidebarOpen ? "Projects" : ""}</Header>
              </MenuItem>
            </NavLink>
          </>
        ) : null}

        {role === "admin" ? (
          <>
            <NavLink to="/AdminUsersPage" style={{ textDecoration: "none" }}>
              <MenuItem>
                <ProjectIconContainer>
                  <ProjectIcon src={users_icon} />
                </ProjectIconContainer>
                <Header>{sidebarOpen ? "Users" : ""}</Header>
              </MenuItem>
            </NavLink>

            <NavLink to="/AdminTeamsPage" style={{ textDecoration: "none" }}>
              <MenuItem>
                <ProjectIconContainer>
                  <ProjectIcon src={team_icon} />
                </ProjectIconContainer>
                <Header>{sidebarOpen ? "Teams" : ""}</Header>
              </MenuItem>
            </NavLink>

            <NavLink to="/AdminUploadPage" style={{ textDecoration: "none" }}>
              <MenuItem>
                <ProjectIconContainer>
                  <ProjectIcon src={upload_icon} />
                </ProjectIconContainer>
                <Header>{sidebarOpen ? "Uploads" : ""}</Header>
              </MenuItem>
            </NavLink>
          </>
        ) : null}

        <NavLink to="/login" style={{ textDecoration: "none" }}>
          <MenuItem onClick={logout}>
            <ProjectIconContainer>
              <ProjectIcon onClick={logout} src={logouticon} />
            </ProjectIconContainer>
            <Header onClick={logout}>{sidebarOpen ? "Log Out" : ""}</Header>
          </MenuItem>
        </NavLink>

        {/*<MenuItem href={kaart_url} target="_blank">*/}
        {/*  <ProjectIconContainer>*/}
        {/*    <ProjectIcon href={kaart_url} target="_blank" src={leftArrow} />*/}
        {/*  </ProjectIconContainer>*/}
        {/*  <Header href={kaart_url} target="_blank">*/}
        {/*    {sidebarOpen ? "Kaart.com" : ""}*/}
        {/*  </Header>*/}
        {/*</MenuItem>*/}
      </div>
    </>
  );
};

// COMPONENT EXPORT //
export default Sidebar;
