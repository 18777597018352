import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../common/DataContext";
import { Table, TableBody } from "@mui/material";
import { TEAM_TABLE_HEAD } from "./teamComponents";
import {
  CardMediaStyle,
  ListHead,
  TableCard,
  TableEntryCell,
  TableEntryRow,
} from "../../../components/commonComponents/commonComponents";

export const DriveTeamsTab = (props) => {
  const { driveTeams, setDriveTeams } = useContext(DataContext);

  // useEffect(() => {
  //   fetchAdminTeams();
  // }, []);

  return (
    <>
      <TableCard
        style={{
          boxShadow: "1px 1px 6px 2px gray",
          height: "80vh",
          overflowY: "scroll",
        }}
      >
        <CardMediaStyle />
        <Table>
          <ListHead
            headLabel={TEAM_TABLE_HEAD}
            tableData={driveTeams}
            updateData={setDriveTeams}
            vw={"40vw"}
          />
          <TableBody>
            {driveTeams &&
              driveTeams.slice().map((row) => {
                const {
                  id,
                  name,
                  description,
                  date_created,
                  total_team_members,
                  total_projects,
                } = row;
                return (
                  <TableEntryRow
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(145, 165, 172, 0.5)",
                        cursor: "pointer",
                      },
                    }}
                    align="center"
                    key={row}
                    tabIndex={-1}
                    onClick={() =>
                      props.handleSetTeamSelected(
                        id,
                        name,
                        description,
                        "drive",
                      )
                    }
                    selected={props.teamSelected === id}
                  >
                    <TableEntryCell entry={<strong>{name}</strong>} />
                    <TableEntryCell entry={description} />
                    <TableEntryCell entry={date_created} />
                    <TableEntryCell entry={total_projects} />
                    <TableEntryCell entry={total_team_members} />
                  </TableEntryRow>
                );
              })}
          </TableBody>
        </Table>
      </TableCard>
    </>
  );
};
