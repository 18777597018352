// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-viewer {
  height: 100%;
  width: 100%;
}

.tooltip_image {
  height: 80px;
  width: 80px;
}

.react-tabs__tab {
  display: inline-block;
  padding: 6px 55px;
  margin: 5px;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #000;
  transition: all 0.5s ease;
  font-size: 1rem;
}

.react-tabs__tab:hover {
  /* background: rgba(145,165,172,0.2); */
  transition: all 0.5s ease;
}

.react-tabs__tab--selected {
  font-weight: bold;
  border-bottom: 5px solid #253e45;
  color: black;
  border-radius: 2px;
  transition: left 0.5s ease;
}

.react-tabs__tab-list {
  border-bottom: none;
  margin: 3px 0 5px;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminTripsPage/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,uCAAuC;EACvC,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;EAChC,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".map-viewer {\n  height: 100%;\n  width: 100%;\n}\n\n.tooltip_image {\n  height: 80px;\n  width: 80px;\n}\n\n.react-tabs__tab {\n  display: inline-block;\n  padding: 6px 55px;\n  margin: 5px;\n  border-top: none;\n  border-left: none;\n  border-right: none;\n  color: #000;\n  transition: all 0.5s ease;\n  font-size: 1rem;\n}\n\n.react-tabs__tab:hover {\n  /* background: rgba(145,165,172,0.2); */\n  transition: all 0.5s ease;\n}\n\n.react-tabs__tab--selected {\n  font-weight: bold;\n  border-bottom: 5px solid #253e45;\n  color: black;\n  border-radius: 2px;\n  transition: left 0.5s ease;\n}\n\n.react-tabs__tab-list {\n  border-bottom: none;\n  margin: 3px 0 5px;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
