import React, { useContext } from "react";
import { Placeholder, PlaceholderImage } from "./styles";
import kaartLogo from "../../images/20-KAART-Color.svg";
import { DataContext } from "../../common/DataContext";
import "./styles.css";

export const ImagePlaceholder = (props) => {
  const { isMapSwap } = useContext(DataContext);

  const commonStyle = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    fontSize: "56px",
    marginBottom: "0vh",
    paddingBottom: "0vh",
  };

  const specificStyle = isMapSwap
    ? {
        ...commonStyle,
        width: "auto",
        left: "36vw",
        top: "15vh",
        overflow: "hidden",
      }
    : {
        ...commonStyle,
        left: "4vw",
        width: "auto",
        // margin: "auto",
      };

  const logoStyle = isMapSwap
    ? {
        width: "30vw",
        marginLeft: "0vw",
      }
    : {
        width: "13vw",
        margin: "auto",
      };

  return (
    <Placeholder style={specificStyle}>
      <div>
        <PlaceholderImage
          alt="Kaart Logo"
          className={"logo"}
          src={kaartLogo}
          style={logoStyle}
        />
      </div>
    </Placeholder>
  );
};
