import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import { Table, TableBody } from "@mui/material";
import { ADMIN_PROJECT_TABLE_HEADERS } from "./adminDashComponents";
import useToggle from "../../hooks/useToggle";
import {
  CardMediaStyle,
  DashboardCard,
  ListHead,
  TableCard,
  TableEntryCell,
  TableEntryRow,
} from "../../components/commonComponents/commonComponents";

var redirectLink = "/login";
export const AdminDash = () => {
  // DATA CONTEXT STATES AND FUNCTIONS //
  const {
    fetch_admin_dash_info,
    adminDashboardInfo,
    readyTrips,
    setReadyTrips,
    fetchOrgTrips,
    ViewProjectSequencesClicked,
    toggleHasImagery,
    setcurrentFeatureCollectionIndex,
    setNewtripSelected,
    setSelectedPictureDirection,
    setSelectedFeature,
    setSelectedSequence,
    setSelectedView,
    isLoading,
    toggleIsLoading,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);
  const [redirect, toggleRedirect] = useToggle(false);
  const [tripSelected, setTripSelected] = useState(null);

  useEffect(() => {
    if (user === null || user.role !== "admin") {
      toggleRedirect(true);
    }
  }, [user]);

  useEffect(() => {
    if (user !== null && user.role === "admin") {
      fetch_admin_dash_info();
      fetchOrgTrips("ready");
    }
  }, []);

  const handleSetTripSelected = (id) => {
    setTripSelected(id);
  };

  const handleViewTrip = (id) => {
    redirectLink = "/viewer";
    toggleRedirect();
    setSelectedPictureDirection(null);
    setSelectedFeature(null);
    setSelectedSequence(null);
    setNewtripSelected(true);
    handleSetTripSelected(id);
    setcurrentFeatureCollectionIndex(0);
    ViewProjectSequencesClicked(id);
    setSelectedView("Front");
    toggleHasImagery(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: "2vh",
        }}
      >
        <DashboardCard
          minWidth={"20vw"}
          title={<strong>Trips Overview</strong>}
          subtitle_text_1={<strong>Ready:</strong>}
          subtitle_text_2={<strong>Archived:</strong>}
          subtitle_text_3={<strong>New/Uploading:</strong>}
          value_1={
            adminDashboardInfo !== null
              ? adminDashboardInfo.total_ready_trips
              : "-"
          }
          value_2={
            adminDashboardInfo !== null
              ? adminDashboardInfo.total_archived_trips
              : "-"
          }
          value_3={
            adminDashboardInfo !== null
              ? adminDashboardInfo.total_uploading_trips
              : "-"
          }
        />

        <DashboardCard
          minWidth={"20vw"}
          title={<strong>Teams Overview</strong>}
          subtitle_text_1={<strong>Total Admins:</strong>}
          subtitle_text_2={<strong>Total Users:</strong>}
          subtitle_text_3={<strong>Total Teams:</strong>}
          value_1={
            adminDashboardInfo !== null ? adminDashboardInfo.total_admins : "-"
          }
          value_2={
            adminDashboardInfo !== null ? adminDashboardInfo.total_users : "-"
          }
          value_3={
            adminDashboardInfo !== null ? adminDashboardInfo.total_teams : "-"
          }
        />
        <DashboardCard
          minWidth={"20vw"}
          title={<strong>Stats Overview</strong>}
          subtitle_text_1={<strong>Total Images:</strong>}
          subtitle_text_2={<strong>Miles Driven:</strong>}
          subtitle_text_3={<strong>Countries Visited:</strong>}
          value_1={
            adminDashboardInfo !== null ? adminDashboardInfo.total_images : "-"
          }
          value_2={
            adminDashboardInfo !== null
              ? adminDashboardInfo.total_countries
              : "-"
          }
          value_3={
            adminDashboardInfo !== null
              ? adminDashboardInfo.total_countries
              : "-"
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "42vh",
        }}
      >
        <TableCard
          style={{
            boxShadow: "1px 1px 6px 2px gray",
            overflowY: "scroll",
            width: "100%",
          }}
        >
          <CardMediaStyle />
          <Table>
            <ListHead
              headLabel={ADMIN_PROJECT_TABLE_HEADERS}
              tableData={readyTrips}
              updateData={setReadyTrips}
              vw={"45vw"}
            />
            <TableBody>
              {readyTrips &&
                readyTrips.slice().map((row) => {
                  const {
                    name,
                    id,
                    country,
                    city,
                    cameras,
                    total_days,
                    date_created,
                  } = row;
                  return (
                    <TableEntryRow
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(145, 165, 172, 0.5)",
                          cursor: "pointer",
                        },
                      }}
                      align="center"
                      // hover
                      key={id}
                      tabIndex={-1}
                      selected={tripSelected === id}
                      onDoubleClick={(e) => handleViewTrip(id)}
                    >
                      <TableEntryCell entry={<strong>{id}</strong>} />
                      <TableEntryCell entry={<strong>{name}</strong>} />
                      <TableEntryCell entry={<strong>{country}</strong>} />
                      <TableEntryCell entry={<strong>{city}</strong>} />
                      <TableEntryCell entry={<strong>{cameras}</strong>} />
                      <TableEntryCell entry={<strong>{total_days}</strong>} />
                      <TableEntryCell entry={<strong>{date_created}</strong>} />
                    </TableEntryRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableCard>
      </div>
      {!redirect ? null : <Navigate push to={redirectLink} />}
    </>
  );
};
