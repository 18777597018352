
import styled from "styled-components";
import whiteKaartLogo from "../../images/20-KAART-White.svg";
import close_icon from "../../images/close-icon.svg";
import kaartLogo from "../../images/logo-kaart-standard.svg";
import menu_icon from "../../images/menu-icon.png";
import LayerImage from "../../images/icn-topographic-white.svg";
import infoIcon from "../../images/infoIcon.png";
import mapIcon from "../../images/Layers-white.svg";
import swapIcon from "../../images/up-arrow-left.svg";
export const SidebarOpenedContainer = styled.div`
  border-width: 0px;
  z-index: 9999;
  position: absolute;
  box-shadow: 3px 0px 5px #253e45;
  left: 0;
  height: 100%;
  width: 220px;
  transition: left 0.3s ease-in-out;
  background-color: white;
`;

export const SidebarClosedContainer = styled.div`
  border-width: 0px;
  z-index: 9999;
  position: absolute;
  left: 0;
  height: 100%;
  width: 15vw;
  transition: left 0.3s ease-in-out;
  background-color: transparent;
`;

export const MenuItem = styled.a`
  position: relative;
  color: #253e45 !important;
  padding-left: 4rem;
  padding: 1rem;
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
    background: rgba(145, 165, 172, 0.1);
  }
`;

export const MenuItemTop = styled.div`
  display: flex;
  flex-direction: row,
  align-items: center;
  margin-bottom: 10px;
`;

export const KaartLogoOpen = styled.div`
  background-image: url(${kaartLogo});
  background-repeat: no-repeat;
  background-position: 50%;
  max-width: 100%;
  width: 100px;
  height: 80px;
  /* position: relative; */
  &:hover {
    cursor: pointer;
  }
`;

export const KaartLogoClosed = styled.div`
  background-image: url(${whiteKaartLogo});
  background-repeat: no-repeat;
  background-position: 50%;
  max-width: 100%;
  width: 100px;
  height: 80px;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
export const ProjectIconContainer = styled.span`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  text-align: center;
  box-sizing: border-box;
  font-size: var(--h3);
`;

export const ProjectIcon = styled.img`
  width: 35px;
  height: 35px;
`;

export const OpenMenuIconContainer = styled.div`
  top: 1.5%;
  right: 20px;
  position: absolute;
  height: 66px;
  width: 32px;
  pointer-events: none;
  z-index: 999;
`;

export const OpenMenuIconButton = styled.div`
  border-radius: 25px;
  width: 75%;
  height: 25px;
  pointer-events: auto;
  background: rgba(145, 165, 172, 0.3);
  cursor: pointer;
`;

export const OpenMenuIcon = styled.div`
  background-image: url(${menu_icon});
  position: relative;
  top: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
  width: 24px;
  height: 24px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const CollapseMenuIcon = styled.div`
  background-image: url(${close_icon});
  background-repeat: no-repeat;
  background-size: 20px 20px;
  position: absolute;
  left: 14rem;
  width: 13%;
  height: 3rem;
  top: 15px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const Header = styled.a`
  text-align: left;
  margin-left: 1.2vw;
  color: #253e45 !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent !important;
  border-right: none !important;
`;

export const RoleBarWrapper = styled.div`
  /* padding-top: 0.5vw; */
  text-align: center;
  margin-bottom: 1rem;
  height: 4rem;
  width: 16rem;
  // background: rgba(145, 165, 172, 0.2);
  border-radius: 6px;
`;

export const RoleHeader = styled.h6`
  /* text-align: center; */
  /* font-size: 1.1rem; */
  padding-top: 5px;
  color: #253e45;
  font-weight: 700;
`;

export const RoleSubHeader = styled.h6`
  /* text-align: center; */
  color: #253e45;
  opacity: 0.7;
`;

export const FullscreenWindow = styled.div`
  width: 99vw;
  margin: auto;
  transform: translate(0%, -10%);
  border: 1px solid black;
`;

export const MapWindow = styled.div`
  z-index: 999;
  transform: translate(5%, -135%);
  height: 30vh;
  width: 20vw;
  background-color: grey;
  border: 1px solid darkgrey;
`;
export const InformationButtonContainer = styled.div`
  bottom: 1rem;
  right: 1.5rem;
  position: absolute;
  /* bottom: 90px;
  right: 14px; */
  height: 66px;
  width: 32px;
  pointer-events: none;
  z-index: 999;
`;

export const InformationButton = styled.div`
  border-radius: 25px;
  width: 75%;
  height: 25px;
  pointer-events: auto;
  background: rgba(145, 165, 172, 0.5);
  cursor: pointer;
`;

export const InformationCollapsedIcon = styled.div`
  background-image: url(${infoIcon});
  position: relative;
  top: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
  width: 24px;
  height: 24px;
  &:hover {
    background-color: rgba(145, 165, 172, 0.5);
    opacity: 0.7;
  }
`;

export const MapIconWrapper = styled.div`
  position: absolute;
  margin: auto;
  top: 1.5%;
  padding: 4px;
  width: 35px;
  height: 35px;
  left: 95%;
  background: rgba(145, 165, 172, 0.3);
  border-radius: 25px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const MapIcon = styled.div`
  background-image: url(${mapIcon});
  width: 15%;
  margin: auto;
  padding: 15px;
  opacity: 0.9;
`;

export const SideBarOpen = styled.div`
  position: absolute;
  left: 15%;
  width: 85%;
  height: 100%;
  margin-right: 0vw;
  padding-right: 0vw;
`;

export const SideBarClosed = styled.div`
  position: absolute;
  left: 8%;
  width: 92%;
  height: 100%;
  margin-right: 0vw;
  padding-right: 0vw;
`;

export const MapSwap = styled.div`
  background-image: url(${swapIcon});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 20px 20px;
  position: absolute;
  z-index: 999;
  margin: auto;
  padding: 15px;
  background-color: rgba(145, 165, 172, 0.5);
  border-radius: 6px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const ViewerWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-right: 0vw;
  padding-right: 0vw;
  overflow: hidden;
`;

export const MetablockWrapper = styled.div`
  position: absolute;
  bottom: 3%;
  right: 1.5%;
  background-color: white;
  box-shadow: 1px 1px 6px 2px black;
  border-radius: 6px;
  opacity: 85;
`;

export const MiniMap = styled.div`
  position: absolute;
  z-index: 999;
  top: 2vh;
  right: 1.5%;
  width: 32%;
  height: 32%;
  background-color: white;
  box-shadow: 1px 1px 6px 2px black;
  overflow: hidden;
  border-radius: 6px;
`;

export const MapLayerToggleContainer = styled.div`
  top: 50%;
  right: 1.5rem;
  position: absolute;
  /* bottom: 90px;
  right: 14px; */
  width: auto;
  height: auto;
  pointer-events: none;
  z-index: 999;
  background: rgba(66, 71, 75, 0.6);
`;

export const MapLayerToggleButton = styled.div`
  border-radius: 25px;
  width: 75%;
  height: 25px;
  pointer-events: auto;
  background-color: rgba(145, 165, 172, 0.5);
  cursor: pointer;
`;

export const MapLayerToggleIcon = styled.div`
  background-image: url(${LayerImage});
  position: relative;
  top: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  /* opacity: 1; */
  width: 24px;
  height: 24px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const LayerToggle = styled.div`
  position: relative;
  margin: auto;
  width: 25px;
  height: 25px;
  border: none;
`;
export const MapLayerPickerWrapper = styled.div`
  top: 10vh;
  right: 2rem;
  position: absolute;
  box-sizing: border-box;
  background-color: blue;
  border-radius: 6px;
`;

export const MapLayerPickerContent = styled.div`
  /* width: 200px; */
  box-shadow: 0 22px 20px -16px var(--shadow-color);
  font-size: 14px;
  min-height: 160px;
  padding: 0.5rem;
`;

export const MapLayerPickerLabel = styled.label`
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-left: 0.5vw;
  display: block;
  font-size: 14px;
`;

export const LayerRadioGroup = styled.div`
  position: absolute;
  margin: auto;
  left: 95%;
  top: 25rem;
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  font-size: 0.6vw;
  z-index: 1;
`;

export const LayerRadioGroupSmall = styled.div`
  position: absolute;
  margin: auto;
  right: 2rem;
  top: 20%;
  background-color: white;
  font-weight: bold;
  border-radius: 6px;
  font-size: 0.6vw;
  z-index: 1;
`;

export const LayerRadioGroupText = styled.label`
  color: white;
  font-weight: bold;
  text-size-adjust: auto;
  z-index: 1;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const Fullscreen = styled.button`
  top: 1%;
  right: 1%;
  font-size: 20px;
  position: absolute;
  background-color: black;
  color: white;
  border: none;
  margin-right: 3px;
  margin-top: 3px;
  border-radius: 50%;
  max-width: 4vw;
  max-height: 4vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  &:hover {
    background-color: white;
    color: black;
  }
`;
export const MapLayers = styled.div`
  bottom: 60px;
  left: 1154px;
  transform-origin: 100% 100%;
`;

export const MapLayerPickerInput = styled.input`
  margin-right: 0.5rem;
`;

export const ArrowRight = styled.div`
  /* bottom: 95px;
  border-left-color: white;
  border-bottom-color: transparent;
  border-bottom-width: 0;
  border-top-width: 5rem;
  right: 0;
  border-right-color: transparent;
border-top-color: transparent;
border-left-width: 5rem;
border-right-width: 0;
border-style: solid;
height: 0;
position: absolute;
width: 0;
background-color:red;
z-index: 9999; */
`;
