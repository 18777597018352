import { useState, useEffect } from "react";

export const useLocalStorageState = (key, defaultValue) => {
  const [state, setState] = useState(
    () => JSON.parse(sessionStorage.getItem(key)) || defaultValue
  );
  useEffect(() => {
    state !== null && state !== undefined
      ? sessionStorage.setItem(key, JSON.stringify(state))
      : sessionStorage.removeItem(key);
  }, [key, state]);

  return [state, setState];
};
