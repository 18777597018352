import React, { useContext, useEffect, useState } from "react";
import { InteractionContext } from "../../common/InteractionContext";
import { DataContext } from "../../common/DataContext";
import PrismaZoom from "react-prismazoom";
import { ImageWrapper, Image, Fullscreen } from "./styles";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import "./styles.css";
import image1 from "../../images/404.jpg";

//COMPOENT DECLARATION & SETUP - IMAGE VIEWER COMPONENT ON MAP PAGE
export default function ImageViewer() {
  //STATES FROM INTERACTION CONTEXT
  const { imageRef, isFullscreen, setisFullscreen, FullArrow } =
    useContext(InteractionContext);

  //STATES FROM DATA CONTEXT
  const {
    selectedPicture,
    zoom,
    setZoom,
    prismaZoom,
    setCurrentYaw,
    rotateOffset,
    imageBrightness,
    handleToggleMapMenus,
    isMapSwap,
  } = useContext(DataContext);

  //COMPONENT STATES
  const [scale, setScale] = useState(1);
  const [initialX, setInitialX] = useState(0);
  const [initialY, setInitialY] = useState(0);
  const [imageViewer, setImageViewer] = useState();
  const pSRef = React.createRef();
  const [tempYaw, setTempYaw] = useState(null);
  //USE EFFECTS

  // CONTROLS THE SIZE OF THE IMAGE VIEWER COMPONENT BASED ON SCREEN SIZE & COMPONENT SWAP
  useEffect(() => {
    if (isFullscreen) {
      imageRef.current.style.height = "100vh";
      imageRef.current.style.width = "100vw";
    } else if (!isFullscreen) {
      imageRef.current.style.height = "";
      imageRef.current.style.width = "";
      FullArrow.current.style.transform = "";
    }
    // eslint-disable-next-line
  }, [isFullscreen]);

  //SETS THE SCALE OF THE CURRENT IMAGE
  useEffect(() => {
    if (selectedPicture) {
      if (rotateOffset !== 0) {
        if (selectedPicture.facing === "360") {
          setTempYaw(rotateOffset);
          // pannellumRef.current.getViewer().setYaw(rotateOffset);
        }
      } else {
        if (selectedPicture.facing === "360") {
          setTempYaw(rotateOffset);
          // pannellumRef.current.getViewer().setYaw(rotateOffset);
        }
      }
      if (selectedPicture.facing === "360") {
        setCurrentYaw(selectedPicture.direction);
      }
      setScale(scale);
      setInitialX(initialX);
      setInitialY(initialY);
      setImageViewer(
        <Image
          src={selectedPicture.img_url}
          style={{ filter: `brightness(${imageBrightness}%)` }}
        />,
      );
    }
    // eslint-disable-next-line
  }, [selectedPicture, imageBrightness]);

  const onZoomChange = () => {
    setZoom(zoom);
  };
  const setYaw = (e) => {
    let yaw;
    if (rotateOffset === 0) {
      yaw = e + selectedPicture.direction;
    } else {
      if (e > 180) {
        e = e - 180;
      }
      if (e < 180) {
        let f = 180 - e;
        e = 0 - f;
        e = 360 + e;
      }
    }
    if (rotateOffset === 180) {
      yaw = e + selectedPicture.direction;
    }
    if (yaw === 360) {
      yaw = 0;
    }
    if (yaw < 0) {
      yaw = 360 - yaw;
    }
    if (yaw > 360) {
      yaw = yaw - 360;
    }
    setCurrentYaw(yaw);
  };

  // useEffect(() => {
  //    pSRef.current.getPostion()
  // }, []);

    useEffect(() => {
    if (selectedPicture.facing === "360") {
      pSRef.current.setPanorama(selectedPicture.img_url, { transition: false, showLoader: false, zoom: 0 });
    }
  }, [selectedPicture, pSRef]);

  return (
    <>
      <ImageWrapper onClick={() => handleToggleMapMenus()} ref={imageRef}>
        {selectedPicture.facing === "360" ? (
          <div style={{ filter: `brightness(${imageBrightness}%)` }}>
          <ReactPhotoSphereViewer
            ref={pSRef}
            width={isMapSwap && isMapSwap === true ? "100vw" : "20vw"}
            height={isMapSwap && isMapSwap === true ? "100vh" : "25vh"}
            container={"container-360"}
            src={image1}
            navbar={false}
          />
          </div>
        ) : (
          <>
            <div id="brightness" style={{ height: "100vh" }}>
              <PrismaZoom
                onZoomChange={onZoomChange}
                maxZoom={8}
                ref={prismaZoom}
                style={{
                  width: isMapSwap && isMapSwap === true ? "100vw" : "20vw",
                  height: isMapSwap && isMapSwap === true ? "100vh" : "25vh",
                }}
                children={0}
              >
                {imageViewer}
              </PrismaZoom>
            </div>
          </>
        )}
        <Fullscreen
          ref={FullArrow}
          onClick={() => {
            setisFullscreen(!isFullscreen);
          }}
        ></Fullscreen>
      </ImageWrapper>
    </>
  );
};
