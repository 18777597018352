import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../common/DataContext";
import { ArrowBar, ChevronRight, ChevronLeft, Play, Stop } from "./styles";

import { TooltipStyled } from "../Viewer/styles";

export const HotBar = () => {
  const { handle_cycle_feature, handle_autoplay, isPlaying } =
    useContext(DataContext);
  // HOTKEY SETUP & RENDER COMPONENT //
  return (
    // HOTKEYS //
    <ArrowBar>
      <ChevronLeft
        data-tip
        data-for="1L"
        onClick={() => handle_cycle_feature("down")}
      >
        <TooltipStyled
          id="1L"
          effect="solid"
          delayShow={800}
          place="bottom"
          type="dark"
        >
          <p>1 Back</p>
        </TooltipStyled>
      </ChevronLeft>
      {isPlaying ? (
        <Stop
          id="Auto"
          data-tip
          data-for="Stop"
          onClick={() => handle_autoplay()}
        >
          <TooltipStyled
            id="Stop"
            effect="solid"
            delayShow={800}
            place="bottom"
            type="dark"
          >
            <p>Stop Auto-Play</p>
          </TooltipStyled>
        </Stop>
      ) : (
        <Play
          id={"Auto"}
          data-tip
          data-for="Play"
          onClick={() => handle_autoplay()}
        >
          <TooltipStyled
            id="Play"
            effect="solid"
            delayShow={800}
            place="bottom"
            type="dark"
          >
            <p>Auto-Play</p>
          </TooltipStyled>
        </Play>
      )}
      <ChevronRight
        data-tip
        data-for="1R"
        onClick={() => handle_cycle_feature("up")}
      >
        <TooltipStyled
          id="1R"
          effect="solid"
          delayShow={800}
          place="bottom"
          type="dark"
        >
          <p>1 Forward</p>
        </TooltipStyled>
      </ChevronRight>
    </ArrowBar>
  );
};
