import styled from "styled-components";
import Minimize from "../../images/minimize.png";
import Close from "../../images/close_icon.png";

export const JSOMHeader = styled.h5`
  text-align: center !important;
  padding-left: 50px !important;
  font-size: 1.1rem;
  font-weight: 500;
`;

export const MinimizeIcon = styled.div`
  background-image: url(${Minimize});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 40px 40px;
  position: absolute;
  position: absolute;
  z-index: 999;
  // margin: auto;
  // top: 2px;
  padding: 5px;
  width: 3vw;

  background-color: #f4753c;
  border-radius: 6px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const CloseIcon = styled.div`
  background-image: url(${Close});
  background-repeat: no-repeat;
  background-position: auto;
  background-size: 100%;
  position: relative;
  flex
  z-index: 999;
  margin-left: 90%;
  top: 2px;
  padding: 5px;
  width: 1.5vw;
  height: 12.5%;
  display: flex;
  flex-direction: column;

  background-color: white;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const TableCell = styled.div`
  font-size: 1rem;
  position: relative;
  width: 25vw;
  margin-top: 1rem;
  white-space: wrap;
`;

export const ImageInfoTable = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  transition: all 0.3s linear;
  flex-direction: column;
  position: relative;
  margin: 1rem 0;
  height: 27rem;
  width: auto;
`;

export const TableTitle = styled.header`
  font-size: 1rem;
  font-weight: bold;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  box-sizing: inherit;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
  width: 90%;
  display: flex;
  align-self: center;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 6px;
`;

export const TextArea = styled.textarea`
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
  width: 90%;
  display: flex;
  align-self: center;
  padding: 12px 20px;
  height: 75%;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
`;
