import React, { createContext, useState, useEffect } from "react";
import { useLocalStorageState } from "../useLocalStorageState";

import { poster, fetcher } from "../../calls";
import { Navigate } from "react-router-dom";
export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorageState("viewer.user", null);
  const [token, setToken] = useState(null);
  const [loginRedirect, setLoginRedirect] = useState();

  useEffect(() => {
    if (!user) {
      setLoginRedirect("/viewer")
    }
  }, [user]);

  const SSO_Login = (email, password) => {
    let outpack = {
      email: email,
      password: password,
    };
    poster(outpack, "sso-login").then((response) => {
      if (response.status === 200) {
        setUser(response.user_info);
        // const token = response.access_token;
        // document.cookie = `access_token=${token}; path=/`;
        setToken(response.token);
      } else if (response.status === 304) {
        alert("Bad Credentials");
        setUser(null);
      } else {
        if (!response.ok) {
          return <Navigate push to={"/login"} />;
        }
      }
    });
  };

  const logout = () => {
    setUser(null);
    fetcher("sso-logout").then((response) => {});
  };

  const SSO_Refresh = () => {
    let outpack = {
      user_id: user.id,
    };
  };

  const value = {
    logout,
    user,
    setUser,
    SSO_Login,
    SSO_Refresh,
    loginRedirect,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
