import React, { useContext, useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import { EditTeamsTab } from "../TableHubs/TeamHub/editTeamsTab";
import { DriveTeamsTab } from "../../components/TableHubs/TeamHub/driveTeamsTab";
import { Navigate } from "react-router-dom";
import "./styles.css";
import {
  ButtonDivComponent,
  TopDiv,
} from "../commonComponents/commonComponents";
import useToggle from "../../hooks/useToggle";
import {
  AddTeamModal,
  DeleteTeamModal,
  ModifyTeamModal,
} from "../TableHubs/TeamHub/teamComponents";

export const AdminTeamsPage = () => {
  // DATA CONTEXT STATES AND FUNCTIONS //
  const { user } = useContext(AuthContext);
  const [redirect, setRedirect] = useState(false);
  // SETS STATE OF CONTROL SIDEBAR OPEN / COLLAPSED //

  const [addOpen, toggleAddOpen] = useToggle(false);
  const [deleteOpen, toggleDeleteOpen] = useToggle(false);
  const [modifyOpen, toggleModifyOpen] = useToggle(false);
  const [teamSelected, setTeamSelected] = useState(null);
  const [teamSelectedName, setTeamSelectedName] = useState(null);
  const [teamSelectedDesc, setTeamSelectedDesc] = useState(null);
  const [teamSelectedType, setTeamSelectedType] = useState(null);
  const [inviteEmail, setInviteEmail] = useState(null);
  const [form, setForm] = useState({ name: "", desc: "" });
  const [roleSelected, setRoleSelected] = useState(null);
  const [tabKey, setTabKey] = useState(0);

  useEffect(() => {
    if (user === null || user.role !== "admin") {
      setRedirect(true);
    }
  }, []);

  const handleTabChange = (newTabKey) => {
    setTabKey(newTabKey);
  };

  const handleSetInviteEmail = (e) => {
    setInviteEmail(e);
  };

  const handleSetTeamSelected = (id, name, desc, type) => {
    setTeamSelected(id);
    setTeamSelectedName(name);
    setTeamSelectedDesc(desc);
    setTeamSelectedType(type);
  };

  const handleAddOpen = () => {
    toggleAddOpen(!addOpen);
    setTeamSelected(null);
    setTeamSelectedName(null);
    setTeamSelectedDesc(null);
    setTeamSelectedType(null);
    setForm({ name: "", desc: "" });
  };

  const handleDeleteOpen = () => {
    if (teamSelected !== null) {
      toggleDeleteOpen();
    } else {
      alert("No Team Selected");
    }
  };

  const handleModifyOpen = () => {
    if (teamSelected !== null) {
      setForm({ name: teamSelectedName, desc: teamSelectedDesc });
      toggleModifyOpen();
    } else {
      alert("No Team Selected");
    }
  };

  return (
    <div>
      <AddTeamModal
        addOpen={addOpen}
        handleAddOpen={handleAddOpen}
        handleSetInviteEmail={handleSetInviteEmail}
        form={form}
        setForm={setForm}
        type={1 === tabKey ? "drive" : "editing"}
      />
      <DeleteTeamModal
        deleteOpen={deleteOpen}
        teamSelected={teamSelected}
        teamSelectedName={teamSelectedName}
        setTeamSelected={handleSetTeamSelected}
        handleDeleteOpen={handleDeleteOpen}
      />
      <ModifyTeamModal
        modifyOpen={modifyOpen}
        roleSelected={roleSelected}
        teamSelected={teamSelected}
        teamSelectedName={teamSelectedName}
        handleSetTeamSelected={handleSetTeamSelected}
        form={form}
        setForm={setForm}
        handleModifyOpen={handleModifyOpen}
      />
      <Tabs onSelect={handleTabChange}>
        <TopDiv>
          <TabList>
            <Tab>
              <strong>Editing Teams</strong>
            </Tab>
            <Tab>
              <strong>Drive Teams</strong>
            </Tab>
          </TabList>
          <ButtonDivComponent
            role={"admin"}
            button1={true}
            button2={true}
            button3={true}
            button1_text={"Add"}
            button2_text={"Edit"}
            button3_text={"Delete"}
            button1_action={handleAddOpen}
            button2_action={handleModifyOpen}
            button3_action={handleDeleteOpen}
          />
        </TopDiv>

        <TabPanel>
          <EditTeamsTab
            role={"admin"}
            tab={"editing"}
            teamSelected={teamSelected}
            handleSetTeamSelected={handleSetTeamSelected}
          />
        </TabPanel>

        <TabPanel>
          <DriveTeamsTab
            role={"admin"}
            tab={"driving"}
            handleSetTeamSelected={handleSetTeamSelected}
          />
        </TabPanel>
      </Tabs>

      {!redirect ? null : <Navigate push to="/login" />}
    </div>
  );
};
