import { MAPBOX_TOKEN } from "../components/constants";

let cachedFeatures = [];

export async function find_next_feature(
  lat,
  lng,
  radius = 100,
  type = null,
  timestamp = null,
  direction = null,
  maxRetries = 2,
) {
  if (cachedFeatures.length > 0) {
    let potential_features = filter_features_by_properties(
      cachedFeatures,
      direction,
    );
    let closest_feature_in_time = findClosestFeatureByTime(
      potential_features,
      timestamp,
      type,
      7500,
    );

    if (closest_feature_in_time != null) {
      return closest_feature_in_time;
    }

    // If no match found in cache, retry using tilequery
    if (maxRetries > 0) {
      cachedFeatures = [];
      return find_next_feature(
        lat,
        lng,
        radius,
        type,
        timestamp,
        direction,
        maxRetries - 1,
      );
    }
  } else {
    let potential_features = await get_potential_features(
      lat,
      lng,
      radius,
      direction,
    );

    cachedFeatures = potential_features;

    let closest_feature_in_time = findClosestFeatureByTime(
      potential_features,
      timestamp,
      type,
      Infinity,
    );

    if (closest_feature_in_time != null) {
      return closest_feature_in_time;
    }

    let closest_feature_in_distance =
      findClosestFeatureByDistance(potential_features);

    if (closest_feature_in_distance != null) {
      return closest_feature_in_distance;
    }
  }
}

function findClosestFeatureByDistance(potential_features) {
  let distance = Infinity;
  let closest_feature = undefined;
  for (let feature of potential_features) {
    let feature_distance = feature.properties.tilequery.distance;
    if (feature_distance < distance && feature_distance !== 0) {
      closest_feature = feature;
      distance = feature_distance;
    }
  }
  return closest_feature;
}

function parseTimestamp(timestampString) {
  return new Date(timestampString);
}

function findClosestFeatureByTime(
  potentialFeatures,
  timestamp,
  type,
  closestTimeDiff,
) {
  if (timestamp === null || type === null) {
    return null;
  }

  let closestFeature = null;
  const timestampToBeat = parseTimestamp(timestamp);

  for (const feature of potentialFeatures) {
    const nextTimestamp = parseTimestamp(feature.properties.timestamp);
    const timeDiff = Math.abs(nextTimestamp - timestampToBeat);

    switch (type) {
      case "up":
        if (nextTimestamp > timestampToBeat && timeDiff < closestTimeDiff) {
          closestFeature = feature;
          closestTimeDiff = timeDiff;
        }
        break;
      case "down":
        if (nextTimestamp < timestampToBeat && timeDiff < closestTimeDiff) {
          closestFeature = feature;
          closestTimeDiff = timeDiff;
        }
        break;
      case "equal":
        if (nextTimestamp.getTime() === timestampToBeat.getTime()) {
          return feature;
        }
        break;
      default:
        console.error("incorrect type");
        break;
    }
  }

  return closestFeature;
}

function filter_features_by_properties(potential_features, direction) {
  const whitelisted_directions = ["Front", "Left", "Right", "360"];
  try {
    //filter down the potential ones by there properties
    let filtered_features = [];
    for (const feature of potential_features) {
      if (
        feature?.properties?.img !== undefined // Has image
      ) {
        // Matches facing direction
        if (!whitelisted_directions.includes(direction)) {
          filtered_features.push(feature);
        } else if (feature?.properties?.facing === direction) {
          filtered_features.push(feature);
        }
      }
    }

    return filtered_features;
  } catch (e) {
    return [];
  }
}

async function get_potential_features(lat, lng, radius, direction = null) {
  if (direction === null) {
    direction = "";
  }
  let potential_features = await tilequery(lat, lng, radius, direction);
  potential_features = potential_features["features"];

  return filter_features_by_properties(potential_features, direction);
}

async function tilequery(lat, lng, radius, direction) {
  const api_url =
    "https://api.mapbox.com/v4/kaart.quandary/tilequery/{lng},{lat}.json?radius={radius}&layers={layer}&limit=50&geometry=point&access_token={token}";
  const url = api_url
    .replace("{lng}", lng)
    .replace("{lat}", lat)
    .replace("{layer}", direction)
    .replace("{radius}", radius)
    .replace("{token}", MAPBOX_TOKEN);

  const options = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      console.error(`API request failed with status ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
  }
}
