import styled from "styled-components";

export const ButtonWrapper = styled.div`
  position: relative;
  right: 0vw;
  display: flex;
  flex-direction: row;
  margin-top: 0;
  margin-bottom: 3vh;
  margin-right: 0vw;
`;
export const StyledButton = styled.div`
  color: white;
  text-transform: uppercase;
  background-color: #253e45;
  width: 10vw;
  margin-right: 1vw;
  height: 4vh;
  text-align: center;
  margin-top: 3vh;
  border-radius: 25px;
  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProjectIcon = styled.img`
  width: 35px;
  height: 35px;
`;
