// MODIFY Trip MODAL //
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import React, { useContext, useEffect, useState } from "react";
import {
  AddDriveTeamModal,
  ProjectDetailsSection,
  TripFootageTab,
} from "./tripComponents";
import useToggle from "../../../hooks/useToggle";
import { Container, Modal } from "@mui/material";
import { ProjectTeamsTable } from "./projectTeamsTable";
import {
  CloseButton,
  ConfirmButton,
  ModalWrapper,
  SectionTitle,
} from "../../commonComponents/commonComponents";

export const ModifyTripModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.modifyOpen) {
        props.handleModifyOpen(); // Call the function to close the modal
      }
    }
  };
  const [addUserModalOpen, toggleAddUserModalOpen] = useToggle(false);

  const handleToggleAddUserModalOpen = () => {
    toggleAddUserModalOpen();
  };

  return (
    <>
      <AddDriveTeamModal
        open={addUserModalOpen}
        close_action={toggleAddUserModalOpen}
        confirm_action={handleToggleAddUserModalOpen}
        trip_id={props.tripSelected}
        orgUsers={props.orgUsers}
        setTempDriveTeam={props.setTempDriveTeam}
        tempDriveTeam={props.tempDriveTeam}
        handleSetDriveTeam={props.handleSetDriveTeam}
        setTempOrgUsers={props.setTempOrgUsers}
      />

      <Modal open={props.modifyOpen} key="modify" onKeyDown={handleKeyDown}>
        <ModalWrapper>
          <CloseButton close_action={props.handleModifyOpen} />
          <SectionTitle
            title_text={<strong>Edit Trip: {props.tripName}</strong>}
          />
          <Container>
            <Tabs
              style={{
                textAlign: "center",
                height: "auto",
                width: "100%",
                paddingLeft: "0",
                paddingRight: "0",
              }}
            >
              <TabList>
                <>
                  <Tab>
                    <strong>Details</strong>
                  </Tab>
                  <Tab>
                    <strong>Footage</strong>
                  </Tab>
                  <Tab>
                    <strong>Teams</strong>
                  </Tab>
                  <Tab>
                    <strong>Status</strong>
                  </Tab>
                </>
              </TabList>
              {props.role === "admin" ? (
                <>
                  <TabPanel>
                    <EditNameSection
                      tripSelected={props.tripSelected}
                      tripName={props.tripName}
                      tripCountry={props.tripCountry}
                      tripCity={props.tripCity}
                      tripDescription={props.tripDescription}
                      handleModifyOpen={props.handleModifyOpen}
                      archive_button={props.archive_button}
                      completed_button={props.completed_button}
                      handleConfirmOpen={props.handleConfirmOpen}
                      tempDriveTeam={props.tempDriveTeam}
                      addUserModalOpen={addUserModalOpen}
                      toggleAddUserModalOpen={toggleAddUserModalOpen}
                    />
                  </TabPanel>
                  <TabPanel>
                    <TripFootageTab
                      close_action={props.handleModifyOpen}
                      project_id={props.tripSelected}
                      tripSelected={props.tripSelected}
                      role={props.role}
                      handleImagePreviewOpen={props.handleImagePreviewOpen}
                      toggleImagePreviewOpen={props.toggleImagePreviewOpen}
                      selectedImageURL={props.selectedImageURL}
                      editOpen={props.editOpen}
                      handleEditOpen={props.handleEditOpen}
                      modifyOpen={props.modifyOpen}
                      toggleModifyOpen={props.toggleModifyOpen}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ProjectTeamsTable
                      assign={false}
                      projectName={props.tripName}
                      close_action={props.handleModifyOpen}
                      project_id={props.tripSelected}
                    />
                  </TabPanel>

                  <TabPanel>
                    <EditStatusSection
                      tripStatus={props.tripStatus}
                      handleConfirmOpen={props.handleConfirmOpen}
                      handleSetStatusAction={props.handleSetStatusAction}
                      archiveTrip={props.archiveTrip}
                      completeTrip={props.completeTrip}
                      uploadingTrip={props.uploadingTrip}
                    />
                  </TabPanel>
                </>
              ) : (
                <>
                  <TabPanel>
                    {/* <TripDaysTab
                    close_action={props.handleModifyOpen}
                    tripSelected={props.tripSelected}
                    role={props.role}
                  /> */}
                  </TabPanel>
                </>
              )}
            </Tabs>
          </Container>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export const EditNameSection = (props) => {
  return (
    <>
      <ProjectDetailsSection
        tripName={props.tripName}
        tripCountry={props.tripCountry}
        tripCity={props.tripCity}
        tripDescription={props.tripDescription}
        tempDriveTeam={props.tempDriveTeam}
        addUserModalOpen={props.addUserModalOpen}
        toggleAddUserModalOpen={props.toggleAddUserModalOpen}
        handleModifyOpen={props.handleModifyOpen}
        cancel_text={"Cancel"}
        confirm_text={"Accept"}
        tripSelected={props.tripSelected}
      />
    </>
  );
};

export const EditStatusSection = (props) => {
  const handleStatusAction = (e) => {
    props.handleSetStatusAction(e);
    props.handleConfirmOpen();
  };

  return (
    <>
      <SectionTitle
        title_text={<strong>{`Current Status: ${props.tripStatus}`}</strong>}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "2vh",
        }}
      >
        <ConfirmButton
          confirm_text={"Set uploading"}
          confirm_action={() => handleStatusAction("Uploading")}
        />
        <ConfirmButton
          confirm_text={"Set Ready"}
          confirm_action={() => handleStatusAction("Ready")}
        />
        <ConfirmButton
          confirm_text={"Set Archived"}
          confirm_action={() => handleStatusAction("Archived")}
        />
        <ConfirmButton
          confirm_text={"Clear Bookmark"}
          confirm_action={() => handleStatusAction("Clear Bookmark")}
        />
      </div>
    </>
  );
};
