import { styled } from "@mui/material/styles";
import React, { useState, useContext, useEffect, Fragment } from "react";
import { Metablock } from "../../MetaBox";
import { Input, TextArea } from "../styles";
import { DataContext } from "../../../common/DataContext";
import useToggle from "../../../hooks/useToggle";
import { Navigate } from "react-router-dom";
import vehicle_icon from "../../../images/carIcon.png";
import camera_icon from "../../../images/cameraIcon.png";
import edit_icon from "../../../images/edit_icon.png";
import view_icon from "../../../images/view_icon.png";
import delete_icon from "../../../images/delete_icon.png";
import downloadIcon from "../../../images/download_icon.png";
import { ProjectIcon } from "./styles";
import { v4 as uuidv4 } from "uuid";
import { MetablockWrapper } from "../../Viewer/styles";
import { LoadingSpinner } from "../../commonComponents/commonComponents";
import {
  Checkbox,
  Divider,
  Modal,
  TableCell,
  TableRow,
  Typography,
  Table,
  TableBody,
} from "@mui/material";
import {
  CancelButton,
  CloseButton,
  ConfirmButton,
  ModalWrapper,
  SectionSubtitle,
  SectionTitle,
  ListHead,
  TableCard,
  TableEntryRow,
  TableEntryCell,
  CancelConfirmButtons,
  Thumbnail,
  ConfirmModalCommon,
  TextFieldComponent,
} from "../../commonComponents/commonComponents";
var redirectLink = "/";
export const TripFootageTab = (props) => {
  const [page, setPage] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleName, setSelectedVehicleName] = useState(null);
  const [selectedVehicleDays, setSelectedVehicleDays] = useState(null);
  const [selectedVehicleStartDate, setSelectedVehicleStartDate] =
    useState(null);
  const [selectedVehicleEndDate, setSelectedVehicleEndDate] = useState(null);
  const [selectedVehicleCameras, setSelectedVehicleCameras] = useState(null);
  const [selectedVehicleMake, setSelectedVehicleMake] = useState(null);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
  const [newVehicles, setNewVehicles] = useState();
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [confirmOpen, toggleConfirmOpen] = useToggle(false);
  const [addVehicleModalOpen, toggleAddVehicleModalOpen] = useToggle(false);
  const [deleteVehicleOpen, toggleDeleteVehicleOpen] = useToggle(false);
  const [modalType, setModalType] = useState(null);
  const [modalTitleText, setModalTitleText] = useState(null);
  const [redirect, toggleRedirect] = useToggle(false);
  const [displayCameras, setDisplayCameras] = useState([]);
  const [deleteConfirmOpen, toggleDeleteConfirmOpen] = useToggle(false);
  const [editVehicleModalOpen, toggleEditVehicleOpen] = useToggle(false);


  const [editCameraModalOpen, toggleEditCameraOpen] = useToggle(false);
  const [addCameraModalOpen, toggleAddCameraOpen] = useToggle(false);

  const [modalQuestion, setModalQuestion] = useState("");
  const [modalInfo, setModalInfo] = useState("");
  const [deleteTarget, setDeleteTarget] = useState("Vehicle");
  const [modalButton1Text, setModalButton1Text] = useState("Cancel");
  const [modalButton2Text, setModalButton2Text] = useState("Delete");
  const [imageModalQuestion, setImageModalQuestion] = useState("");
  const [imageModalInfo, setImageModalInfo] = useState("");
  const [selectedCameraFacing,setSelectedCameraFacing]=useState(null)
  const [selectedCameraMake,setSelectedCameraMake]=useState(null)
  const [selectedCameraModel,setSelectedCameraModel]=useState(null)

  const {
    deleteOriginals,
    toggleDeleteOriginals,
    fetch_image_urls,
    setSequenceDate,
    setSequenceFilename,
    setSelectionType,
    selectionType,
    fetchTripVehicles,
    tripVehicles,
    vehicleCameras,
    fetchVehicleCameras,
    fetchVehicleDays,
    vehicleDays,
    setVehicleDays,
    fetch_day_sequences,
    daySequences,
    setDaySequences,
    sequenceImages,
    selectedSequence,
    setSequenceImages,
    fetch_sequence_images,
    delete_image,
    deleteSequence,
    fetch_image_details,
    selectedImage,
    setSelectedImage,
    metaBlockOpen,
    handleToggleMetaBlock,
    deleteTripVehicle,
    deleteDay,
    editTripVehicle,
    handleSetTripSelected,
    tripStartDate,
    setTripStartDate,
    tripEndDate,
    setTripEndDate,
    ViewProjectSequencesClicked,
    tripSelected,
    toggleHasImagery,
    setcurrentFeatureCollectionIndex,
    setNewtripSelected,
    setSelectedPictureDirection,
    setSelectedFeature,
    setSelectedSequence,
    editCamera,
    addCamera


  } = useContext(DataContext);

  useEffect(() => {
    fetchTripVehicles(props.tripSelected);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let facings = [];
    if (tripVehicles && tripVehicles.length > 0) {
      for (let i = 0; i < tripVehicles.length; i++) {
        let currentVehicle = tripVehicles[i];
        if (
          currentVehicle &&
          currentVehicle.cameras &&
          currentVehicle.cameras.length > 0
        ) {
          for (let i = 0; i < currentVehicle.cameras.length; i++) {
            let camera = currentVehicle.cameras[i];
            if (!facings.includes(camera.facing)) {
              facings.push(camera.facing);
            }
          }
        }
      }
    }
    setDisplayCameras(facings);
    // eslint-disable-next-line
  }, [tripVehicles]);

  useEffect(() => {
    if (selectedImage) {
      fetch_image_details(selectedImage);
    }

    // eslint-disable-next-line
  }, [selectedImage]);

  useEffect(() => {
    if (page !== 4 && page !== 5) {
      metaBlockOpen ? handleToggleMetaBlock() : <></>;
    }
    // eslint-disable-next-line
  }, []);

  const handleSetPage = (direction) => {
    if (page === 1) {
      if (direction === "back") {
        props.close_action();
      } else {
        setPage(2);
      }
    }
    if (page === 2) {
      if (direction === "back") {
        setPage(1);
      } else {
        if (selectedDay) {
          setPage(3);
          fetchVehicleCameras(selectedVehicle, selectedDay);
        } else {
          alert("A Travel Day must first be selected");
        }
      }
    }
    if (page === 3) {
      if (direction === "back") {
        setPage(2);
      } else {
        if (selectedCamera) {
          setPage(4);
          fetch_day_sequences(selectedDay, selectedCamera);
        } else {
          alert("A camera must first be selected");
        }
      }
    }
    if (page === 4) {
      if (direction === "back") {
        setPage(3);
      } else {
        if (selectedSequence) {
          setPage(5);
          fetch_sequence_images(selectedSequence);
        } else {
          alert("A sequence must first be selected");
        }
      }
    }
    if (page === 5) {
      if (direction === "back") {
        setPage(4);
      }
      // else{
      //   if(selectedSequence){
      //     setPage(5)
      //     fetch_sequence_images(selectedSequence)
      //   }

      // }
    }
  };

  const handleSetSelectedVehicle = (
    id,
    name,
    days,
    startDate,
    endDate,
    cameras,
    make,
    model
  ) => {
    setSelectedVehicle(id);
    setSelectedVehicleName(name);
    setSelectedVehicleDays(days);
    setSelectedVehicleStartDate(startDate);
    setSelectedVehicleEndDate(endDate);
    setSelectedVehicleCameras(cameras);
    setSelectedVehicleMake(make);
    setSelectedVehicleModel(model);
    fetchVehicleDays(id);
  };

  const handleSetSelectedCamera = (id,facing,make,model) => {
    console.log(facing)
    setSelectedCamera(id);
    setSelectedCameraFacing(facing)
    setSelectedCameraModel(model)
    setSelectedCameraMake(make)
  };

  const handleSetSelectedDay = (id) => {
    setSelectedDay(id);
  };

  const handleSetSelectedSequence = (
    id,
    video_filename,
    first_picture_date
  ) => {
    setSequenceFilename(video_filename);
    setSequenceDate(first_picture_date);
    setSelectedSequence(id);
  };

  const handleSetSelectedImage = (id, url) => {
    setSelectedImage(id);
    setSelectedImageURL();
    fetch_image_details(id);
  };

  const handleConfirmOpen = (id) => {
    setDeleteTarget('sequence')
    setModalQuestion(`Are you sure you want to delete the selected Sequence?`);
    setModalInfo(
      "-This action will delete the sequence and all of the images and vector tiles associated with this sequence. Clearing your browser cached vector tiles is recommended so that the changes will be reflected on the map."
    );
    setModalButton2Text("Cancel");
    setModalButton2Text("Delete");
    toggleDeleteConfirmOpen();
  };

  const handleDeleteVehicleOpen = () => {
    setDeleteTarget("Vehicle");
    setModalQuestion("Are you sure you want to delete this Vehicle?");
    setModalInfo(
      "CAUTION: This will also delete any Cameras, Travel Days, Sequences and Images associated with this Vehicle!"
    );
    handleDeleteConfirmOpen();
  };

  const handleDeleteImage = () => {
    delete_image(selectedImage);
    toggleConfirmOpen(false);
    fetch_sequence_images(selectedSequence);
  };

  const handleDeleteSequence = () => {
    toggleDeleteConfirmOpen();
    setModalQuestion(
      `Would you like to delete the original images in cloud storage as well?`
    );
    setModalInfo(
      "CAUTION! This action CANNOT be undone! Delete these images by accident and you will never hear the end of it!"
    );
    setModalButton1Text("Preserve");
    setModalButton2Text("Delete Originals");
    toggleDeleteConfirmOpen();
  };

  const handleConfirmDeleteToAnnoyUser = () => {
    toggleDeleteConfirmOpen();
    setModalQuestion(
      `...Yeah but are you like 100%, ABSOLUTELY positive you want to delete the original images from the cloud?`
    );
    setModalInfo(
      "It can't be stressed enough how much this action CANNOT be undone. Deleting these images by accident will place GREAT dishonor on you and and your house, and your computer will redirect all URLs to a Portuguese gaming website until the end of time."
    );
    setModalButton1Text("Preserve");
    setModalButton2Text("Yes, Delete");
    toggleDeleteConfirmOpen();
  };




  const handleSetDeleteOriginals = () => {
      handleDeleteProceed(true);
  };

  const handleDeleteProceed = (bool) => {
    console.log(deleteTarget,bool)
    if (deleteTarget==='sequence'){
      deleteSequence(bool);
    }
    if (deleteTarget==='camera'){
      // deleteCamera(bool);
    }
    toggleDeleteConfirmOpen();
  };

  const handleViewSelectedImage = (image_id, image_index, image_facing) => {
    ViewProjectSequencesClicked(
      props.tripSelected,
      selectedSequence,
      image_id,
      image_index,
      image_facing,
      true
    );
    toggleRedirect();
  };

  const handleMetaBlock = (id, type, filename = null, date = null) => {
    setSelectionType(type);
    if (type === "image") {
      setSelectedImage(id);
      handleToggleMetaBlock();
    } else if (type === "sequence") {
      setSelectedSequence(id);
      setSequenceFilename(filename);
      setSequenceDate(date);
      handleToggleMetaBlock(true);
    }
  };

  const handleDeleteVehicle = () => {
    handleDeleteConfirmOpen(false);
  };

  const handleEditVehicle = () => {
    editTripVehicle(
      selectedVehicle,
      selectedVehicleName,
      selectedVehicleCameras,
      selectedVehicleDays
    );
  };

  const handleEditVehicleOpen = (e = null) => {
    setModalType("Edit");
    if (e) {
      toggleEditVehicleOpen(e);
    } else {
      toggleEditVehicleOpen();
    }
  };

  const handleAddCameraOpen = (e = null) => {
    setModalType("Add");
    if (e) {
      toggleEditCameraOpen(e);
    } else {
      toggleEditCameraOpen();
    }
  };


  const handleEditCameraOpen = (e=null)=>{
    setModalType("Edit");
    if (e) {
      toggleEditCameraOpen(e);
    } else {
      toggleEditCameraOpen();
    }

  }



  const handleAddVehicleOpen = (e) => {
    setModalType("Add");
    if (e) {
      toggleAddVehicleModalOpen(e);
    } else {
      toggleAddVehicleModalOpen();
    }
    setPage(1);
  };

  const handleDeleteDayOpen = () => {
    setDeleteTarget("Day");
    setModalQuestion("Are you sure you want to delete this Day of Travel?");
    setModalInfo(
      "CAUTION: This will also delete any Cameras, Sequences and Images associated with this Day of Travel!"
    );
    handleDeleteConfirmOpen();
  };



  const handleDeleteCameraOpen = () => {
    setDeleteTarget("Camera");
    setModalQuestion("Are you sure you want to delete this Camera?");
    setModalInfo(
      "CAUTION: This will also delete any Days, Sequences and Images associated with this Camera and cannot be undone!"
    );
    handleDeleteConfirmOpen();
  };





  const handleDeleteDay = () => {
    deleteDay(selectedDay);
    handleDeleteConfirmOpen(false);
  };

  const handleDelete = () => {
    // if (deleteTarget === "Vehicle") {
    //   handleDeleteVehicle();
    // }
    // if (deleteTarget === "Day") {
    //   handleDeleteDay();
    // }
  };

  const handleViewTrip = (id) => {
    setSelectedPictureDirection(null);
    setSelectedFeature(null);
    setSelectedSequence(null);
    setNewtripSelected(true);

    setcurrentFeatureCollectionIndex(0);
    ViewProjectSequencesClicked(tripSelected, id);
    // setSelectedView("Front");
    toggleHasImagery(true);
    redirectLink = "/viewer";
    toggleRedirect();
  };

  const handleDeleteConfirmOpen = (bool) => {
    if (bool) {
      toggleDeleteConfirmOpen(bool);
    } else {
      toggleDeleteConfirmOpen();
    }
  };

  const handleSetModalTitleText = (e) => {
    setModalTitleText(e);
  };

  function formatDate(datetime) {
    const date = new Date(datetime);
    const timezoneOffset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);
    const month = adjustedDate.getMonth() + 1; // Months are 0-indexed
    const day = adjustedDate.getDate();
    const year = adjustedDate.getFullYear() % 100; // Extract last two digits of the year
    return `${month}/${day}/${year}`;
  }
  const handleSetVehicles = () => {
    setNewVehicles();
  };

  const fetchUrls = () => {
    toggleDeleteConfirmOpen();
    fetch_image_urls(selectedSequence);
  };

  const handleFetchImageUrls = (id) => {
    setModalButton2Text("Download");
    setModalQuestion(
      "Are you sure you want to download this entire sequence of images?"
    );
    setModalInfo(
      "This could take several minutes depending on the length of the sequence. The zipped folder will appear in your downloads folder when complete"
    );
    handleDeleteConfirmOpen();
  };

  return (
    <>
      <LoadingSpinner />
      {/* <AddDayModal
        addDayOpen={addDayOpen}
        handleAddDayOpen={handleAddDayOpen}
        form={form}
        setForm={setForm}
        handleSetFacings={handleSetFacings}
        handleCreateProject={handleCreateProject}
      /> */}
      <NewVehicleModal
        modalState={addVehicleModalOpen}
        modalHandler={handleAddVehicleOpen}
        modalType={modalType}
        vehicleIndex={props.vehicleIndex}
        name={selectedVehicleName}
        days={selectedVehicleDays}
        cameras={selectedVehicleCameras}
        handleSetName={props.handleSetName}
        daysOfTravel={props.daysOfTravel}
        handleSetDays={props.handleSetDays}
        vehicleText={modalTitleText}
        selectedVehicle={selectedVehicle}
        handleSetModalTitleText={handleSetModalTitleText}
        handleAddVehicleOpen={handleAddVehicleOpen}
        handleEditVehicle={handleEditVehicle}
        // selectedVehicleName={selectedVehicleName}
        setSelectedVehicleName={setSelectedVehicleName}
        setSelectedVehicleDays={setSelectedVehicleDays}
        setSelectedVehicleCameras={setSelectedVehicleCameras}
      />
      <EditVehicleModal
        title={"Add Vehicle"}
        function={modalType}
        modalState={editVehicleModalOpen}
        modalHandler={handleEditVehicleOpen}
        modalType={modalType}
        vehicleIndex={props.vehicleIndex}
        name={selectedVehicleName}
        days={selectedVehicleDays}
        startDate={selectedVehicleStartDate}
        endDate={selectedVehicleEndDate}
        cameras={selectedVehicleCameras}
        make={selectedVehicleMake}
        model={selectedVehicleModel}
        handleSetName={props.handleSetName}
        daysOfTravel={props.daysOfTravel}
        handleSetDays={props.handleSetDays}
        vehicleText={modalTitleText}
        selectedVehicle={selectedVehicle}
        handleSetModalTitleText={handleSetModalTitleText}
        handleAddVehicleOpen={handleAddVehicleOpen}
        handleEditVehicle={handleEditVehicle}
        // selectedVehicleName={selectedVehicleName}
        setSelectedVehicleName={setSelectedVehicleName}
        setSelectedVehicleDays={setSelectedVehicleDays}
        setSelectedVehicleCameras={setSelectedVehicleCameras}
      />
      <EditCameraModal
        modalState={editCameraModalOpen}
        modalHandler={handleEditCameraOpen}
        function={modalType}
        selectedCamera={selectedCamera}
        selectedCameraFacing={selectedCameraFacing}
        selectedCameraModel={selectedCameraModel}
        selectedCameraMake={selectedCameraMake}
        selectedDay={selectedDay}

      />
      <ConfirmModalCommon
        modal_open={deleteConfirmOpen}
        handleOpenCloseModal={handleDeleteConfirmOpen}
        interrogative={modalQuestion}
        informative={modalInfo}
        button_1_text={modalButton1Text}
        button_2_text={modalButton2Text}
        button_2_action={
          modalButton2Text === "Delete"
            ? handleDeleteSequence
            : modalButton2Text === "Delete Originals"
            ? handleConfirmDeleteToAnnoyUser
            : modalButton2Text === "Yes, Delete"
            ? handleSetDeleteOriginals
            :() => fetchUrls()


            
        }
        button_1_action={
          modalButton1Text === "Cancel"
            ? handleDeleteConfirmOpen
            : modalButton1Text === "Preserve"
            ? (e) => handleSetDeleteOriginals(false)
            : (e) => console.log("YO")
        }
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "45vw",
          height: "70vh",
          margin: "auto",
          marginTop: "0vh",
        }}
      >
        {page === 1 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "auto",
                width: "100%",
                // backgroundColor:'green'
              }}
            >
              <SectionTitle
                title_text={"Select Vehicle"}
                paddingLeft={"0vw"}
                paddingRight={"9.5vw"}
              />
              <ConfirmButton
                confirm_action={() => handleAddVehicleOpen()}
                confirm_text={"Add"}
              />
              <ConfirmButton
                confirm_action={() => handleEditVehicleOpen()}
                confirm_text={"Edit"}
              />
              <ConfirmButton
                confirm_action={() => handleDeleteVehicleOpen()}
                confirm_text={"Delete"}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "60vh",
                overflowY: "scroll",
                boxShadow: "1px 1px 6px 2px gray",
              }}
            >
              {tripVehicles &&
                tripVehicles.slice().map((row) => {
                  const {
                    id,
                    name,
                    travel_days,
                    start_date,
                    end_date,
                    cameras,
                    make,
                    model,
                  } = row;
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "2vh",
                        height: "20vh",
                        width: "90%",
                        boxShadow: "1px 1px 6px 2px gray",
                        marginBottom: "2vh",
                      }}
                      key={uuidv4()}
                    >
                      <ProjectIcon
                        src={vehicle_icon}
                        key={uuidv4()}
                        style={{
                          margin: "auto",
                          marginLeft: "1vw",
                          height: "3vh",
                          width: "auto",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "15vh",
                          width: "80%",
                          alignItems: "left",
                          justifyContent: "left",
                          margin: "auto",
                        }}
                        key={uuidv4()}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "left",
                            marginLeft: "0vw",
                          }}
                          key={uuidv4()}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Vehicle:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={name}
                            key={uuidv4()}
                          />
                          <span style={{ width: "2vw" }} />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Make:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={make}
                            key={uuidv4()}
                          />

                          <span style={{ width: "2vw" }} />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Model:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={model}
                            key={uuidv4()}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "left",
                          }}
                          key={uuidv4()}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Total Days:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={travel_days}
                            key={uuidv4()}
                          />
                          <span style={{ width: "2vw" }} />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Dates:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={`${formatDate(
                              start_date
                            )}-${formatDate(end_date)}`}
                            key={uuidv4()}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "left",
                          }}
                          key={uuidv4()}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Cameras:</strong>}
                            key={uuidv4()}
                          />
                          {cameras &&
                            cameras.slice().map((camera) => {
                              return (
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={camera}
                                  key={uuidv4()}
                                />
                              );
                            })}
                        </div>
                      </div>
                      <input
                        type="radio"
                        value={id}
                        style={{ marginRight: "2vw" }}
                        onChange={(e) =>
                          handleSetSelectedVehicle(
                            id,
                            name,
                            travel_days,
                            start_date,
                            end_date,
                            cameras,
                            make,
                            model
                          )
                        }
                        checked={selectedVehicle === id}
                        key={uuidv4()}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        ) : page === 2 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "auto",
                  width: "100%",
                  // backgroundColor:'green'
                }}
              >
                <SectionTitle
                  title_text={"Select Day of Travel:"}
                  paddingLeft={"2vw"}
                  paddingRight={"2vw"}
                />
                <ConfirmButton
                  // confirm_action={() => handleAddVehicleOpen()}
                  confirm_text={"Add"}
                />
                <ConfirmButton
                  // confirm_action={() => handleEditVehicleOpen()}
                  confirm_text={"Edit"}
                />
                <ConfirmButton
                  confirm_action={() => handleDeleteDayOpen()}
                  confirm_text={"Delete"}
                />
              </div>

              <TableCard
                style={{
                  boxShadow: "1px 1px 6px 2px gray",
                  margin: "auto",
                  height: "50vh",
                  width: "90%",
                  marginTop: "3vh",
                  marginBottom: "2vh",
                  overflowY: "scroll",
                }}
              >
                <Table style={{ height: "auto" }}>
                  <ListHead
                    // sortTrips={sortTrips}
                    headLabel={VEHICLE_DAYS_TABLE_HEAD}
                    // operator={sortOperator}
                    tableData={vehicleDays}
                    updateData={setVehicleDays}
                    vw={"45vw"}
                  />
                  <TableBody>
                    {vehicleDays &&
                      vehicleDays.slice().map((row) => {
                        const { id, day_of_travel, date, city, cloud_path } =
                          row;
                        return (
                          <TableEntryRow
                            sx={{
                              "&:hover": {
                                backgroundColor: "rgba(145, 165, 172, 0.5)",
                                cursor: "pointer",
                              },
                            }}
                            align="center"
                            // hover
                            key={id}
                            tabIndex={-1}
                            onClick={() => handleSetSelectedDay(id)}
                            selected={selectedDay === id}
                            // onDoubleClick={() => view_all_project_sequences(id)}
                          >
                            <TableEntryCell
                              entry={<strong>{day_of_travel}</strong>}
                            />
                            <TableEntryCell entry={date ? date : "N/A"} />
                            <TableEntryCell entry={city ? city : "N/A"} />
                          </TableEntryRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableCard>
            </div>
          </>
        ) : page === 3 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "auto",
                width: "100%",
                // backgroundColor:'green'
              }}
            >
              <SectionTitle
                title_text={"Select Camera:"}
                paddingLeft={"2vw"}
                paddingRight={"2vw"}
              />
              <ConfirmButton
                confirm_action={() => handleAddCameraOpen()}
                confirm_text={"Add"}
              />
              <ConfirmButton
                confirm_action={() => handleEditCameraOpen()}
                confirm_text={"Edit"}
              />
              <ConfirmButton
                confirm_action={() => handleDeleteCameraOpen()}
                confirm_text={"Delete"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "60vh",
                overflowY: "scroll",
                boxShadow: "1px 1px 6px 2px gray",
              }}
            >
              {vehicleCameras &&
                vehicleCameras
                  .slice()
                  .sort((a, b) => {
                    const facingOrder = ["Front", "Left", "Right", "360"];
                    const indexA = facingOrder.indexOf(a.facing);
                    const indexB = facingOrder.indexOf(b.facing);
                    return indexA - indexB;
                  })

                  .map((row) => {
                    const { id, facing, type, model } = row;
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "2vh",
                          height: "15vh",
                          width: "60%",
                          boxShadow: "1px 1px 6px 2px gray",
                          marginBottom: "2vh",
                        }}
                        key={uuidv4()}
                      >
                        <ProjectIcon
                          src={camera_icon}
                          style={{
                            margin: "auto",
                            marginLeft: "1vw",
                            height: "3vh",
                            width: "auto",
                          }}
                          key={uuidv4()}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "15vh",
                            width: "80%",
                            alignItems: "left",
                            justifyContent: "left",
                            margin: "auto",
                          }}
                          key={uuidv4()}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                              marginLeft: "0vw",
                            }}
                            key={uuidv4()}
                          >
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={<strong>Camera Facing:</strong>}
                              key={uuidv4()}
                            />
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={<strong>{facing}</strong>}
                              key={uuidv4()}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                            }}
                            key={uuidv4()}
                          >
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={<strong>Make:</strong>}
                              key={uuidv4()}
                            />
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={type ? type : "N/A"}
                              key={uuidv4()}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                            }}
                            key={uuidv4()}
                          >
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={<strong>Model:</strong>}
                              key={uuidv4()}
                            />
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={model ? model : "N/A"}
                              key={uuidv4()}
                            />
                          </div>
                        </div>
                        <input
                          type="radio"
                          value={id}
                          style={{ marginRight: "3vw" }}
                          onChange={(e) => handleSetSelectedCamera(id,facing,type,model)}
                          checked={selectedCamera === id}
                          key={uuidv4()}
                        />
                      </div>
                    );
                  })}
            </div>
          </>
        ) : page === 4 ? (
          <>
            <SectionTitle title_text={"Select Sequence"} />
            <TableCard
              style={{
                boxShadow: "1px 1px 6px 2px gray",
                margin: "auto",
                height: "50vh",
                width: "90%",
                marginTop: "3vh",
                marginBottom: "2vh",
                overflowY: "scroll",
              }}
            >
              <Table style={{ height: "100%" }}>
                <ListHead
                  // sortTrips={sortTrips}
                  headLabel={SEQUENCE_TABLE_HEAD}
                  // operator={sortOperator}
                  tableData={daySequences}
                  updateData={setDaySequences}
                  vw={"45vw"}
                />
                <TableBody>
                  {daySequences &&
                    daySequences.slice().map((row) => {
                      const {
                        id,
                        index,
                        total_images,
                        video_filename,
                        first_picture_date,
                      } = row;
                      return (
                        <TableEntryRow
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(145, 165, 172, 0.5)",
                              cursor: "pointer",
                            },
                          }}
                          align="center"
                          // hover
                          key={id}
                          tabIndex={-1}
                          onClick={() =>
                            handleSetSelectedSequence(
                              id,
                              video_filename,
                              first_picture_date
                            )
                          }
                          selected={selectedSequence === id}
                        >
                          <TableEntryCell entry={<strong>{index}</strong>} />
                          <TableEntryCell entry={id} />
                          <TableEntryCell entry={total_images} />

                          <TableCell>
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "auto",
                                  width: "4vw",
                                  // backgroundColor:'grey'
                                }}
                                key={uuidv4()}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginRight: "1vw",
                                    height: "auto",
                                    width: "auto",
                                    // backgroundColor:'lightgrey'
                                  }}
                                  key={uuidv4()}
                                >
                                  <ProjectIcon
                                    key={uuidv4()}
                                    src={edit_icon}
                                    style={{
                                      // marginTop: "0vh",
                                      marginBottom: "1.5vh",
                                      height: "auto",
                                      width: "1.5vw",
                                    }}
                                    onClick={(e) =>
                                      handleMetaBlock(id, "sequence")
                                    }
                                  />
                                  <ProjectIcon
                                    key={uuidv4()}
                                    src={view_icon}
                                    style={{
                                      marginTop: "0vh",
                                      marginBottom: "1vh",
                                      height: "auto",
                                      width: "1.5vw",
                                    }}
                                    onClick={() => handleViewTrip(id)}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "0vh",
                                    marginRight: "0vw",
                                    height: "auto",
                                    width: "auto",
                                    // backgroundColor:'lightgrey'
                                  }}
                                  key={uuidv4()}
                                >
                                  {/* <a href={image_url} download> */}
                                  <ProjectIcon
                                    key={uuidv4()}
                                    src={downloadIcon}
                                    style={{
                                      marginTop: "0vh",
                                      marginBottom: "1vh",
                                      height: "auto",
                                      width: "1.5vw",
                                    }}
                                    onClick={() => handleFetchImageUrls(id)}
                                  />
                                  {/* </a> */}
                                  <ProjectIcon
                                    key={uuidv4()}
                                    src={delete_icon}
                                    style={{
                                      height: "auto",
                                      width: "1.5vw",
                                    }}
                                    onClick={() =>
                                      handleConfirmOpen(id, "delete")
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          </TableCell>
                        </TableEntryRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableCard>
          </>
        ) : page === 5 ? (
          <>
            <ConfirmModalCommon
              modal_open={confirmOpen}
              button_1_action={toggleConfirmOpen}
              interrogative={imageModalQuestion}
              informative={imageModalInfo}
              button_1_text={"Cancel"}
              button_2_action={handleDelete}
              button_2_text={"Confirm"}
            />
            <SectionTitle title_text={"Select Image"} />
            <TableCard
              style={{
                boxShadow: "1px 1px 6px 2px gray",
                margin: "auto",
                height: "50vh",
                width: "98%",
                marginTop: "3vh",
                marginBottom: "2vh",
                overflowY: "scroll",
              }}
            >
              <Table style={{ height: "100%", marginBottom: "2vh" }}>
                <ListHead
                  // sortTrips={sortTrips}
                  headLabel={IMAGE_TABLE_HEAD}
                  // operator={sortOperator}
                  tableData={sequenceImages}
                  updateData={setSequenceImages}
                  vw={"45vw"}
                />
                <TableBody>
                  {sequenceImages &&
                    sequenceImages.slice().map((row) => {
                      const { id, index, image_facing, timestamp, image_url } =
                        row;
                      return (
                        <TableEntryRow
                          style={{}}
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(145, 165, 172, 0.5)",
                              cursor: "pointer",
                            },
                          }}
                          align="center"
                          key={uuidv4()}
                          tabIndex={-1}
                          onClick={() => handleSetSelectedImage(id, image_url)}
                          selected={selectedImage === id}
                          // onDoubleClick={() => view_all_project_sequences(id)}
                        >
                          <TableEntryCell
                            entry={<strong>{index}</strong>}
                            key={uuidv4()}
                          />
                          <TableEntryCell entry={timestamp} key={uuidv4()} />

                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: ".5vh",
                                justifyContent: "center",
                              }}
                              onClick={(e) =>
                                props.handleImagePreviewOpen(image_url)
                              }
                              key={uuidv4()}
                            >
                              <Thumbnail
                                key={uuidv4()}
                                src={image_url}
                                alt={image_url}
                                maxWidth={100}
                                maxHeight={100}
                              />
                            </div>
                          </td>

                          <TableCell>
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "0vh",
                                  marginRight: "0vw",
                                  height: "auto",
                                  width: "1.5vw",
                                  // backgroundColor:'grey'
                                }}
                                key={uuidv4()}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "auto",
                                    width: "4vw",
                                    // backgroundColor:'grey'
                                  }}
                                  key={uuidv4()}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginRight: "1vw",
                                      height: "auto",
                                      width: "auto",
                                      // backgroundColor:'lightgrey'
                                    }}
                                    key={uuidv4()}
                                  >
                                    <ProjectIcon
                                      key={uuidv4()}
                                      src={edit_icon}
                                      style={{
                                        // marginTop: "0vh",
                                        marginBottom: ".5vh",
                                        height: "auto",
                                        width: "1.5vw",
                                      }}
                                      onClick={() =>
                                        handleMetaBlock(id, "image")
                                      }
                                    />
                                    <ProjectIcon
                                      key={uuidv4()}
                                      src={view_icon}
                                      style={{
                                        marginTop: ".5vh",
                                        marginBottom: ".5vh",
                                        height: "auto",
                                        width: "1.5vw",
                                      }}
                                      onClick={() =>
                                        handleViewSelectedImage(
                                          id,
                                          index,
                                          image_facing
                                        )
                                      }
                                    />
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginRight: "1vw",
                                      height: "auto",
                                      width: "auto",
                                      // backgroundColor:'lightgrey'
                                    }}
                                    key={uuidv4()}
                                  >
                                    <a href={image_url} download>
                                      <ProjectIcon
                                        key={uuidv4()}
                                        src={downloadIcon}
                                        style={{
                                          marginTop: "0vh",
                                          marginBottom: ".5vh",
                                          height: "auto",
                                          width: "1.5vw",
                                        }}
                                        // onClick={() => handleConfirmOpen(id,'download')}
                                      />
                                    </a>
                                    <ProjectIcon
                                      key={uuidv4()}
                                      src={delete_icon}
                                      style={{
                                        height: "auto",
                                        width: "1.5vw",
                                      }}
                                      onClick={() =>
                                        handleConfirmOpen(id, "delete")
                                      }
                                    />
                                  </div>
                                </div>
                                {/*
                                 */}
                              </div>
                            </>
                          </TableCell>
                        </TableEntryRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableCard>
            {!redirect ? <></> : <Navigate push to="/viewer" />}
          </>
        ) : (
          <></>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "2vh",
            marginBottom: "2vh",
          }}
        >
          <CancelButton
            cancel_action={(e) => handleSetPage("back")}
            cancel_text={page === 1 ? "Cancel" : "Back"}
          />
          {page !== 5 ? (
            <>
              <ConfirmButton
                confirm_action={() => handleSetPage("next")}
                confirm_text={"Next"}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      {metaBlockOpen ? (
        <>
          <MetablockWrapper>
            <Metablock selectionType={selectionType} />
          </MetablockWrapper>
        </>
      ) : null}
      {!redirect ? null : <Navigate push to="/viewer" />}
    </>
  );
};

// ADD PROJECT MODAL //
export const AddDayModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.addDayOpen) {
        props.handleAddDayOpen(); // Call the function to close the modal
      }
    }
  };
  return (
    <Modal open={props.addDayOpen} key="add" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleAddDayOpen} />

        <SectionTitle title_text={<strong>Add day of travel</strong>} />
        <SectionSubtitle subtitle_text={<strong>Vehicle</strong>} />
        <TextField
          placeholder={"Vehicle"}
          name={"vehicle"}
          form={props.form}
          setForm={props.setForm}
          // value={props.form.vehicle}
        />
        <SectionSubtitle subtitle_text={<strong>Day Number</strong>} />
        <TextField
          placeholder={"Day Number"}
          name={"dayNumber"}
          form={props.form}
          // value={props.form.dayNumber}
          setForm={props.setForm}
        />
        <SectionSubtitle
          subtitle_text={<strong>City / Region Visited</strong>}
        />
        <TextField
          placeholder={"City / Region Visited"}
          name={"city"}
          form={props.form}
          // value={props.value}
          setForm={props.setForm}
        />
        <SectionSubtitle subtitle_text={<strong>Date</strong>} />
        <input
          type="date"
          name={"date"}
          // value={props.dueDate}
          onChange={(e) => {
            props.setForm({ ...props.form, [e.target.name]: e.target.value });
          }}
          style={{ height: "5vh", marginLeft: "2.5vw", width: "90%" }}
        />

        <SectionSubtitle subtitle_text={<strong>Camera Facings</strong>} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "3vh",
          }}
        >
          <SectionSubtitle subtitle_text={<strong>Front</strong>} />
          <input
            type="checkbox"
            value={"Front"}
            onChange={(e) => props.handleSetFacings(e)}
          />
          <SectionSubtitle subtitle_text={<strong>Left</strong>} />
          <input
            type="checkbox"
            value={"Left"}
            onChange={(e) => props.handleSetFacings(e)}
          />
          <SectionSubtitle subtitle_text={<strong>Right</strong>} />
          <input
            type="checkbox"
            value={"Right"}
            onChange={(e) => props.handleSetFacings(e)}
          />
          <SectionSubtitle subtitle_text={<strong>360</strong>} />
          <input
            type="checkbox"
            value={"360"}
            onChange={(e) => props.handleSetFacings(e)}
          />
        </div>

        <div style={{ marginBottom: "3vh" }}>
          <CancelConfirmButtons
            confirm_text={"Add"}
            cancel_text={"Cancel"}
            confirm_action={props.handleCreateProject}
            cancel_action={props.handleAddDayOpen}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};

// PROJECT NAME TEXT FIELD //
export const TextField = (props) => {
  return (
    <Input
      type="text"
      placeholder={props.placeholder}
      name={props.name}
      style={{ marginLeft: "2.5vw" }}
      value={props.value}
      onChange={(e) => {
        props.setForm({ ...props.form, [e.target.name]: e.target.value });
      }}
    />
  );
};

// DELETE PROJECT MODAL //
export const DeleteTripModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.deleteOpen) {
        props.handleDeleteOpen(); // Call the function to close the modal
      }
    }
  };
  return (
    <Modal open={props.deleteOpen} key="delete" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleDeleteOpen} />
        <SectionTitle
          title_text={
            <strong>Are you sure you want to delete the following trip?</strong>
          }
        />
        <div style={{ marginBottom: "3vh" }}>
          <SectionSubtitle
            subtitle_text={<strong>Trip: {props.tripName}</strong>}
          />
        </div>
        <div style={{ marginBottom: "1vh" }}>
          <DeleteTripButtons
            handleDeleteOpen={props.handleDeleteOpen}
            handle_delete_trip={props.handle_delete_trip}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};

// DELETE PROJECT BUTTONS //
export const DeleteTripButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        lineHeight: "2.0em",
        justifyContent: "center",
      }}
    >
      <CancelButton
        cancel_action={props.handleDeleteOpen}
        cancel_text={"Cancel"}
      />
      <ConfirmButton
        confirm_action={props.handle_delete_trip}
        confirm_text={"Delete"}
      />
    </div>
  );
};

// MODIFY PROJECT BUTTONS //
export const ModifyProjectButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        marginBottom: "2vh",
      }}
    >
      <CancelButton
        cancel_action={props.cancel_action}
        cancel_text={props.cancel_text}
      />
      <ConfirmButton
        confirm_action={props.confirm_action}
        confirm_text={props.confirm_text}
      />
    </div>
  );
};

// MODIFY PROJECT DETAILS SECTION //
export const ProjectDetailsSection = (props) => {
  const [tripName, setTripName] = useState(null);
  const [tripCity, setTripCity] = useState(null);
  const [tripCountry, setTripCountry] = useState(null);
  const [tripDesc, setTripDesc] = useState(null);
  const [tripSelected, setTripSelected] = useState(null);

  const { modifyTripDetails } = useContext(DataContext);

  useEffect(() => {
    handleSetTripName(props.tripName);
    handleSetTripCountry(props.tripCountry);
    handleSetTripCity(props.tripCity);
    handleSetTripDesc(props.tripDescription);
    setTripSelected(props.tripSelected)
    // eslint-disable-next-line
  }, [props]);

  const handleSetTripName = (e) => {
    const value = e && e.target ? e.target.value : e;
    setTripName(value);
  };
  const handleSetTripCountry = (e) => {
    const value = e && e.target ? e.target.value : e;
    setTripCountry(value);
  };

  const handleSetTripCity = (e) => {
    const value = e && e.target ? e.target.value : e;
    setTripCity(value);
  };

  const handleSetTripDesc = (e) => {
    const value = e && e.target ? e.target.value : e;
    setTripDesc(value);
  };

  const handle_modify_trip_details = (tripSelected, name, desc, city, country) => {
    modifyTripDetails(tripSelected, name, desc, city, country);
    props.handleModifyOpen();
    setTripName(name);
    setTripCountry(country);
    setTripCity(city);
  };

  return (
    <>
      <SectionSubtitle subtitle_text={<strong>Name</strong>} />
      <Input
        style={{ margin: "auto", marginBottom: "2vh" }}
        value={tripName}
        type="text"
        name="name"
        placeholder="Name"
        onChange={(e) => handleSetTripName(e)}
      />
      <SectionSubtitle subtitle_text={<strong>City</strong>} />
      <Input
        style={{ margin: "auto", marginBottom: "2vh" }}
        value={tripCity}
        type="text"
        name="city"
        placeholder="City"
        onChange={(e) => handleSetTripCity(e)}
      />
      <SectionSubtitle subtitle_text={<strong>Country</strong>} />
      <Input
        style={{ margin: "auto", marginBottom: "2vh" }}
        value={tripCountry}
        type="text"
        name="country"
        placeholder="Country"
        onChange={(e) => handleSetTripCountry(e)}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <ModifyProjectButtons
          cancel_action={props.handleModifyOpen}
          cancel_text={"Cancel"}
          confirm_action={() =>
            handle_modify_trip_details(
                tripSelected,
                tripName,
                tripDesc,
                tripCity,
                tripCountry
            )
          }
          confirm_text={"Accept"}
          tripSelected={props.tripSelected}
        />
      </div>
    </>
  );
};

// SHARE PROJECT MODAL //
export const ShareProjectModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.shareOpen) {
        props.handleShareOpen(); // Call the function to close the modal
      }
    }
  };
  return (
    <Modal open={props.shareOpen} key="assign" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleShareOpen} />
        <SectionTitle
          title_text={"Share the project with other organizations or users"}
        />
        <SectionSubtitle
          subtitle_text={`Project -${props.projectSelected} will be shared with the selected organization or user`}
        />
        <SelectOrgUsersSection
          unassignSelected={props.unassignSelected}
          assignSelected={props.assignSelected}
          setAssignSelected={props.setAssignSelected}
          setUnassignSelected={props.setUnassignSelected}
        />
        <ShareProjectButtons
          form={props.form}
          handleShareOpen={props.handleShareOpen}
        />
      </ModalWrapper>
    </Modal>
  );
};

export const ShareProjectButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        marginBottom: "2vh",
      }}
    >
      <CancelButton
        cancel_action={props.handleShareOpen}
        cancel_text={"Cancel"}
      />
      <ConfirmButton
        confirm_action={props.modifyProjectDetails}
        confirm_text={"Accept"}
      />
    </div>
  );
};

// SELECT USERS SECTION //
export const SelectOrgUsersSection = (props) => {
  return (
    <>
      <SectionTitle title_text={"Organization ID"} />
      <Input
        style={{ marginLeft: "2.5vw", marginBottom: "2vh" }}
        type="text"
        name="name"
        placeholder="Name"
        onChange={(e) => {
          props.setForm({ ...props.form, [e.target.name]: e.target.value });
        }}
      />
      <SectionTitle title_text={"Project Description"} />
      <TextArea
        style={{ margin: "auto", marginBottom: "2vh" }}
        rows="4"
        name="desc"
        placeholder="Description"
        onChange={(e) => {
          props.setForm({ ...props.form, [e.target.name]: e.target.value });
        }}
      />
    </>
  );
};

export const AddDriveTeamModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.open) {
        props.close_action(); // Call the function to close the modal
      }
    }
  };

  const { fetchOrgUsers, orgUsers } = useContext(DataContext);
  const [tempUserList, setTempUserList] = useState([]);
  const [tempDriveTeam, setTempDriveTeam] = useState([]);
  const { driveTeam, setDriveTeam } = useContext(DataContext);

  useEffect(() => {
    fetchOrgUsers();
    setTempDriveTeam(props.driveTeam);
  }, []);

  useEffect(() => {
    let templist = orgUsers;
    setTempUserList(templist);
  }, [orgUsers]);

  const handleConfirm = () => {
    let tempTeam = tempDriveTeam;
    setDriveTeam(tempTeam);
    setTempDriveTeam([]);
    props.close_action();
  };

  const handleClick = (user) => {
    let tempList = [...tempUserList]; // Create a copy to avoid mutating the state directly
    let tempTeam = [...tempDriveTeam]; // Create a copy to avoid mutating the state directly

    if (tempList.includes(user)) {
      tempList = tempList.filter((item) => item !== user);
    } else {
      tempList = [...tempList, user];
    }

    if (tempTeam.includes(user)) {
      tempTeam = tempTeam.filter((item) => item !== user);
    } else {
      tempTeam = [...tempTeam, user];
    }
    setTempDriveTeam(tempTeam);
    setTempUserList(tempList);
  };

  return (
    <>
      <Modal open={props.open} key="addUser" onKeyDown={handleKeyDown}>
        <ModalWrapper>
          <CloseButton close_action={props.close_action} />
          <SectionTitle title_text={<strong>Edit Drive Team</strong>} />
          <SectionSubtitle
            subtitle_text={
              <strong>
                Double click to add or remove editors to the drive team
              </strong>
            }
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              height: "50vh",
              width: "100%",
              // backgroundColor: "blue",
              marginTop: "2vh",
            }}
          >
            <div
              style={{
                width: "40%",
                height: "100%",
                boxShadow: "1px 1px 6px 2px gray",
                marginRight: "2vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <h4>
                  <strong>Drive Team</strong>
                </h4>
              </div>
              {tempDriveTeam &&
                tempDriveTeam.slice().map((row) => {
                  const { name } = row;
                  return (
                    <>
                      <div
                        style={{ marginBottom: "1vh" }}
                        onDoubleClick={() => handleClick(row)}
                      >
                        <SectionSubtitle subtitle_text={name} />
                      </div>
                    </>
                  );
                })}
            </div>
            <div
              style={{
                width: "40%",
                height: "100%",
                overflowY: "scroll",
                boxShadow: "1px 1px 6px 2px gray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <h4>
                  <strong>Editors</strong>
                </h4>
              </div>
              {tempUserList &&
                tempUserList.slice().map((row) => {
                  const { id, name } = row;
                  return (
                    <>
                      <div
                        style={{ marginBottom: "1vh" }}
                        onDoubleClick={() => handleClick(row)}
                      >
                        <SectionSubtitle subtitle_text={name} />
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              // height:'50vh',
              // width: "100%",
              // backgroundColor: "grey",
              marginTop: "2vh",
              marginBottom: "2vh",
            }}
          >
            <ConfirmButton
              confirm_text={"Confirm"}
              confirm_action={(e) => handleConfirm(e)}
            />
            {/* <ConfirmButton confirm_text={'Remove'}/> */}
          </div>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export const ProjectUploadPathField = (props) => {
  return (
    <Input
      type="text"
      placeholder="Cloud Upload Path i.e. 'Images/Indonesia/Medan/Ipad1/Day1'"
      name="path"
      style={{ marginLeft: "2.5vw" }}
      value={props.form.path}
      onChange={(e) => {
        props.setForm({ ...props.form, [e.target.name]: e.target.value });
      }}
    />
  );
};

export const TripCountryField = (props) => {
  return (
    <Input
      type="text"
      placeholder="Country"
      name="country"
      style={{
        borderColor: props.isMissing ? "red" : undefined,
        borderWidth: props.isMissing ? "2px" : undefined,
        borderStyle: props.isMissing ? "solid" : undefined,
      }}
      value={props.country}
      onChange={(e) => {
        props.handleSetCountry(e);
      }}
    />
  );
};

// PROJECT COUNTRY NAME TEXT FIELD //

export const TripCityField = (props) => {
  return (
    <Input
      type="text"
      placeholder="City"
      name="city"
      value={props.city}
      onChange={(e) => {
        props.handleSetCity(e);
      }}
    />
  );
};

// PROJECT COUNTRY NAME TEXT FIELD //
export const ProjectDeviceField = (props) => {
  return (
    <Input
      type="text"
      placeholder="Device"
      name="device"
      style={{ marginLeft: "2.5vw" }}
      value={props.form.device}
      onChange={(e) => {
        props.setForm({ ...props.form, [e.target.name]: e.target.value });
      }}
    />
  );
};
// PROJECT COUNTRY NAME TEXT FIELD //
export const ProjectDayField = (props) => {
  return (
    <Input
      type="text"
      placeholder="Day of Travel"
      name="day_of_travel"
      style={{ marginLeft: "2.5vw" }}
      value={props.form.day_of_travel}
      onChange={(e) => {
        props.setForm({ ...props.form, [e.target.name]: e.target.value });
      }}
    />
  );
};
// ADD PROJECT BUTTONS //
export const AddTripButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        marginBottom: "1vh",
      }}
    >
      <CancelButton
        cancel_action={props.handleAddOpen}
        cancel_text={"Cancel"}
      />
      <ConfirmButton
        confirm_text={"Create"}
        confirm_action={() => props.confirm_action(props.form)}
      />
    </div>
  );
};

// PROJECT NAME TEXT FIELD //
export const TripNameField = (props) => {
  return (
    <Input
      type="text"
      placeholder="Trip Name"
      name="name"
      style={{
        borderColor: props.isMissing ? "red" : undefined,
        borderWidth: props.isMissing ? "2px" : undefined,
        borderStyle: props.isMissing ? "solid" : undefined,
      }}
      value={props.name}
      onChange={(e) => {
        props.handleSetName(e);
      }}
    />
  );
};

// PROJECT DESCRIPTION TEXT FIELD //
export const BackupFolderField = (props) => {
  return (
    <TextArea
      style={{
        paddingLeft: "1vw",
        paddingRight: "1vw",
        margin: "auto",
        marginTop: "1vh",
        marginBottom: "2vh",
        borderColor: props.isMissing ? "red" : undefined,
        borderWidth: props.isMissing ? "2px" : undefined,
        borderStyle: props.isMissing ? "solid" : undefined,
      }}
      rows="1"
      placeholder="Enter the folder where you would like Nudge to backup the original video files during processing"
      name="desc"
      value={props.backupFolder}
      onChange={(e) => {
        props.handleSetBackupFolder(e);
      }}
    />
  );
};

export const DriveTeamField = (props) => {
  return (
    <div
      style={{
        height: "20vh",
        width: "90%",
        boxShadow: "1px 1px 6px 2px gray",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "scroll",
        marginBottom: "2vh",
      }}
    >
      {props.driveTeam &&
        props.driveTeam.slice().map((row) => {
          const { name, id } = row;
          return (
            <>
              <div
                style={{
                  height: "3vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "1vh",
                }}
              >
                {name}
              </div>
            </>
          );
        })}
    </div>
  );
};

export const VehiclesField = (props) => {
  let counter = 1;
  return (
    <div
      style={{
        height: "40vh",
        width: "90%",
        boxShadow: "1px 1px 6px 2px gray",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "scroll",
        marginBottom: "2vh",
      }}
    >
      {props.vehicles &&
        props.vehicles.slice().map((row) => {
          const { name, make, model } = row;
          counter += 1;
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2vh",
                  height: "15vh",
                  width: "80%",
                  boxShadow: "1px 1px 6px 2px gray",
                  marginBottom: "2vh",
                }}
              >
                <ProjectIcon
                  src={vehicle_icon}
                  style={{
                    margin: "auto",
                    marginLeft: "1vw",
                    height: "3vh",
                    width: "auto",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "15vh",
                    width: "80%",
                    alignItems: "left",
                    justifyContent: "left",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      marginLeft: "0vw",
                    }}
                  >
                    <SectionSubtitle
                      align={"left"}
                      subtitle_text={<strong>Name:</strong>}
                    />
                    <SectionSubtitle align={"left"} subtitle_text={name} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      marginLeft: "0vw",
                    }}
                  >
                    <SectionSubtitle
                      align={"left"}
                      subtitle_text={<strong>Make:</strong>}
                    />
                    <SectionSubtitle align={"left"} subtitle_text={make} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      marginLeft: "0vw",
                    }}
                  >
                    <SectionSubtitle
                      align={"left"}
                      subtitle_text={<strong>Model:</strong>}
                    />
                    <SectionSubtitle align={"left"} subtitle_text={model} />
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

// PROJECT TOTAL SEQUENCES CELL//
export const ProjectSequencesCell = (props) => {
  return (
    <TableCell align="left" component="th" scope="row">
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.total_sequences}
      </Typography>
    </TableCell>
  );
};

// PROJECT DATE CREATED CELL //
export const ProjectDateCell = (props) => {
  return (
    <TableCell align="left" component="th" scope="row">
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.date_created}
      </Typography>
    </TableCell>
  );
};

// PROJECT DESCRIPTION CELL//
export const ProjectDescCell = (props) => {
  return (
    <TableCell align="left" component="th" scope="row">
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.description}
      </Typography>
    </TableCell>
  );
};
// PROJECT DESCRIPTION CELL//
export const ProjectCountryCell = (props) => {
  return (
    <TableCell align="left" component="th" scope="row">
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.country}
      </Typography>
    </TableCell>
  );
};

export const ProjectCityCell = (props) => {
  return (
    <TableCell align="left" component="th" scope="row">
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.city}
      </Typography>
    </TableCell>
  );
};

export const ProjectDeviceCell = (props) => {
  return (
    <TableCell align="left" component="th" scope="row">
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.device}
      </Typography>
    </TableCell>
  );
};

export const ProjectDayCell = (props) => {
  return (
    <TableCell align="left" component="th" scope="row">
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.day_of_travel}
      </Typography>
    </TableCell>
  );
};

// PROJECT NAME CELL //
export const ProjectNameCell = (props) => {
  return (
    <TableCell align="left" component="th" scope="row">
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.label}
      </Typography>
    </TableCell>
  );
};

// PROJECT ID CELL //
export const ProjectIdCell = (props) => {
  return (
    <TableCell
      align="left"
      component="th"
      scope="row"
      onClick={() => props.click_action()}
    >
      <Typography variant="subtitle2" noWrap style={{ textAlign: "center" }}>
        {props.value}
      </Typography>
    </TableCell>
  );
};

// IMAGE THUMBNAIL CELL //
export const ImageThumbCell = (props) => {
  const [hover, setHover] = useState(false);
  const handleSetHover = () => {
    setHover(!hover);
  };
  return (
    <TableCell
      align="center"
      sx={{
        "&:hover": {
          border: "2px solid #fffff",
          boxSizing: "border-box",
          cursor: "pointer",
          backgroundColor: "#f4753c",
          color: "white",
          textAlign: "left",
          paddingLeft: "0",
        },
      }}
      key={uuidv4()}
      component="th"
      scope="row"
      // padding="4vw"
    >
      <img
        style={hover ? { width: "120%" } : { width: "60%" }}
        src={props.thumb}
        key={uuidv4()}
        alt={uuidv4()}
        onMouseEnter={handleSetHover}
        onMouseLeave={handleSetHover}
      />
    </TableCell>
  );
};

// CHECKBOX CELL //
export const CheckboxCell = (props) => {
  const handleCheck = (e) => {
    props.handleSetSelected(
      e,
      props.id,
      props.project_id ? props.project_id : null,
      props.sequence_id ? props.sequence_id : null,
      props.index ? props.index : null
    );
  };
  return (
    <TableCell
      align="left"
      sx={{
        "&:hover": {
          border: "2px solid #fffff",
          boxSizing: "border-box",
          cursor: "pointer",
          backgroundColor: "#f4753c",
          color: "white",
          textAlign: "left",
          paddingLeft: "0",
        },
      }}
      component="th"
      scope="row"
      // padding="1vw"
    >
      <Checkbox
        checked={props.id === props.selected}
        onChange={(e) => handleCheck(e)}
      />
    </TableCell>
  );
};

// STYLED COMPONENTS
export const ProjectRow = styled(TableRow)(() => ({
  // backgroundColor:'lightgray',
  width: "100vw",
  marginLeft: "2vw",
}));

//TABLE HEADERS
export const TRIPS_TABLE_HEAD = [
  { id: "name", label: <strong>Name</strong>, alignLeft: true },
  { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "country", label: <strong>Country</strong>, alignLeft: true },
  { id: "city", label: <strong>City</strong>, alignLeft: true },
  { id: "vehicles", label: <strong>Vehicles</strong>, alignLeft: true },
  { id: "teams", label: <strong>Teams</strong>, alignLeft: true },
  { id: "created", label: <strong>Date Added</strong>, alignLeft: true },
];
export const UPLOADING_TABLE_HEAD = [
  { id: "name", label: <strong>Name</strong>, alignLeft: true },
  { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "country", label: <strong>Country</strong>, alignLeft: true },
  { id: "city", label: <strong>City</strong>, alignLeft: true },
  { id: "vehicles", label: <strong>Vehicles</strong>, alignLeft: true },
  { id: "upload", label: <strong>Upload Status</strong>, alignLeft: true },
  { id: "created", label: <strong>Date Added</strong>, alignLeft: true },
];

//TABLE HEADERS
export const ARCHIVE_TABLE_HEAD = [
  { id: "name", label: <strong>Name</strong>, alignLeft: true },
  { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "country", label: <strong>Country</strong>, alignLeft: true },
  { id: "city", label: <strong>City</strong>, alignLeft: true },
  { id: "vehicles", label: <strong>Vehicles</strong>, alignLeft: true },
  { id: "created", label: <strong>Date Added</strong>, alignLeft: true },
];

export const SELECT_TRIPS_TABLE_HEAD = [
  { id: "name", label: <strong>Name</strong>, alignLeft: true },
  { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "status", label: <strong>Status</strong>, alignLeft: true },
  { id: "country", label: <strong>Country</strong>, alignLeft: true },
  { id: "city", label: <strong>City</strong>, alignLeft: true },
  { id: "vehicles", label: <strong>Vehicles</strong>, alignLeft: true },
  { id: "days", label: <strong>Travel days</strong>, alignLeft: true },
];

export const DAYS_TABLE_HEAD = [
  { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "vehicle", label: <strong>Vehicle</strong>, alignLeft: true },
  { id: "day number", label: <strong>Day</strong>, alignLeft: true },
  { id: "date taken", label: <strong>Date</strong>, alignLeft: true },
  { id: "region", label: <strong>City/Region</strong>, alignLeft: true },
];

export const VEHICLE_DAYS_TABLE_HEAD = [
  // { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "day number", label: <strong>Day</strong>, alignLeft: true },
  { id: "date taken", label: <strong>Date</strong>, alignLeft: true },
  { id: "region", label: <strong>City/Region</strong>, alignLeft: true },
];

export const PROJECTS_TABLE_HEAD = [
  // { id: "id", label: "Project ID", alignLeft: true },
  { id: "name", label: "Name", alignLeft: true },
  { id: "id", label: "ID", alignLeft: true },
  { id: "country", label: "Country", alignLeft: true },
  { id: "city", label: "City", alignLeft: true },
  // { id: "day", label: "Day", alignLeft: true },
  // { id: "device", label: "Device", alignLeft: true },
  { id: "time", label: "Date Created", alignLeft: true },
  { id: "sequences", label: "Travel days", alignLeft: true },
];

export const SEQUENCE_TABLE_HEAD = [
  { id: "Index", label: <strong>Index</strong>, alignLeft: true },
  { id: "ID", label: <strong>ID</strong>, alignLeft: true },
  { id: "images", label: <strong>Total Images</strong>, alignLeft: true },
];

export const IMAGE_TABLE_HEAD = [
  { id: "Index", label: <strong>Index</strong>, alignLeft: true },
  { id: "Timestamp", label: <strong>Total Images</strong>, alignLeft: true },
  { id: "Thumbnail", label: <strong>Thumbnail</strong>, alignLeft: true },
  { id: "blank", label: "", alignLeft: true },
];

export const NewVehicleModal = (props) => {
  const [name, setName] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
  }, [props.cameras]);

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const handleSetMake = (e) => {
    setMake(e.target.value);
  };
  const handleSetModel = (e) => {
    setModel(e.target.value);
  };

  const handleClearForm = () => {
    setName(null);
    setModel(null);
    setMake(null);
    setHighlight(false);
  };
  const handleCloseModal = () => {
    handleClearForm();
    props.modalHandler();
  };

  const handleSetVehicles = () => {
    if (name) {
      props.handleSetVehicles(name, make, model);
      handleClearForm();
    } else {
      setHighlight(true);
    }
  };
  return (
    <>
      <>
        <Modal open={props.modalState} key="add">
          <ModalWrapper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "auto",
                marginTop: "1vh",
                marginBottom: "1vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SectionTitle title_text={<strong>{"Add Vehicle"}</strong>} />
              <SectionSubtitle
                subtitle_text={<strong>Vehicle Name (required)</strong>}
              />

              <TextFieldComponent
                value={name}
                setter={handleSetName}
                placeholder="Car 1"
                isMissing={highlight}
              />

              <SectionSubtitle
                subtitle_text={<strong>Vehicle Make (optional)</strong>}
              />

              <TextFieldComponent
                value={make}
                setter={handleSetMake}
                placeholder="Ford"
              />

              <SectionSubtitle
                subtitle_text={<strong>Vehicle Model (optional)</strong>}
              />

              <TextFieldComponent
                value={model}
                setter={handleSetModel}
                placeholder="Exploder"
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "auto",
                marginTop: "1vh",
                marginBottom: "1vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CancelButton
                cancel_action={() => handleCloseModal()}
                cancel_text={"Cancel"}
              />
              <ConfirmButton
                confirm_action={() => handleSetVehicles()}
                confirm_text={"Add"}
              />
            </div>
            {/* </div> */}
          </ModalWrapper>
        </Modal>
      </>
    </>
  );
};


export const EditCameraModal = (props) => {
  const[newCameraFacing, setNewCameraFacing]=useState()
  const [newCameraMake,setNewCameraMake]=useState()
  const [newCameraModel,setNewCameraModel]=useState()

  const {
    editCamera,
    addCamera
  } = useContext(DataContext);

  useEffect(() => {
    if(props.function==='edit'){
        setNewCameraFacing(props.selectedCameraFacing)
        setNewCameraMake(props.selectedCameraMake)
        setNewCameraModel(props.selectedCameraModel)
    }
    // eslint-disable-next-line
  }, [props]);

  const handleSetNewCameraFacing=(e)=>{
    setNewCameraFacing(e.target.value)
  }

  const handleSetNewCameraMake=(e)=>{
    setNewCameraMake(e.target.value)
  }

  const handleSetNewCameraModel=(e)=>{
    setNewCameraModel(e.target.value)
  }

  const handleEditCamera=()=>{
    if(props.function==='Edit'){
      editCamera(props.selectedCamera,newCameraMake,newCameraModel)
    }
    else{
      addCamera(newCameraMake,newCameraModel,newCameraFacing,props.selectedDay)
    }
    props.modalHandler()
  }




  return(
    <>
        <Modal open={props.modalState} key="add">
          <ModalWrapper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "auto",
                marginTop: "1vh",
                marginBottom: "1vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SectionTitle
                title_text={
                  <strong>{`${props.function} Camera`}</strong>
                }
              />
                  {props.function === 'Add'?
                  <>
                                    <SectionSubtitle
                    subtitle_text={<strong>Camera Facing</strong>}
                  />

                  <select
                    style={{
                      width:'40vw'
                    }}
                    value={newCameraFacing}
                    onChange={(e)=>handleSetNewCameraFacing(e)}
                  >
                    <option>
                    </option>
                    <option
                      value={'front'}
                    >
                      Front
                    </option>
                    <option
                      value={'left'}
                    >
                      Left
                    </option>
                    <option
                      value={'right'}
                    >
                      Right
                    </option>
                    <option
                      value={'360'}
                    >
                      360
                    </option>
                  </select>
                  </>
                  :
                  null
                    
                  }


                  <SectionSubtitle
                    subtitle_text={<strong>Camera Make (optional)</strong>}
                  />

                  <TextFieldComponent
                    setter={handleSetNewCameraMake}
                    placeholder="GoPro"
                    value={newCameraMake}
                  />

                  <SectionSubtitle
                    subtitle_text={<strong>Camera Model (optional)</strong>}
                  />

                  <TextFieldComponent
                    value={newCameraModel}
                    setter={handleSetNewCameraModel}
                    placeholder="Hero 10"
                  />
                              <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "auto",
                marginTop: "1vh",
                marginBottom: "1vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CancelButton
                cancel_action={() => props.modalHandler()}
                cancel_text={"Cancel"}
              />
              <ConfirmButton
                confirm_action={(e) => handleEditCamera(e)}
                confirm_text={props.function==='Edit'?'Update':'Add'}
              />
            </div>
            </div>
            </ModalWrapper>
          </Modal>    
    </>

  )
}



export const EditVehicleModal = (props) => {
  const [name, setName] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [confirmButtonText, setConfirmButtonText] = useState(null);
  const [page, setPage] = useState(1);
  const [allCameras, toggleAllCameras] = useToggle(false);
  const [tempCameraArray, setTempCameraArray] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [daysTraveled, setDaysTraveled] = useState(null);
  const isCameraSelected = (e) => tempCameraArray.includes(e);

  const { addVehicle, editVehicle } = useContext(DataContext);
  useEffect(() => {
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    setConfirmButtonText("Next");
    if (props.cameras && props.cameras.length > 0) {
      setTempCameraArray(props.cameras);
    }
    if (props.startDate) {
      let tempStart = new Date(props.startDate);
      tempStart = tempStart.toISOString().split("T")[0];
      setStartDate(tempStart);
    }
    if (props.endDate) {
      let tempEnd = new Date(props.endDate);
      tempEnd = tempEnd.toISOString().split("T")[0];
      setEndDate(tempEnd);
    }
    if (props.name) {
      setName(props.name);
    }
    if (props.make) {
      setMake(props.make);
    }
    if (props.model) {
      setModel(props.model);
    }
    // eslint-disable-next-line
  }, [props.cameras]);

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const handleSetMake = (e) => {
    setMake(e.target.value);
  };
  const handleSetModel = (e) => {
    setModel(e.target.value);
  };

  useEffect(() => {
    let check_array = ["Front", "Left", "Right", "360"];
    if (areAllElementsInArray(check_array, tempCameraArray)) {
      toggleAllCameras(true);
    } else {
      toggleAllCameras(false);
    }
    // eslint-disable-next-line
  }, [tempCameraArray]);

  function areAllElementsInArray(testArray, stateArray) {
    for (let i = 0; i < testArray.length; i++) {
      if (!stateArray.includes(testArray[i])) {
        return false;
      }
    }
    return true;
  }

  const handleSetConfirmButtonText = (e) => {
    setConfirmButtonText(e);
  };

  const handleClearForm = () => {
    setName(null);
    setModel(null);
    setMake(null);
  };

  const handleCloseModal = () => {
    handleClearForm();
    setPage(1);
    props.modalHandler();
  };

  const handleSetPage = () => {
    if (page === 1) {
      setPage(2);
    }
    if (page === 2) {
      setPage(3);
    }
    if (page === 3) {
      setPage(4);
      setConfirmButtonText("Submit");
    }
    if (page === 4) {
      if (props.modalType === "add") {
        handleAddVehicle();
      } else {
        handleEditVehicle();
      }
    }
  };

  const handleSetStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleSetEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleSetCameras = (facing) => {
    if (facing === "all") {
      if (allCameras) {
        toggleAllCameras(false);
        setTempCameraArray([]);
      } else {
        toggleAllCameras(true);
        setTempCameraArray(["Front", "Left", "Right", "360"]);
      }
    } else {
      if (!tempCameraArray.includes(facing)) {
        setTempCameraArray([...tempCameraArray, facing]);
      } else {
        setTempCameraArray(tempCameraArray.filter((i) => i !== facing));
      }
    }
  };

  function formatDate(datetime) {
    const date = new Date(datetime);
    const timezoneOffset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);
    const month = adjustedDate.getMonth(); // Months are 0-indexed
    const day = adjustedDate.getDate() + 1;
    const year = adjustedDate.getFullYear() % 100; // Extract last two digits of the year
    return `${month}/${day}/${year}`;
  }

  const handleAddVehicle = () => {
    addVehicle(
      name,
      make,
      model,
      tempCameraArray,
      startDate,
      endDate,
      daysTraveled
    );
    props.modalHandler();
  };

  const handleEditVehicle = () => {
    editVehicle(
      props.selectedVehicle,
      name,
      make,
      model,
      tempCameraArray,
      startDate,
      endDate,
      daysTraveled
    );
    props.modalHandler();
  };

  return (
    <>
      <>
        <Modal open={props.modalState} key="add">
          <ModalWrapper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "auto",
                marginTop: "1vh",
                marginBottom: "1vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SectionTitle
                title_text={
                  <strong>{`${props.modalType} Vehicle`}</strong>
                }
              />
              {page === 1 ? (
                <>
                  <SectionSubtitle
                    subtitle_text={<strong>Vehicle Name</strong>}
                  />

                  <TextFieldComponent
                    value={name}
                    setter={handleSetName}
                    placeholder="Car 1"
                  />

                  <SectionSubtitle
                    subtitle_text={<strong>Vehicle Make (optional)</strong>}
                  />

                  <TextFieldComponent
                    setter={handleSetMake}
                    placeholder="Ford"
                    value={make}
                  />

                  <SectionSubtitle
                    subtitle_text={<strong>Vehicle Model (optional)</strong>}
                  />

                  <TextFieldComponent
                    value={model}
                    setter={handleSetModel}
                    placeholder="Explorer"
                  />
                </>
              ) : page === 2 ? (
                <>
                  <SectionTitle
                    title_text={<strong>{`Edit Cameras for ${name}`}</strong>}
                  />

                  <SectionSubtitle subtitle_text={<strong>Facing:</strong>} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "3vw",
                      marginTop: "0vw",
                      marginBottom: "2vh",
                      height: "4vh",
                      width: "45vw",
                      alignItems: "top",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"Front"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="front"
                        checked={isCameraSelected("Front")}
                        onChange={(e) => handleSetCameras("Front")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"Left"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="left"
                        checked={isCameraSelected("Left")}
                        onChange={(e) => handleSetCameras("Left")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"Right"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="right"
                        checked={isCameraSelected("Right")}
                        onChange={(e) => handleSetCameras("Right")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"360"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="360"
                        checked={isCameraSelected("360")}
                        onChange={(e) => handleSetCameras("360")}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "4vh",
                        marginRight: "2vw",
                      }}
                    >
                      <SectionTitle title_text={"All"} marginTop={"1vh"} />
                      <input
                        type="checkbox"
                        value="All"
                        checked={allCameras}
                        onChange={(e) => handleSetCameras("all")}
                      />
                    </div>
                  </div>
                </>
              ) : page === 3 ? (
                <>
                  <SectionTitle
                    title_text={
                      <strong>{`Edit days of travel for ${props.name}`}</strong>
                    }
                  />
                  <SectionSubtitle
                    subtitle_text={<strong>{"Start Date"}</strong>}
                  />
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => handleSetStartDate(e)}
                  />
                  <SectionSubtitle
                    subtitle_text={<strong>{"End Date"}</strong>}
                  />
                  <input
                    type="date"
                    value={endDate}
                    style={{
                      marginBottom: "2vh",
                    }}
                    onChange={(e) => handleSetEndDate(e)}
                  />
                  <SectionSubtitle
                    style={{ marginBottom: "1vh" }}
                    // subtitle_text={
                    //   <strong>{`Total Days of Travel: ${daysTraveled}`}</strong>
                    // }
                  />
                </>
              ) : page === 4 ? (
                <>
                  <SectionTitle
                    title_text={
                      <strong>Review Details and Update Vehicle</strong>
                    }
                  />
                  <ProjectIcon
                    src={vehicle_icon}
                    key={uuidv4()}
                    style={{
                      margin: "auto",
                      marginLeft: "1vw",
                      height: "3vh",
                      width: "auto",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "20vh",
                      width: "85%",
                      alignItems: "left",
                      justifyContent: "left",
                      margin: "auto",
                    }}
                    key={uuidv4()}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        marginLeft: "0vw",
                      }}
                      key={uuidv4()}
                    >
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Vehicle:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={name}
                        key={uuidv4()}
                      />
                      <span style={{ width: "2vw" }} />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Make:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={make}
                        key={uuidv4()}
                      />

                      <span style={{ width: "2vw" }} />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Model:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={model}
                        key={uuidv4()}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                      }}
                      key={uuidv4()}
                    >
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Total Days:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={daysTraveled}
                        key={uuidv4()}
                      />
                      <span style={{ width: "2vw" }} />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Dates:</strong>}
                        key={uuidv4()}
                      />
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={`${formatDate(startDate)}-${formatDate(
                          endDate
                        )}`}
                        key={uuidv4()}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                      }}
                      key={uuidv4()}
                    >
                      <SectionSubtitle
                        align={"left"}
                        subtitle_text={<strong>Cameras:</strong>}
                        key={uuidv4()}
                      />
                      {tempCameraArray &&
                        tempCameraArray.slice().map((camera) => {
                          return (
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={camera}
                              key={uuidv4()}
                            />
                          );
                        })}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "auto",
                marginTop: "1vh",
                marginBottom: "1vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CancelButton
                cancel_action={() => handleCloseModal()}
                cancel_text={"Cancel"}
              />
              <ConfirmButton
                confirm_action={() => handleSetPage()}
                confirm_text={confirmButtonText}
              />
            </div>
          </ModalWrapper>
        </Modal>
      </>
    </>
  );
};
