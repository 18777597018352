import React, { useEffect, useContext, useState } from "react";
import { DataContext } from "../../../common/DataContext";
import useToggle from "../../../hooks/useToggle.js";
import { Table, TableBody } from "@mui/material";

import {
  ProjectSequencesCell,
  ProjectDateCell,
  ProjectDescCell,
  ProjectNameCell,
} from "./tripComponents";

import {
  AssignTeamModal,
  UnassignTeamModal,
  ModifyTeamModal,
  TEAM_TABLE_HEAD,
} from "../TeamHub/teamComponents";

import {
  TableCard,
  ConfirmButton,
  CancelButton,
  ListHead,
  TableEntryRow,
  TableEntryCell,
} from "../../commonComponents/commonComponents";

export const ProjectTeamsTable = (props) => {
  const {
    fetchProjectTeams,
    projectTeams,
    setProjectTeams,
    unassignedTeams,
    modifyTeamDetails,
    assignTeam,
    unAssignTeam,
    teamSelected,
    setTeamSelected,
  } = useContext(DataContext);

  //COMPONENT STATES AND SETTERS //

  const [form, setForm] = useState({ name: "", desc: "" });
  const [selectedTeamName, setSelectedTeamName] = useState(null);
  const [unassignSelected, setUnassignSelected] = useState(null);
  const [assignSelected, setAssignSelected] = useState(null);
  const [assignOpen, toggleAssignOpen] = useToggle(false);
  const [modifyOpen, toggleModifyOpen] = useToggle(false);
  const [unassignOpen, toggleUnassignOpen] = useToggle(false);

  useEffect(() => {
    if (props.assign === true) {
      fetchProjectTeams("unassigned");
    } else {
      fetchProjectTeams("assigned");
    }

    // eslint-disable-next-line
  }, []);

  // TOGGLES ASSIGN TEAM MODAL OPEN IF TEAM SELECTED, SET ASSIGN & UNASSIGN SELECTED STATES TO NULL FOR NEW SELECTION //
  const handleAssignOpen = () => {
    toggleAssignOpen();
  };

  const handleSetTeamSelected = (id, name) => {
    setTeamSelected(id);
    setSelectedTeamName(name);
  };

  // TOGGLES ASSIGN TEAM MODAL OPEN IF TEAM SELECTED, SET ASSIGN & UNASSIGN SELECTED STATES TO NULL FOR NEW SELECTION //
  const handleUnassignOpen = () => {
    if (teamSelected !== null) {
      toggleUnassignOpen();
    }
  };

  const handleModifyOpen = () => {
    if (teamSelected !== null) {
      toggleModifyOpen();
    }
  };

  // const do_modify_team_details = () => {
  //   modifyTeamDetails(teamSelected, form.name, form.desc, props.project_id);
  //   fetchProjectTeams();
  //   handleModifyOpen();
  // };

  // SENDS API CALL THROUGH DATA CONTEXT TO ASSIGN TEAM TO PROJECT IN DB, FETCHES NEW LIST OF ASSIGNED PROJECTS, CLOSES ASSIGN TEAM MODAL AND SETS SELECTED ASSIGNABLE PROJECT STATE TO NULL //
  const handle_assign_team = () => {
    assignTeam(props.project_id);
    handleAssignOpen();
  };

  // SENDS API CALL THROUGH DATA CONTEXT TO UN-ASSIGN TEAM FROM PROJECT IN DB, FETCHES NEW LIST OF ASSIGNED PROJECTS, CLOSES ASSIGN TEAM MODAL AND SETS SELECTED UN-ASSIGNABLE PROJECT STATE TO NULL //
  const do_unassign_team = () => {
    if (teamSelected !== null) {
      unAssignTeam(teamSelected, props.project_id);
      fetchProjectTeams("assigned");
      handleUnassignOpen();
    }
  };

  return (
    <>
      <div
        style={{ marginLeft: "2vw", marginRight: "2vw", marginBottom: "2vh" }}
      >
        {/* <ModifyTeamModal
            form={form}
            modifyOpen={modifyOpen}
            selectedTeamName={selectedTeamName}
            teamSelected={teamSelected}
            handleModifyOpen={handleModifyOpen}
            do_modify_team_details={do_modify_team_details}
            setForm={setForm}
          /> */}
        <UnassignTeamModal
          projectName={props.projectName}
          unassignOpen={unassignOpen}
          teamSelected={teamSelected}
          teamName={selectedTeamName}
          unassignSelected={unassignSelected}
          assignSelected={assignSelected}
          handleUnassignOpen={handleUnassignOpen}
          do_unassign_team={do_unassign_team}
          setUnassignSelected={setUnassignSelected}
          setAssignSelected={setAssignSelected}
        />
        <AssignTeamModal
          projectName={props.projectName}
          handleAssignOpen={handleAssignOpen}
          assignOpen={assignOpen}
          project_selected={props.project_id}
          teamSelected={teamSelected}
          handle_assign_team={handle_assign_team}
        />
        <TableCard style={{ boxShadow: "1px 1px 6px 2px gray" }}>
          <Table>
            <div
              style={{
                height: "30vh",
                width: "78.75vw",
                overflowY: "scroll",
                overflowX: "scroll",
              }}
            >
              <ListHead
                headLabel={TEAM_TABLE_HEAD}
                tableData={projectTeams}
                updateData={setProjectTeams}
                vw={"7vw"}
              />
              <TableBody>
                {props.assign === false ? (
                  <>
                    {projectTeams &&
                      projectTeams.slice().map((row) => {
                        const {
                          id,
                          name,
                          description,
                          date_created,
                          total_team_members,
                          total_projects,
                        } = row;
                        return (
                          <TableEntryRow
                            align="center"
                            hover
                            key={id}
                            tabIndex={-1}
                            onClick={() => handleSetTeamSelected(id, name)}
                            selected={teamSelected === id}
                          >
                            <TableEntryCell entry={name} />
                            <TableEntryCell entry={description} />
                            <TableEntryCell entry={date_created} />
                            <TableEntryCell entry={total_projects} />
                            <TableEntryCell entry={total_team_members} />
                          </TableEntryRow>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {unassignedTeams &&
                      unassignedTeams.slice().map((row) => {
                        const {
                          id,
                          name,
                          description,
                          date_created,
                          total_team_members,
                          total_projects,
                        } = row;
                        return (
                          <TableEntryRow
                            align="center"
                            hover
                            key={id}
                            tabIndex={-1}
                            onClick={() => handleSetTeamSelected(id, name)}
                            selected={teamSelected === id}
                          >
                            <TableEntryCell entry={name} />
                            <TableEntryCell entry={description} />
                            <TableEntryCell entry={date_created} />
                            <TableEntryCell entry={total_projects} />
                            <TableEntryCell entry={total_team_members} />
                          </TableEntryRow>
                        );
                      })}
                  </>
                )}
              </TableBody>
            </div>
          </Table>
        </TableCard>
      </div>
      {!props.assign ? (
        <ProjectTeamsButtons
          add_action={handleAssignOpen}
          modify_action={toggleModifyOpen}
          close_action={props.close_action}
          unassign_action={handleUnassignOpen}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export const ProjectTeamsButtons = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center,",
          justifyContent: "center",
          marginBottom: "1vh",
        }}
      >
        <CancelButton
          cancel_action={props.close_action}
          cancel_text={"Cancel"}
        />
        <ConfirmButton confirm_action={props.add_action} confirm_text={"Add"} />
        <ConfirmButton
          confirm_action={props.unassign_action}
          confirm_text={"Remove"}
        />
        {/* <ConfirmButton
          confirm_action={props.modify_action}
          confirm_text={"Edit"}
        /> */}
      </div>
    </>
  );
};
