import React, { useEffect, useContext, useState } from "react";
import useToggle from "../../../hooks/useToggle.js";
import { DataContext } from "../../../common/DataContext/index.js";
import { Table, TableBody } from "@mui/material";
import { Modal, Divider } from "@mui/material";
import { USER_TABLE_HEAD } from "../../../components/AdminUsersPage/userComponents.js";
import {
  TableEntryRow,
  TableEntryCell,
  CardMediaStyle,
} from "../../commonComponents/commonComponents.js";
import {
  TableCard,
  ConfirmButton,
  CancelButton,
  ListHead,
  CloseButton,
  SectionTitle,
  SectionSubtitle,
  ModalWrapper,
  ButtonDiv,
} from "../../commonComponents/commonComponents.js";

export const TeamMembersTable = (props) => {
  const [deleteOpen, toggleDeleteOpen] = useToggle(false);
  const [assignOpen, toggleAssignOpen] = useToggle(false);
  const [userSelectedName, setUserSelectedName] = useState(null);

  const {
    assignUser,
    unassignUser,
    teamMembers,
    setTeamMembers,
    userSelected,
    setUserSelected,
    fetchTeamMembers,
    unassignedTeamMembers,
    fetchUnassignedMembers,
  } = useContext(DataContext);

  // FETCHES ALL ORG USERS ON COMPONENT MOUNT //
  useEffect(() => {
    if (props.members === "unassigned") {
      fetchUnassignedMembers(props.team_id);
    } else {
      fetchTeamMembers(props.team_id);
    }
    // eslint-disable-next-line
  }, []);

  // SETS SELECTED USER TO STATE WHEN CHECKED, SETS SELECTED USER STATE TO NULL ON UNCHECK //
  const handleSetUserSelected = (id, name) => {
    setUserSelected(id);
    setUserSelectedName(name);
  };

  // TOGGLE DELETE USER MODAL OPEN IF USER SELECTED //
  const handleDeleteOpen = () => {
    if (userSelected !== null) {
      toggleDeleteOpen();
    }
  };

  const handleUnassignUser = () => {
    unassignUser(userSelected, props.team_id);
    fetchTeamMembers(props.team_id);
    toggleDeleteOpen();
  };

  // TOGGLES ASSIGN USER MODAL OPEN IF USER SELECTED, SETS ASSIGNED AND UNASSIGNED TEAM STATES TO NULL FOR NEW SELECTIONS //
  const handleAssignOpen = () => {
    toggleAssignOpen();
  };

  // MAKES API CALL THROUGH DATA CONTEXT TO ASSIGN SELECTED USER TO SELECTED TEAM IN DB, FETCHES NEW LIST OF ASSIGNED TEAMS, CLOSES ASSIGN USER MODAL AND SETS SELECTED ASSIGNABLE TEAM STATE TO NULL //
  const handle_assign_user = () => {
    assignUser(userSelected, props.team_id);
    toggleAssignOpen();
  };

  return (
    <>
      <div
        style={{ marginLeft: "2vw", marginRight: "2vw", marginBottom: "2vh" }}
      >
        <RemoveTeamMemberModal
          unassign_action={handleUnassignUser}
          deleteOpen={deleteOpen}
          team_id={props.team_id}
          userSelectedName={userSelectedName}
          teamSelectedName={props.teamSelectedName}
          user_selected={userSelected}
          handleDeleteOpen={handleDeleteOpen}
        />
        <AssignTeamMemberModal
          assignOpen={assignOpen}
          handleAssignOpen={handleAssignOpen}
          team_id={props.team_id}
          teamSelectedName={props.teamSelectedName}
          handle_assign_user={handle_assign_user}
        />
        <TableCard
          style={{
            boxShadow: "1px 1px 6px 2px gray",
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          <CardMediaStyle />
          <Table>
            <ListHead
              headLabel={USER_TABLE_HEAD}
              tableData={teamMembers}
              updateData={setTeamMembers}
            />
            <TableBody style={{ width: "100vw" }}>
              {props.members === "assigned" ? (
                <>
                  {teamMembers &&
                    teamMembers.slice().map((row) => {
                      const { value, label, role, joined } = row;
                      return (
                        <TableEntryRow
                          align="center"
                          hover
                          key={value}
                          tabIndex={-1}
                          onClick={() => handleSetUserSelected(value, label)}
                          selected={userSelected === value}
                        >
                          <TableEntryCell entry={label} />
                          <TableEntryCell entry={role} />
                          <TableEntryCell entry={joined} />
                        </TableEntryRow>
                      );
                    })}
                </>
              ) : (
                <>
                  {unassignedTeamMembers &&
                    unassignedTeamMembers.slice().map((row) => {
                      const { id, label, role, joined } = row;
                      return (
                        <TableEntryRow
                          align="center"
                          hover
                          key={row}
                          tabIndex={-1}
                          onClick={() => handleSetUserSelected(id)}
                          selected={userSelected === id}
                        >
                          <TableEntryCell entry={label} />
                          <TableEntryCell entry={role} />
                          <TableEntryCell entry={joined} />
                        </TableEntryRow>
                      );
                    })}
                </>
              )}
            </TableBody>
          </Table>
        </TableCard>
      </div>
      {props.buttons !== false ? (
        <TeamMemberButtons
          unassign_action={handleDeleteOpen}
          close_action={props.close_action}
          assign_action={handleAssignOpen}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export const TeamMemberButtons = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center,",
          justifyContent: "center",
          marginBottom: "1vh",
        }}
      >
        <CancelButton
          cancel_action={props.close_action}
          cancel_text={"Cancel"}
        />
        <ConfirmButton
          confirm_action={props.assign_action}
          confirm_text={"Add"}
        />
        <ConfirmButton
          confirm_action={props.unassign_action}
          confirm_text={"Remove"}
        />
      </div>
    </>
  );
};

// DELETE USER MODAL //
export const RemoveTeamMemberModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.deleteOpen) {
        props.handleDeleteOpen(); // Call the function to close the modal
      }
    }
  };

  return (
    <Modal open={props.deleteOpen} key="add" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton action={props.handleDeleteOpen} />
        <SectionTitle title_text={<strong>Remove user from team</strong>} />
        <SectionSubtitle
          subtitle_text={
            <strong>
              Are you sure you want to remove - {props.userSelectedName} - from{" "}
              {props.teamSelectedName}
            </strong>
          }
        />
        <Divider />
        <div style={{ marginBottom: "1vh" }}>
          <RemoveTeamMemberButtons
            form={props.form}
            unassign_action={props.unassign_action}
            handleDeleteOpen={props.handleDeleteOpen}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};

// DELETE USER BUTTONS //
export const RemoveTeamMemberButtons = (props) => {
  return (
    <ButtonDiv>
      <CancelButton
        cancel_action={props.handleDeleteOpen}
        cancel_text={"Cancel"}
      />
      <ConfirmButton
        confirm_text={"Remove"}
        confirm_action={props.unassign_action}
      />
    </ButtonDiv>
  );
};

// ASSIGN USER MODAL //
export const AssignTeamMemberModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.assignOpen) {
        props.handleAssignOpen(); // Call the function to close the modal
      }
    }
  };

  return (
    <Modal open={props.assignOpen} key="assign" onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <CloseButton close_action={props.handleAssignOpen} />
        <SectionTitle
          title_text={
            <strong>
              Assign the selected users to Team - {props.teamSelectedName}
            </strong>
          }
        />
        <TeamMembersTable
          buttons={false}
          members={"unassigned"}
          team_id={props.team_id}
        />
        <div style={{ marginBottom: "1vh" }}>
          <AssignTeamMemberButtons
            close_action={props.handleAssignOpen}
            handle_assign_user={props.handle_assign_user}
            do_unassign_user={props.do_unassign_user}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};

// ASSIGN USER BUTTONS //
export const AssignTeamMemberButtons = (props) => {
  return (
    <ButtonDiv>
      <CancelButton cancel_text={"Cancel"} cancel_action={props.close_action} />
      <ConfirmButton
        confirm_text={"Assign"}
        confirm_action={props.handle_assign_user}
      />
    </ButtonDiv>
  );
};
