// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: white !important;
  font-family: "Open Sans", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.goog-logo-link,
.goog-te-gadget span {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0;
}

.goog-te-banner-frame {
  display: none !important;
}

/* .mod-footer .footer-info-left .social-networks ul li {
  display: inline-block;
  vertical-align: middle;
}

.mod-footer .footer-info-left .social-networks ul li + li {
  margin-left: 10px;
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;;EAEE,wBAAwB;AAC1B;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;;;;;;;GAOG","sourcesContent":["body {\n  background: white !important;\n  font-family: \"Open Sans\", sans-serif;\n}\n\n#root {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100vh;\n}\n\n.goog-logo-link,\n.goog-te-gadget span {\n  display: none !important;\n}\n\n.goog-te-gadget {\n  color: transparent !important;\n  font-size: 0;\n}\n\n.goog-te-banner-frame {\n  display: none !important;\n}\n\n/* .mod-footer .footer-info-left .social-networks ul li {\n  display: inline-block;\n  vertical-align: middle;\n}\n\n.mod-footer .footer-info-left .social-networks ul li + li {\n  margin-left: 10px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
