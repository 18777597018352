import React, {useContext, useEffect, useRef} from "react";
import { DataContext } from "../../common/DataContext";
import { Source, Layer } from "react-map-gl";
import { paint_style_line } from "../../components/constants";
import vehicle_icon from "../../images/carIcon.png";
import camera_icon from "../../images/cameraIcon.png";
import day_icon from "../../images/day_icon.png";
import searchButton from "../../images/search_icon.png";
import polygonButton from "../../images/polygon_button.png";
import { ProjectIcon } from "../../components/AdminUploadPage/styles";
import type {LineLayer} from 'react-map-gl';
import { Modal } from "@mui/material";
import {
  CancelButton,
  CloseButton,
  ConfirmButton,
  SectionTitle,
  SectionSubtitle,
  TableCard,
  ModalWrapper,
} from "../commonComponents/commonComponents";

const LineColorLayer = (id, sourceLayer, color) => ({
    id: id,
    type: "line",
    'source-layer': sourceLayer,
    layout: {"line-cap": "round"},
    paint: {
        "line-color": color,
        "line-width": 8,
        "line-translate-anchor": "map",
    }
});

const LineOutlineLayer = (id, sourceLayer) => ({
    id: id,
    type: "line",
    'source-layer': sourceLayer,
    layout: {"line-cap": "round"},
    paint: {
        "line-color": "#000",
        "line-width": 8,
        "line-translate-anchor": "map",
    },
});

const LineArrowLayer = (id, sourceLayer) => ({
    id: id,
    type: "line",
    'source-layer': sourceLayer,
    layout:{ "line-cap": "round" },
    paint:{
      "line-pattern": "pointicon",
      "line-width": 6,
      "line-translate-anchor": "map",},
    filter: ["all", ["<", 13, ["zoom"]]],
});

const PointLayer = (id, sourceLayer, color) => ({
        id: id,
        type:"circle",
        'source-layer': sourceLayer,
        // filter: [
        //   "all", // Combine multiple filter expressions using 'all'
        //   [">=", ["get", "timestamp"], filterStartDate], // Filter by start date
        //   ["<=", ["get", "timestamp"], filterEndDate], // Filter by end date
        //   ["in", ["get", "facing"], ["literal", unfilteredViews]],
        // ],
        paint: {
          "circle-color": color,
          "circle-stroke-color": "#000000", // Outline color (default black)
          "circle-stroke-width": 0.3, // Outline width (in pixels)
        }
});

const IconLayer = (id, sourceLayer, color) => ({
    id: id,
    type: "symbol",
    'source-layer': sourceLayer,
    layout: {
        "icon-image": [
          "match",
          ["get", "facing"],
          "360",
          "threesixtyCone",
          "viewCone",
        ],
        "icon-size": [
          "match",
          ["get", "facing"],
          "360",
          1,
          1.5,
        ],
        "icon-offset": [
          "match",
          ["get", "facing"],
          "360",
          ["literal", [0, 0]],
          ["literal", [0, -5]]
        ],
        "icon-rotation-alignment": "map",
        "icon-anchor": "center",
        "icon-rotate": ["to-number", ["get", "heading"]],
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
    },
    paint: {
        "icon-opacity": [
          "match",
          ["get", "facing"],
          "360",
          0.5,
          1.0,
        ],
        "icon-halo-width": [
          "match",
          ["get", "facing"],
          "360",
          0.0,
          5.0,
        ],
        "icon-halo-color": "rgba(50,50,50,50)",
        "icon-color": color
    },
})

export const VectorTileLayerComponent = (props) => {
    const { filterStartDate, filterEndDate, unfilteredViews, currentFeature, currentYaw } =
    useContext(DataContext);


    // Front Line/Point Styles
    const frontLineColorLayer = LineColorLayer('front-line-color', 'Front', '#FFFFFF')
    const frontOutlineLayer = LineOutlineLayer('front-line-outline', 'Front')
    const frontArrowLayer = LineArrowLayer('front-arrow-line', 'Front')
    const frontPointColorLayer = PointLayer('front-point-color', 'Front', '#FFFFFF')
    const frontIconLayer = IconLayer("front-icon", 'Front', '#FFFFFF')

    // Left Line/Point Styles
    const leftLineColorLayer = LineColorLayer('left-line-color', 'Left', '#FF0000')
    const leftOutlineLayer = LineOutlineLayer('left-line-outline', 'Left')
    const leftArrowLayer = LineArrowLayer('left-arrow-line', 'Left')
    const leftPointColorLayer = PointLayer('left-point-color', 'Left', '#FF0000')
    const leftIconLayer = IconLayer("left-icon", 'Left', '#FF0000')

    // Right Line/Point Styles
    const rightLineColorLayer = LineColorLayer('right-line-color', 'Right', '#00FF00')
    const rightOutlineLayer = LineOutlineLayer('right-line-outline', 'Right')
    const rightArrowLayer = LineArrowLayer('right-arrow-line', 'Right')
    const rightPointColorLayer = PointLayer('right-point-color', 'Right', '#00FF00')
    const rightIconLayer = IconLayer("right-icon", 'Right', '#00FF00')

    // 360 Line/Point Styles
    const threesixtyLineColorLayer = LineColorLayer('threesixty-line-color', '360', '#9900FF')
    const threesixtyOutlineLayer = LineOutlineLayer('threesixty-line-outline', '360')
    const threesixtyArrowLayer = LineArrowLayer('threesixty-arrow-line', '360')
    const threesixtyPointColorLayer = PointLayer('threesixty-point-color', '360', '#9900FF')
    const threesixtyIconLayer = IconLayer("threesixty-icon", '360', '#9900FF')

  return (
    <Source
      id={"devinmarkley.quandary"}
      type="vector"
      url={props.url}
    >
        <Layer {...frontOutlineLayer} />
        <Layer {...frontLineColorLayer}/>
        <Layer {...frontArrowLayer} />
        <Layer {...frontPointColorLayer} />

        <Layer {...leftOutlineLayer} />
        <Layer {...leftLineColorLayer}/>
        <Layer {...leftArrowLayer} />
        <Layer {...leftPointColorLayer} />

        <Layer {...rightOutlineLayer} />
        <Layer {...rightLineColorLayer}/>
        <Layer {...rightArrowLayer} />
        <Layer {...rightPointColorLayer} />

        <Layer {...threesixtyOutlineLayer} />
        <Layer {...threesixtyLineColorLayer}/>
        <Layer {...threesixtyArrowLayer} />
        <Layer {...threesixtyPointColorLayer} />

        {currentFeature && (
              <>
                <Source
                  id={"devinmarkley.quandary"}
                  type="vector"
                  url={props.url}
                >
                <Layer {...frontIconLayer} filter={ ["==", ["get", "img"], currentFeature.properties.img]}/>
                <Layer {...leftIconLayer} filter={ ["==", ["get", "img"], currentFeature.properties.img]}/>
                <Layer {...rightIconLayer} filter={ ["==", ["get", "img"], currentFeature.properties.img]}/>
                <Layer {...threesixtyIconLayer} filter={ ["==", ["get", "img"], currentFeature.properties.img]}/>
                <Layer
                  id="360_cone_layer_geojson"
                  source="devinmarkley.quandary"
                  source-layer="360"
                  type="symbol"
                  layout={{
                    "icon-image": "viewCone360",
                    "icon-size": 1,
                    "icon-rotation-alignment": "map",
                    "icon-anchor": "center",
                    "icon-offset": [0, -19],
                    "icon-rotate": ["to-number", ["get", "heading"]],
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true,
                  }}
                  paint={{
                    "icon-halo-width": 5,
                    "icon-halo-color": "rgba(50,100,50,50)",
                    "icon-color": "#B200ED",
                  }}
                  filter={["==", ["get", "img"], currentFeature.properties.img]}
                />
                </Source>
              </>
            )}
    </Source>
  );
};

export const FilterMenuModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.filterMenuOpen) {
        props.handleToggleFilterMenu(); // Call the function to close the modal
      }
    }
  };

  const {
    tripVehiclesFilters,
    tripCamerasFilters,
    tripDaysFilters,
    fetchTripInfo,
    filterParameters,
    setFilterParameters,
  } = useContext(DataContext);

  useEffect(() => {
    fetchTripInfo();
    // eslint-disable-next-line
  }, []);

  const handleSetFilterParameters = (value) => {
    const isValuePresent = filterParameters.includes(value);
    if (isValuePresent) {
      const updatedParameters = filterParameters.filter(
        (param) => param !== value,
      );
      setFilterParameters(updatedParameters);
    } else {
      const updatedParameters = [...filterParameters, value];
      setFilterParameters(updatedParameters);
    }
  };

  return (
    <>
      <Modal open={props.filterMenuOpen} key="filter" onKeyDown={handleKeyDown}>
        <ModalWrapper>
          <CloseButton close_action={props.handleToggleFilterMenu} />
          <SectionTitle title_text={<strong>Filter Map Contents</strong>} />
          <SectionSubtitle
            subtitle_text={
              "Filter out map content by Vehicle, Camera or Day of Travel"
            }
          />

          <TableCard
            style={{
              boxShadow: "1px 1px 6px 2px gray",
              margin: "auto",
              height: "50vh",
              width: "90%",
              marginTop: "3vh",
              marginBottom: "2vh",
            }}
          >
            <div
              style={{
                backgroundColor: "lightyellow",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <SectionSubtitle subtitle_text={<strong>Vehicle</strong>} />
                {tripVehiclesFilters &&
                  tripVehiclesFilters.slice().map((row) => {
                    const { id, name, travel_days, cameras } = row;
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "1vh",
                          height: "8vh",
                          width: "13vw",
                          boxShadow: "1px 1px 6px 2px gray",
                          marginBottom: "2vh",
                        }}
                      >
                        <ProjectIcon
                          src={vehicle_icon}
                          style={{
                            margin: "auto",
                            marginTop: "1vh",
                            marginLeft: "1vw",
                            height: "3vh",
                            width: "auto",
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            width: "12vw",
                            flexDirection: "row",
                            justifyContent: "left",
                            marginLeft: "0vw",
                          }}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Vehicle:</strong>}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={name}
                            marginLeft={"0vw"}
                            paddingRight={"0vw"}
                          />
                          <input
                            type="checkbox"
                            value={id}
                            style={{ marginLeft: "2vw" }}
                            onChange={(e) => handleSetFilterParameters(id)}
                            checked={filterParameters.includes(id)}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <SectionSubtitle subtitle_text={<strong>Camera</strong>} />

                {tripCamerasFilters &&
                  tripCamerasFilters.slice().map((row) => {
                    const { id, facing, travel_days, cameras } = row;
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "1vh",
                          height: "8vh",
                          width: "13vw",
                          boxShadow: "1px 1px 6px 2px gray",
                          marginBottom: "2vh",
                        }}
                      >
                        <ProjectIcon
                          src={camera_icon}
                          style={{
                            margin: "auto",
                            marginTop: "1vh",
                            marginLeft: "1vw",
                            height: "3vh",
                            width: "auto",
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            width: "12vw",
                            flexDirection: "row",
                            justifyContent: "left",
                            marginLeft: "0vw",
                          }}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Camera:</strong>}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={facing}
                            marginLeft={"0vw"}
                            paddingRight={"0vw"}
                          />
                          <input
                            type="checkbox"
                            value={facing}
                            style={{ marginLeft: "2vw" }}
                            onChange={(e) => handleSetFilterParameters(facing)}
                            checked={filterParameters.includes(facing)}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <SectionSubtitle
                  subtitle_text={<strong>Day of Travel</strong>}
                />
                {tripDaysFilters &&
                  tripDaysFilters.slice().map((row) => {
                    const { id, name, day_of_travel, cameras } = row;
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "1vh",
                          height: "8vh",
                          width: "13vw",
                          boxShadow: "1px 1px 6px 2px gray",
                          marginBottom: "2vh",
                        }}
                      >
                        <ProjectIcon
                          src={day_icon}
                          style={{
                            margin: "auto",
                            marginTop: "1vh",
                            marginLeft: "1vw",
                            height: "3vh",
                            width: "auto",
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            width: "12vw",
                            flexDirection: "row",
                            justifyContent: "left",
                            marginLeft: "0vw",
                          }}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Travel Day:</strong>}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={day_of_travel}
                            marginLeft={"0vw"}
                            paddingRight={"0vw"}
                          />
                          <input
                            type="checkbox"
                            value={day_of_travel}
                            style={{ marginLeft: "1vw" }}
                            onChange={(e) =>
                              handleSetFilterParameters(day_of_travel)
                            }
                            checked={filterParameters.includes(day_of_travel)}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </TableCard>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CancelButton cancel_text={"Cancel"} />
            <ConfirmButton confirm_text={"confim"} />
          </div>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export const SearchButton = (props) => {
  return (
    <>
      <button
        style={{
          width: "50%",
          marginBottom: "0vh",
          borderRadius: "6px",
          padding: "auto",
          fontSize: 12,
        }}
        onClick={props.action}
      >
        {props.text}
      </button>
    </>
  );
};

export const SearchField = (props) => {
  return (
    <>
      <input
        value={props.text}
        placeholder={props.placeholder}
        style={{ width: "95%", marginBottom: "1vh" }}
        type={"text"}
        onChange={(e) => {
          props.setter(e.target.value);
        }}
      />
    </>
  );
};

export const SearchResultListItem = (result) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginBottom: "1vh" }}>
      <label>{result.name}</label>
    </div>
  );
};

export const SearchResultList = (props) => {
  return (
    <div style={{ marginTop: "0", height: "25vh", overflow: "scroll" }}>
      {props.searchResults.map((row) => {
        const { index, name, coordinates } = row;
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "1vh",
            }}
          >
            <input
              key={index}
              type={"radio"}
              value={index}
              checked={props.resultSelected === index}
              onChange={() => props.handleSetResultSelected(index)}
            />
            <label>{name}</label>
          </div>
        );
      })}
    </div>
  );
};

export const SearchWidget = (props) => {
  return (
    <div
      style={{
        width: "10vw",
        height: "8vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10vh",
        backgroundColor: "lightblue",
      }}
    >
      {props.searchOpen ? (
        <div class="searchGroup">
          <h5>Search locations by any or all of the fields below</h5>
          <SearchField
            text={props.address}
            setter={props.handleSetAddress}
            placeholder={"Address & Street Name"}
          />
          <SearchField
            text={props.city}
            setter={props.handleSetCity}
            placeholder={"City"}
          />
          <SearchField
            text={props.postcode}
            setter={props.handleSetPostcode}
            placeholder={"Postcode"}
          />
          <SearchField
            text={props.state}
            setter={props.handleSetState}
            placeholder={"State"}
          />
          <SearchField
            text={props.country}
            setter={props.handleSetCountry}
            placeholder={"Country"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10vh",
            }}
          >
            <SearchButton
              text={"Close"}
              action={props.handleSetSearchOpen}
              style={{ borderRadius: "2px", padding: "2px auto" }}
            />
            <span style={{ width: "1vw" }} />
            <SearchButton text={"Clear"} action={props.handleClearSearch} />
            <span style={{ width: "1vw" }} />
            <SearchButton text={"Search"} action={props.action} />
            <span style={{ width: "1vw" }} />
            <SearchButton text={"View"} action={props.handleViewResult} />
          </div>
          {props.resultsOpen ? (
            <>
              <SearchResultList
                searchResults={props.searchResults}
                handleSetResultsOpen={props.handleSetResultsOpen}
                resultSelected={props.resultSelected}
                handleSetResultSelected={props.handleSetResultSelected}
                handleClearSearch={props.handleClearSearch}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          <img
            style={{
              width: "40px",
            }}
            src={searchButton}
            onClick={props.handleSetSearchOpen}
          />
        </>
      )}
    </div>
  );
};
