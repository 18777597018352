import React, { useContext } from "react";
import { TooltipStyled } from "../Viewer/styles";
import { DataContext } from "../../common/DataContext";
import {
  DirectionBar,
  LeftCamera,
  ThreeSixtyCamera,
  FrontCamera,
  RightCamera,
} from "./styles";

export const DirectionPicker = (props) => {
  const { handleSetSelectedView, selectedView } = useContext(DataContext);
  return (
    <DirectionBar>
      <LeftCamera
        style={
          selectedView === "Left"
            ? { backgroundColor: "#FF0000", borderRadius: "45px" }
            : { backgroundColor: "unset", borderRadius: "0px" }
        }
        name="view_direction"
        value="LeftCamera"
        data-tip
        data-for="leftCamera"
        onClick={() => handleSetSelectedView("Left")}
        setSelectedView={"Left"}
      >
        <TooltipStyled
          id="leftCamera"
          effect="solid"
          delayShow={800}
          place="top"
          type="dark"
        >
          <p>Left</p>
        </TooltipStyled>
      </LeftCamera>
      <FrontCamera
        style={
          selectedView === "Front"
            ? { backgroundColor: "#FFFFFF", borderRadius: "45px" }
            : { backgroundColor: "unset", borderRadius: "0px" }
        }
        value="Front"
        data-tip
        data-for="frontCamera"
        onClick={() => handleSetSelectedView("Front")}
        selectedView={selectedView}
      >
        <TooltipStyled
          id="frontCamera"
          effect="solid"
          delayShow={800}
          place="top"
          type="dark"
        >
          <p>Front</p>
        </TooltipStyled>
      </FrontCamera>
      <RightCamera
        style={
          selectedView === "Right"
            ? { backgroundColor: "#00FF00", borderRadius: "45px" }
            : { backgroundColor: "unset", borderRadius: "0px" }
        }
        data-tip
        value="RightCamera"
        data-for="rightCamera"
        onClick={() => handleSetSelectedView("Right")}
        selectedView={selectedView}
      >
        <TooltipStyled
          id="rightCamera"
          effect="solid"
          delayShow={800}
          place="top"
          type="dark"
        >
          <p>Right</p>
        </TooltipStyled>
      </RightCamera>
      <ThreeSixtyCamera
        style={
          selectedView === "360"
            ? { backgroundColor: "#e920e9", borderRadius: "45px" }
            : { backgroundColor: "unset", borderRadius: "0px" }
        }
        value="360°"
        data-tip
        data-for="360Camera"
        onClick={() => handleSetSelectedView("360")}
        selectedView={selectedView}
      >
        <TooltipStyled
          id="360Camera"
          effect="solid"
          delayShow={800}
          place="top"
          type="dark"
        >
          <p>360°</p>
        </TooltipStyled>
      </ThreeSixtyCamera>
    </DirectionBar>
  );
};
