import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { DataContext } from "../../common/DataContext";
import close_icon from "../../images/close_icon.png";
import { Button, ButtonLabel, Container, Input } from "./styles";
import { v4 as uuidv4 } from "uuid";
import vehicle_icon from "../../images/carIcon.png";
import camera_icon from "../../images/cameraIcon.png";
import day_icon from "../../images/day_icon.png";
import { ProjectIcon } from "../AdminUploadPage/styles";
import {
  Card,
  Divider,
  Modal,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

// STYLED COMPONENTS

const spinnerStyle = {
  position: "fixed",
  left: "45%",
  top: "45%",
  width: "15vw",
  height: "15vh",
  margin: "auto",
  padding: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  zIndex: 9999,
  backgroundColor: "black",
};
const circleStyle = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  border: "4px solid #f3f3f3",
  borderTop: "4px solid #3498db",
  animation: `spin ${3}s linear infinite`,
  marginBottom: "10px",
  zIndex: 999,
};

export const LoadingSpinner = () => {
  const { isLoading } = useContext(DataContext);
  return (
    <>
      {isLoading ? (
        <div style={spinnerStyle}>
          <div style={circleStyle}></div>
          <h4 style={{ color: "orange" }}>Loading...</h4>
        </div>
      ) : null}
    </>
  );
};

export const TopDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "2vh",
}));

export const CardMediaStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  paddingTop: "3vh",
  "&:before": {
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
    // backdropFilter: "blur(3px)",
    // WebkitBackdropFilter: "blur(3px)", // Fix on Mobile
    backgroundColor: "#f4753c",
    fontWeight: "400",
  },
}));

export const TableCard = styled(Card)(() => ({
  width: "100%",
  marginLeft: "0vw",
  boxShadow: "0 0 4px gray",
  overflowY: "scroll",
}));

export const MainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export const ModalWrapper = styled("div")(() => ({
  position: "fixed",
  top: "50%",
  left: "55%",
  backgroundColor: "white",
  // backdropFilter: "blur(3px)",
  // WebkitBackdropFilter: "blur(3px)",
  borderRadius: "6px",
  width: "50%",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
  transform: "translate(-50%, -50%)",
}));

export const ButtonDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  textAlign: "center",
  justifyContent: "center",
}));

export const TableEntryRow = styled(TableRow)(() => ({
  width: "100vw",
  height: "1vh",
  marginLeft: "2vw",
  paddingTop: "1vh",
  paddingBottom: "1vh",
}));

// COMPONENTS

export const DashboardCard = (props) => {
  return (
    <>
      <Card
        style={{
          boxShadow: "1px 1px 6px 2px gray",
          position: "relative",
          top: "2vh",
          // marginLeft: props.marginLeft,
          // marginRight: props.marginRight,
          marginBottom: "1vh",
          minWidth: props.minWidth,
          height: "40vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardMediaStyle />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <SectionTitle title_text={props.title} bold={true} />
        </div>
        <Divider />
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: "4vh" }}
        >
          <SectionTitle title_text={props.subtitle_text_1} bold={true} />
          <SectionTitle title_text={props.value_1} />
        </div>
        <Divider />
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: "4vh" }}
        >
          <SectionTitle title_text={props.subtitle_text_2} bold={true} />
          <SectionTitle title_text={props.value_2} />
        </div>
        <Divider />
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: "4vh" }}
        >
          <SectionTitle title_text={props.subtitle_text_3} bold={true} />
          <SectionTitle title_text={props.value_3} />
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        ></div>
      </Card>
    </>
  );
};

//GENERIC CONFIRM & CANCEL BUTTONS - USED ON MOST VIEWER MODALS - CHANGE TEXT AND ACTION PROP FOR EACH BUTTON
export const CancelConfirmButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <CancelButton
        cancel_text={props.cancel_text}
        cancel_action={props.cancel_action}
      />
      <ConfirmButton
        confirm_text={props.confirm_text}
        confirm_action={props.confirm_action}
      />
    </div>
  );
};

// GENERAL CANCEL BUTTON //
export const CancelButton = (props) => {
  return (
    <Button
      onClick={props.cancel_action}
      style={{
        boxShadow: "1px 1px 6px 2px gray",
        textAlign: "center",
        lineHeight: "2.0em",
      }}
    >
      {props.cancel_text}
    </Button>
  );
};

// GENERAL CONFIRM BUTTON //
export const ConfirmButton = (props) => {
  return (
    <Button
      onClick={(e) => props.confirm_action(e)}
      style={{
        boxShadow: "1px 1px 6px 2px gray",
        textAlign: "center",
        lineHeight: "2.0em",
      }}
    >
      {props.confirm_text}
    </Button>
  );
};

// GENERAL SECTION TITLE
export const SectionTitle = (props) => {
  return (
    <Typography
      variant="h5"
      align="center"
      style={{
        paddingLeft: props.paddingLeft ? props.paddingLeft : "1vw",
        paddingRight: props.paddingRight ? props.paddingRight : "1vw",
        marginBottom: "1vh",
        marginTop: props.marginTop ? props.marginTop : "2vh",
      }}
      sx={{ mt: 6 }}
    >
      {props.title_text}
    </Typography>
  );
};

// GENERAL SECTION SUBTITLE
export const SectionSubtitle = (props) => {
  return (
    <Typography
      variant="body1"
      align={props.align ? props.align : "center"}
      style={{
        width: "auto",
        paddingLeft: props.marginLeft ? props.marginLeft : "1vw",
        paddingRight: "1vw",
        marginBottom: "1vh",
        marginRight: props.marginRight ? props.marginRight : "0vw",
        marginTop: "1vh",
        ...props.style,
      }}
      sx={{ mt: 6 }}
    >
      {props.subtitle_text}
    </Typography>
  );
};

export const TableEntryCell = (props) => {
  return (
    <TableCell
      style={{ height: "2vh" }}
      align="center"
      component="th"
      scope="row"
      onClick={
        props.click_action && props.click_action
          ? () => props.click_action()
          : null
      }
    >
      <Typography variant="subtitle2" noWrap style={{ textAlign: "left" }}>
        {props.entry}
      </Typography>
    </TableCell>
  );
};

// GENERAL CLOSE MODAL BUTTON
export const CloseButton = (props) => {
  return (
    <img
      src={close_icon}
      style={{
        position: "relative",
        marginTop: "2vh",
        left: "95%",
        width: "2%",
      }}
      alt={"close_button"}
      onClick={props.close_action}
    ></img>
  );
};

// TITLE DIV
export const TitleDiv = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-end",
        marginBottom: "2vh",
        justifyContent: "flex-start",
      }}
    >
      <h3>{props.text}</h3>
    </div>
  );
};

//GENERAL STYLED BUTTON
export const StyledButton = (props) => {
  return (
    <Button
      onClick={props.button_action}
      style={{
        boxShadow: "1px 1px 6px 2px gray",
        textAlign: "center",
        lineHeight: "2.0em",
      }}
    >
      <ButtonLabel>{props.button_text}</ButtonLabel>
    </Button>
  );
};

//BUTTON DIV COMPONENT
export const ButtonDivComponent = (props) => {
  return (
    <Container>
      {props.button1 ? (
        <StyledButton
          button_action={props.button1_action}
          button_text={props.button1_text}
        />
      ) : (
        <></>
      )}
      {props.button2 ? (
        <StyledButton
          button_action={props.button2_action}
          button_text={props.button2_text}
        />
      ) : (
        <></>
      )}
      {props.button3 ? (
        <StyledButton
          button_action={props.button3_action}
          button_text={props.button3_text}
        />
      ) : (
        <></>
      )}
      {props.button4 ? (
        <StyledButton
          button_action={props.button4_action}
          button_text={props.button4_text}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

//TABLE HEADER COMPNENT
export const ListHead = ({ headLabel, tableData, updateData }) => {
  const [sort, setSort] = useState({ column: "", order: "asc" });
  const [isSorting, setIsSorting] = useState(false);
  const handleSort = (column) => {
    setSort((prevSort) => {
      let order;
      if (prevSort.column === column) {
        order = prevSort.order === "asc" ? "desc" : "asc";
      } else {
        order = "asc";
      }
      return { column, order };
    });
  };

  useEffect(() => {
    if (isSorting) {
      const sortData = () => {
        if (sort.column === "") {
          return;
        }
        const newData = [...tableData].sort((a, b) => {
          const valueA = a[sort.column];
          const valueB = b[sort.column];

          if (typeof valueA === "number" && typeof valueB === "number") {
            return sort.order === "asc" ? valueA - valueB : valueB - valueA;
          } else {
            const stringA = String(valueA).toUpperCase();
            const stringB = String(valueB).toUpperCase();
            if (stringA < stringB) return sort.order === "asc" ? -1 : 1;
            if (stringA > stringB) return sort.order === "asc" ? 1 : -1;
            return 0;
          }
        });
        updateData(newData);
      };
      sortData();
      setIsSorting(false);
    }
  }, [isSorting, sort, tableData, updateData]);

  useEffect(() => {
    setIsSorting(true);
  }, [sort]);

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell key={headCell.id}>
            <TableSortLabel
              active={sort.column === headCell.id}
              direction={sort.column === headCell.id ? sort.order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const ProjectHead = (props) => {
  return (
    <TableHead>
      <TableRow style={{ margin: "0", textAlign: "center" }}>
        {props.headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{
              width: "9vw",
              textAlign: "center",
              fontSize: props.fontSize,
            }}
          >
            <TableSortLabel hideSortIcon direction={"asc"}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const ConfirmModalCommon = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (props.modal_open) {
        props.handleOpenCloseModal();
      }
    }
  };
  const modal_body = (
    <ModalWrapper>
      <Card>
        <Typography
          variant="h5"
          align="center"
          style={{ marginLeft: "1vw", marginRight: "1vw", marginTop: "2vh" }}
        >
          {props.interrogative}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          style={{ marginLeft: "1vw", marginRight: "1vw", marginTop: "2vh" }}
        >
          {props.informative}
        </Typography>
        <Divider style={{ marginTop: "1vh" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1vh",
            marginBottom: "1vh",
          }}
        >
          <ConfirmButton
            style={{ marginLeft: "1vw", marginRight: "1vw" }}
            confirm_action={props.button_1_action}
            confirm_text={props.button_1_text}
          ></ConfirmButton>
          <ConfirmButton
            style={{ marginLeft: "1vw", marginRight: "1vw" }}
            confirm_action={props.button_2_action}
            confirm_text={props.button_2_text}
          ></ConfirmButton>
        </div>
      </Card>
    </ModalWrapper>
  );
  //COMPONENT RENDER
  return (
    <Modal
      open={props.modal_open}
      onClose={props.handleOpenCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onKeyDown={handleKeyDown}
    >
      {modal_body}
    </Modal>
  );
};

export const ExportGpxModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.exportModalOpen) {
        props.handleExportModalOpen(); // Call the function to close the modal
      }
    }
  };
  return (
    <Modal open={props.exportModalOpen} key="notes" onKeyDown={handleKeyDown}>
      <div>
        <ModalWrapper>
          <CloseButton close_action={props.handleExportModalOpen} />
          <SectionTitle title_text={props.title_text} />
          <Divider />
          {props.modalPage === 1 ? (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle subtitle_text={props.page_1_text} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "2vh",
                }}
              >
                <SectionSubtitle subtitle_text={"Selected Sequence:"} />
                <input
                  type="radio"
                  value="Sequence"
                  name="Sequence"
                  onChange={(e) => props.handleSetExportSelection(e)}
                  checked={
                    props.exportSelection !== null
                      ? props.exportSelection === "Sequence"
                      : false
                  }
                />
                <span style={{ width: "5vw" }} />
                <SectionSubtitle subtitle_text={"Entire Project:"} />
                <input
                  type="radio"
                  value="Project"
                  name="Project"
                  onChange={(e) => props.handleSetExportSelection(e)}
                  checked={
                    props.exportSelection !== null
                      ? props.exportSelection === "Project"
                      : false
                  }
                  style={{ marginRight: "6.5vw" }}
                />
              </div>
            </>
          ) : props.modalPage === 2 ? (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle subtitle_text={props.page_2_text} />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "2vh",
                }}
              >
                <SectionSubtitle subtitle_text={"Front:"} />
                <input
                  type="radio"
                  value="Front"
                  name="Front"
                  onChange={(e) => props.handleSetExportView(e)}
                  checked={props.exportView && props.exportView === "Front"}
                />
                <span style={{ width: "5vw" }} />
                <SectionSubtitle subtitle_text={"Left:"} />
                <input
                  type="radio"
                  value="Left"
                  name="Left"
                  onChange={(e) => props.handleSetExportView(e)}
                  checked={props.exportView && props.exportView === "Left"}
                />
                <span style={{ width: "5vw" }} />
                <SectionSubtitle subtitle_text={"Right:"} />
                <input
                  type="radio"
                  value="Right"
                  name="Right"
                  onChange={(e) => props.handleSetExportView(e)}
                  checked={props.exportView && props.exportView === "Right"}
                />
                <span style={{ width: "5vw" }} />
                <SectionSubtitle subtitle_text={"360:"} />
                <input
                  type="radio"
                  value="360"
                  name="360"
                  onChange={(e) => props.handleSetExportView(e)}
                  checked={props.exportView && props.exportView === "360"}
                />
              </div>
            </>
          ) : props.modalPage === 3 ? (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle subtitle_text={"Confirm GPX Export Details"} />
              </div>
              <Divider />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle
                  subtitle_text={`Export Selection: ${props.exportSelection}`}
                />
                <SectionSubtitle
                  subtitle_text={`Export View Direction: ${
                    props.exportSelection === "Sequence"
                      ? props.selectedView
                      : props.exportView
                  }`}
                />
              </div>
            </>
          ) : (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle subtitle_text={"GPX has been downloaded"} />
              </div>
            </>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              marginBottom: "2vh",
            }}
          >
            <Divider />
            <StyledButton
              button_action={
                props.modalPage === 1
                  ? () => props.handleSetModalPage(2)
                  : props.modalPage === 2
                    ? () => props.handleSetModalPage(3)
                    : props.modalPage === 3
                      ? () => props.handleSetModalPage(4)
                      : () => props.handleSetModalPage(5)
              }
              button_text={
                props.modalPage !== 3
                  ? "Next"
                  : props.modalPage === 3
                    ? "Confirm"
                    : props.modalPage === 4
                      ? "Close"
                      : ""
              }
            />
            {props.modalPage !== 4 ? (
              <>
                <StyledButton
                  button_action={props.handleExportModalOpen}
                  button_text={"Cancel"}
                />
              </>
            ) : null}
          </div>
        </ModalWrapper>
      </div>
    </Modal>
  );
};

export const DropdownMenu = ({
  options,
  onSelect,
  menu_title,
  processSubfolders = false,
  selectedFolder,
}) => {
  const [isOpen, setIsOpen] = useToggle(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleMenu = () => {
    setIsOpen();
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div>
      <button
        style={{
          padding: "8px 10px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          outline: "none",
          boxShadow: "1px 2px 5px gray",
          width: "16vw",
        }}
        onClick={toggleMenu}
      >
        {selectedOption ? selectedOption.label : menu_title}
      </button>
      {isOpen && (
        <div
          style={{
            backgroundColor: "ghostwhite",
            height: "16vh",
            overflowY: "scroll",
          }}
        >
          {processSubfolders === false ? (
            <>
              <ul>
                {options.map((option) => (
                  <>
                    {typeof option === "string" ? (
                      <>
                        <li
                          key={option}
                          onClick={() => handleOptionSelect(option)}
                        >
                          {option}
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          key={option.value}
                          onClick={() => handleOptionSelect(option)}
                        >
                          {option.label}
                        </li>
                      </>
                    )}
                  </>
                ))}
              </ul>
            </>
          ) : (
            <>{selectedFolder}</>
          )}
        </div>
      )}
    </div>
  );
};

export const SelectionRow = (props) => {
  const renderInputs = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // alignItems: "left",
        }}
      >
        <SectionTitle title_text={props.title} marginTop={"1vh"} />
        {props.inputs &&
          props.inputs.map((input, index) => (
            <>
              <SectionTitle title_text={input.title} marginTop={"1vh"} />

              {input.type === "radio" ? (
                <input
                  type="radio"
                  value={input.value}
                  checked={input.value === props.valueState}
                  onChange={(e) => props.stateHandler(e)}
                  disabled={input.isDisabled}
                />
              ) : input.type === "file" ? (
                <>
                  <input
                    style={{ width: "auto", marginTop: "1vh" }}
                    type="file"
                    multiple
                    accept=".jpg, .JPG, .jpeg, .JPEG"
                    onChange={
                      props.handleSetLocalImages && props.handleSetLocalImages
                        ? (e) => props.handleSetLocalImages(e)
                        : null
                    }
                  />
                  {/* <ConfirmButton
                    confirm_text={input.button_text}
                    confirm_action={() => input.fileRef.current.click()}
                  /> */}
                </>
              ) : input.type === "text" ? null : input.type === "button" ? (
                <ConfirmButton
                  confirm_text={input.button_text}
                  confirm_action={
                    input.stateHandler ? input.stateHandler : props.stateHandler
                  }
                />
              ) : input.type === "dropdown" ? (
                <>
                  <DropdownMenu
                    processSubfolders={props.processSubfolders}
                    selectedFolder={props.selectedFolder}
                    menu_title={input.menu_title}
                    options={input.options}
                    onSelect={props.stateHandler}
                  />
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  return <>{renderInputs()}</>;
};

export const Thumbnail = ({ src, alt, maxWidth, maxHeight }) => {
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  const handleImageLoad = () => {
    setThumbnailLoaded(true);
  };

  return (
    <div
      style={{
        position: "relative",
        width: maxWidth,
        height: maxHeight,
        overflow: "hidden",
        // backgroundColor:'green'
      }}
      key={uuidv4()}
    >
      {!thumbnailLoaded && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            marginTop: "0vh",
            padding: "0",
          }}
          key={uuidv4()}
        >
          Loading...
        </div>
      )}
      <img
        src={src}
        alt={alt}
        style={{
          display: thumbnailLoaded ? "block" : "none",
          maxWidth: thumbnailLoaded ? "100%" : "0",
          maxHeight: thumbnailLoaded ? "100%" : "0",
          width: thumbnailLoaded ? "auto" : "0",
          height: thumbnailLoaded ? "auto" : "0",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "0",
          objectFit: "cover",
          objectPosition: "center",
        }}
        key={uuidv4()}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default Thumbnail;

export const ImagePreviewModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (props.imagePreviewOpen) {
        props.toggleImagePreviewOpen();
      }
    }
  };
  return (
    <Modal open={props.imagePreviewOpen} key="notes" onKeyDown={handleKeyDown}>
      <ModalWrapper style={{ width: "70vw" }}>
        <CloseButton close_action={props.toggleImagePreviewOpen} />
        <SectionTitle title_text={"Image Preview:"} />
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={props.url}
            alt={props.alt}
            style={{
              height: "70vh",
              width: "auto",
              display: "block",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};

export const CameraUIcard = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2vh",
            height: "15vh",
            width: "60%",
            boxShadow: "1px 1px 6px 2px gray",
            marginBottom: "2vh",
          }}
        >
          <ProjectIcon
            src={camera_icon}
            style={{
              margin: "auto",
              marginLeft: "1vw",
              height: "3vh",
              width: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "15vh",
              width: "80%",
              alignItems: "left",
              justifyContent: "left",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginLeft: "0vw",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Camera Facing:</strong>}
              />
              <SectionSubtitle align={"left"} subtitle_text={props.facing} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Make:</strong>}
              />
              <SectionSubtitle
                align={"left"}
                subtitle_text={props.type ? props.type : "N/A"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Model:</strong>}
              />
              <SectionSubtitle
                align={"left"}
                subtitle_text={props.model ? props.model : "N/A"}
              />
            </div>
          </div>
          <input
            type="radio"
            value={props.id}
            style={{ marginRight: "3vw" }}
            onChange={(e) =>
              props.handleSetSelectedCamera(props.id, props.facing)
            }
            checked={props.selectedCamera === props.id}
          />
        </div>
      </div>
    </>
  );
};

export const VehicleUIcard = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2vh",
            height: "15vh",
            width: "60%",
            boxShadow: "1px 1px 6px 2px gray",
            marginBottom: "2vh",
          }}
        >
          <ProjectIcon
            src={vehicle_icon}
            style={{
              margin: "auto",
              marginLeft: "1vw",
              height: "3vh",
              width: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "15vh",
              width: "80%",
              alignItems: "left",
              justifyContent: "left",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginLeft: "0vw",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Vehicle:</strong>}
              />
              <SectionSubtitle align={"left"} subtitle_text={props.name} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Days of travel:</strong>}
              />
              <SectionSubtitle
                align={"left"}
                subtitle_text={props.travel_days.length / props.cameras.length}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Cameras:</strong>}
              />
              {props.cameras &&
                props.cameras.slice().map((camera) => {
                  const { facing } = camera;
                  return (
                    <SectionSubtitle align={"left"} subtitle_text={facing} />
                  );
                })}
            </div>
          </div>
          <input
            type="radio"
            value={props.id}
            style={{ marginRight: "3vw" }}
            onChange={(e) =>
              props.handleSetSelectedVehicle(props.id, props.name)
            }
            checked={props.selectedVehicle === props.id}
          />
        </div>
      </div>
    </>
  );
};

export const TravelDayUIcard = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2vh",
            height: "15vh",
            width: "60%",
            boxShadow: "1px 1px 6px 2px gray",
            marginBottom: "2vh",
          }}
        >
          <ProjectIcon
            src={day_icon}
            style={{
              margin: "auto",
              marginLeft: "1vw",
              height: "3vh",
              width: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "15vh",
              width: "80%",
              alignItems: "left",
              justifyContent: "left",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginLeft: "0vw",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Day of Travel:</strong>}
              />
              <SectionSubtitle
                align={"left"}
                subtitle_text={props.day_of_travel}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Date:</strong>}
              />
              <SectionSubtitle align={"left"} subtitle_text={props.date} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <SectionSubtitle
                align={"left"}
                subtitle_text={<strong>Facings:</strong>}
              />
              {props.cameras &&
                props.cameras.slice().map((camera) => {
                  const { facing } = camera;
                  return (
                    <SectionSubtitle align={"left"} subtitle_text={facing} />
                  );
                })}
            </div>
          </div>
          <input
            type="radio"
            value={props.id}
            style={{ marginRight: "3vw" }}
            onChange={(e) =>
              props.handleSetSelectedDay(
                props.id,
                props.day_of_travel,
                props.cloud_path,
              )
            }
            checked={props.selectedDay === props.id}
          />
        </div>
      </div>
    </>
  );
};

// TEXT FIELD //
export const TextFieldComponent = (props) => {
  return (
    <Input
      style={{
        marginLeft: "2.5vw",
        borderColor: props.isMissing ? "red" : undefined,
        borderWidth: props.isMissing ? "2px" : undefined,
        borderStyle: props.isMissing ? "solid" : undefined,
      }}
      type="text"
      placeholder={props.placeholder}
      name={props.name}
      value={props.value}
      onChange={(e) => {
        props.setter(e);
      }}
    />
  );
};
