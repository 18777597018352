import { Navigate, useNavigate } from "react-router-dom";
import { InteractionContext } from "../InteractionContext";
import { AuthContext } from "../AuthContext";
import { useQueryString } from "../useQueryString";
import { imageryType, MAPBOX_TOKEN } from "../../components/constants";
import { fetcher, poster } from "../../calls";
import useToggle from "../../hooks/useToggle";
import { saveAs } from "file-saver";
import EXIF from "exif-js";
import JSZip from "jszip";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import mapboxgl from "mapbox-gl";
import { find_next_feature } from "../../services/tile_service";
// CONTEXT IMPORTS //
export const DataContext = createContext({});
export const DataProvider = ({ children }) => {
  const { setSelectedPictureId, setViewport, currentZoom, setCurrentZoom } =
    useContext(InteractionContext);
  const [backupFolder, setBackupFolder] = useState(null);
  const { setUser, user } = useContext(AuthContext);
  const [currentImagery, setCurrentImagery] = useState(imageryType.STREETS);
  const [projectID, setProjectID] = useQueryString("project", "0");
  //BOOLEAN TOGGLES
  const [LayerMenuOpen, toggleLayerMenuOpen] = useToggle(false);
  const [followCheckboxClicked, toggleFollowCheckboxClicked] = useToggle(false);
  const [sidebarOpen, toggleSidebar] = useToggle(true);
  const [activeMenuItem, toggleActiveMenuItem] = useToggle(false);
  const [isLoading, toggleIsLoading] = useToggle(false);
  const [loadingTimeout, setLoadingTimeout] = useState(0);
  const [metaBlockOpen, toggleMetaBlockOpen] = useToggle(false);
  const [filterMenuOpen, toggleFilterMenuOpen] = useToggle(false);
  const [followInJosm, toggleFollowInJosm] = useToggle(false);
  const [toolMenuOpen, toggleToolMenuOpen] = useToggle(false);
  const [alertOpen, toggleAlertOpen] = useToggle(false);
  const [hasImagery, toggleHasImagery] = useToggle(false);
  const [cloudConnected, toggleCloudConnected] = useToggle(false);
  const [minimapOpen, setMinimapOpen] = useToggle(true);
  const [isMapSwap, setisMapSwap] = useToggle(false);
  const [drawPolyOpen, setDrawPolyOpen] = useToggle(false);
  const [machine_learning, setML] = useToggle(false);
  const [osm, setOsm] = useToggle(false);
  const [license, setLicense] = useToggle(false);
  const [detailsOpen, setDetailsOpen] = useToggle(false);
  const [fetching, setFetching] = useToggle(false);
  const [autoPlay, setAutoPlay] = useToggle(false);
  const [newtripSelected, setNewtripSelected] = useToggle(false);
  const [searchOpen, toggleSearchOpen] = useToggle(false);
  const [polygonOpen, togglePolygonOpen] = useToggle(false);
  const [addImagesOpen, toggleAddImagesOpen] = useToggle(false);
  const [heartbeat, toggleHeartbeat] = useToggle(false);

  //OBJECT STATES
  const [adminDashboardInfo, setAdminDashboardInfo] = useState({});
  const [selectedFeature, setSelectedFeature] = useState({});
  const [imageDetails, setImageDetails] = useState({});
  const [geoJson, setGeoJson] = useState({});
  const [displayGeoJson, setDisplayGeoJson] = useState({});
  const [centroidGeojson, setCentroidGeojson] = useState({});
  const [tempImageGeoJson, setTempImageGeoJson] = useState({
    type: "FeatureCollection",
    features: [],
  });
  //NUMERIC STATES
  const [rotateOffset, setRotateOffset] = useState(0);
  const [currentYaw, setCurrentYaw] = useState(0);
  const [selectedMapIndex, setSelectedMapIndex] = useState(0);
  const [imageBrightness, setImageBrightness] = useState(100);
  const [currentFeatureCollectionIndex, setcurrentFeatureCollectionIndex] =
    useState(0);
  const [imageryXoffset, setImageryXoffset] = useState(0);
  const [imageryYoffset, setImageryYoffset] = useState(0);
  const [currentImgCount, setCurrentImgCount] = useState(0);
  const [totalImgCount, setTotalImgCount] = useState(100);
  const [zoom, setZoom] = useState(1);
  //STRING STATES
  const [uploadMethod, setUploadMethod] = useState("");
  const [alertText, setAlertText] = useState("");
  const [connectingText, setConnectingText] = useState("Not Connected");
  //ARRAY STATES
  const [tempImageUrls, setTempImageUrls] = useState([]);
  const [urlsToFetch] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [newTrips, setNewTrips] = useState([]);
  const [readyTrips, setReadyTrips] = useState([]);
  const [uploadingTrips, setUploadingTrips] = useState([]);
  const [archivedTrips, setArchivedTrips] = useState([]);
  const [userTrips, setUserTrips] = useState([]);
  const [selectedTripDays, setSelectedTripDays] = useState([]);
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [unassignedProjects, setUnassignedProjects] = useState([]);
  const [sequenceImages, setSequenceImages] = useState([]);
  const [currentSelectedTripDays, setCurrentSelectedTripDays] = useState([]);
  const [daySequences, setDaySequences] = useState([]);
  const [vehicleCameras, setVehicleCameras] = useState([]);
  const [vehicleDays, setVehicleDays] = useState([]);
  const [layer_list, set_layer_list] = useState([]);
  const [unassignedGroups, setUnassignedGroups] = useState([]);
  const [assignedGroups, setAssignedGroups] = useState([]);
  const [layerIds, setLayerIds] = useState([]);
  const [otherTeams, setOtherTeams] = useState([]);
  const [orgTeams, setOrgTeams] = useState([]);
  const [orgSequences, setOrgSequences] = useState([]);
  const [orgImages, setOrgImages] = useState([]);
  const [editingTeams, setEditingTeams] = useState([]);
  const [driveTeams, setDriveTeams] = useState([]);
  const [driveTeam, setDriveTeam] = useState([]);
  const [assignedTeams, setAssignedTeams] = useState([]);
  const [unassignedTeams, setUnassignedTeams] = useState([]);
  const [orgGroups, setOrgGroups] = useState([]);
  const [projectGroups, setProjectGroups] = useState([]);
  const [similarProjects, setSimilarProjects] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [projectTeams, setProjectTeams] = useState([]);
  const [unassignedTeamMembers, setUnassignedTeamMembers] = useState([]);
  const [tile_url_list, set_tile_url_list] = useState([]);
  const [vectorSourceIds, setVectorSourceIds] = useState([]);
  const [cloudFolderContents, setCloudFolderContents] = useState([]);
  const [cloudFileContents, setCloudFileContents] = useState([]);
  const [projectDirectionSequences, setProjectDirectionSequences] = useState(
    [],
  );
  const [filterParameters, setFilterParameters] = useState([]);
  const [tripVehiclesFilters, setTripVehiclesFilters] = useState([]);
  const [tripCamerasFilters, setTripCamerasFilters] = useState([]);
  const [tripDaysFilters, setTripDaysFilters] = useState([]);
  const [tripVehicles, setTripVehicles] = useState([]);
  const [polyArray, setPolyArray] = useState([]);
  const [stringArray, setStringArray] = useState([]);
  const [pointArray, setPointArray] = useState([]);
  const [tempImages, setTempImages] = useState([]);
  //NULL STATES BY DEFAULT
  const [tempId, setTempId] = useState(null);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [stillsJson, setStillsJson] = useState(null);
  const [sequenceSelected, setSequenceSelected] = useState(null);
  const [lastZoomedPicture, setLastZoomedPicture] = useState(null);

  const [selectedTripCloudPath, setSelectedTripCloudPath] = useState(null);
  const [selectedVehicleName, setSelectedVehicleName] = useState(null);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [selectedSequenceIndex, setSelectedSequenceIndex] = useState(null);
  const [selectedSequenceId, setSelectedSequenceId] = useState(null);
  const [selectedSequenceLength, setSelectedSequenceLength] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedCameraFacing, setSelectedCameraFacing] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [dayOfTravel, setDayOfTravel] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [newNote, setNewNote] = useState(null);
  const [mapReload, setMapReload] = useState(false);
  const [sequenceLoaded, setSequenceLoaded] = useState(false);
  const [imageNotes, setImageNotes] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [tripSelected, setTripSelected] = useState(null);
  const [frontJson, setFrontJson] = useState(null);
  const [leftJson, setLeftJson] = useState(null);
  const [rightJson, setRightJson] = useState(null);
  const [GPXfile, setGPXfile] = useState(null);
  const [vectorFeatureIndex, setVectorFeatureIndex] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [bucket, setBucket] = useState(null);
  const [aws_access_key, setAccessToken] = useState(null);
  const [aws_secret_key, setSecretKey] = useState(null);
  const [path, setBasePath] = useState(null);
  const [teamSelected, setTeamSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [userSelectedName, setUserSelectedName] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [TILE_URL, setTILE_URL] = useState(null);
  const [selectedPictureDirection, setSelectedPictureDirection] =
    useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [projectSelected, setProjectSelected] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [displayBoundary, setDisplayBoundary] = useState(null);
  const [area, setArea] = useState(null);
  const [centroid, setCentroid] = useState(null);
  const [drawMode, setDrawMode] = useState(false);
  const [firstFeature, setfirstFeature] = useState(null);
  const [ThreesixtyLoading, toggleThreeSixtyLoading] = useToggle(false);

  const [selectedGeojsonType, setSelectedGejsonType] = useState("fixed");
  const [selectedImageNotes, setSelectedImageNotes] = useState({});

  const [tripStartDate, setTripStartDate] = useState();
  const [tripEndDate, setTripEndDate] = useState();
  const [selectionType, setSelectionType] = useState("Image");
  const [sequenceFilename, setSequenceFilename] = useState(null);
  const [sequenceDate, setSequenceDate] = useState(null);
  const [flyToCoords, setFlyToCoords] = useState();
  const [deleteOriginals, toggleDeleteOriginals] = useToggle(false);

  const [fools, toggleFools] = useToggle(false);
  const [publicTrip, setPublicTrip] = useState(null);

  //REFS
  const mapRef = useRef();
  const pannellumRef = useRef();
  const prismaZoom = useRef();
  const history = useNavigate();

  const navigate = useNavigate();

  const [currentFeature, setCurrentFeature] = useState(null);
  const [selectedFeatureIcon, setSelectedFeatureIcon] = useState("viewCone");
  const [filterStartDate, setFilterStartDate] = useState("2000-01-24");
  const [filterEndDate, setFilterEndDate] = useState("2100-01-24");
  const [unfilteredViews, setUnfilteredViews] = useState([
    "Front",
    "Left",
    "Right",
    "360",
  ]);

  //USE EFFECTS
  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-based index
    const currentDay = currentDate.getDate();
    if (currentMonth === 4 && currentDay === 1) {
      toggleFools(true);
    } else {
      toggleFools(false);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      setLoadingTimeout((prevCount) => prevCount + 1);

      if (loadingTimeout > 10) {
        toggleIsLoading(false);
        alert("Cannot find selected sequence or image.");
      }
    }
    // eslint-disable-next-line
  }, [heartbeat]);

  //HANDLERS
  const handleIsMapSwap = () => {
    setisMapSwap(!isMapSwap);
  };

  const handleSetMiniMapState = () => {
    setMinimapOpen(!minimapOpen);
  };

  const handleSetSelectedSequenceLength = (e) => {
    setSelectedSequenceLength(e);
  };

  const handleSetSelectedPictureDirection = (e) => {
    setSelectedPictureDirection(e);
  };

  const handleSetMapReload = () => {
    setMapReload(!mapReload);
  };
  const handleSetSelectedSequenceID = (e) => {
    setSelectedSequenceId(e);
  };
  const handleSetSelectedSequenceIndex = (e) => {
    setSelectedSequenceIndex(e);
  };
  const handleSetRawData = (e) => {
    setRawData(e);
  };

  const handleSetFlyToCoords = (coords) => {
    setFlyToCoords(coords);
  };

  const handleSetFacing = (e) => {
    setSelectedView(e);
  };
  const handleSetVectorSourceIds = (e) => {
    if (e.length > 0) {
      setVectorSourceIds(e);
    }
  };

  const handleSetTripSelected = (e) => {
    setTripSelected(e);
  };

  const handleSetCurrentPictureIndex = (e) => {
    console.log("updating current picture index: ", e);
    setCurrentPictureIndex(e);
  };
  const handleSetCurrentPictureId = (e) => {
    setSelectedPictureId(e);
  };
  const handle_set_layer_list = (e) => {
    set_layer_list(e);
  };
  const handleSetCurrentSelectedTripDays = (e) => {
    setCurrentSelectedTripDays(e);
  };

  const handleSetSidebarState = () => {
    toggleSidebar();
    setTimeout(() => {
      if (mapRef && mapRef.current) {
        mapRef.current.getMap().resize();
      }
    }, 1);
  };

  const handleAvtiveMenuItemState = () => {
    toggleActiveMenuItem();
  };

  const handleSetDetailPanelState = () => {
    setDetailsOpen(!detailsOpen);
  };

  const handleSetOsm = () => {
    setOsm(!osm);
  };

  const handleSetML = () => {
    setML(!machine_learning);
  };

  const handle_License_change = (e) => {
    setLicense(e.target.value);
  };

  const handleSetProjectID = (e) => {
    setProjectID(e);
  };

  const handleSetSelectedMapIndex = (e) => {
    setSelectedMapIndex(e);
  };

  const handleToggleMetaBlock = (e) => {
    if (selectedImage) {
      if (e) {
        toggleMetaBlockOpen(e);
      } else {
        toggleMetaBlockOpen();
      }
    } else if (selectedSequence) {
      if (e) {
        toggleMetaBlockOpen(e);
      } else {
        toggleMetaBlockOpen();
      }
    }
    // else {
    //   alert("No Sequence or Image Selected");
    // }
  };

  const handle_change_imagery = (e) => {
    setCurrentImagery(e);
  };

  const handleCheckboxChange = (event) => {
    if (selectedImage) {
      toggleFollowCheckboxClicked();
      toggleFollowInJosm();
    }
  };

  const handleSetTempImages = async (e, imagetype = null, facing = null) => {
    setTempImageUrls([]);
    setTotalImgCount(0);
    setTempImageGeoJson(null);
    let temp_geoJson = {
      type: "FeatureCollection",
      features: [],
    };
    let lineFeature = {
      type: "Feature",
      properties: {
        id: 0,
        index: 0,
        image_facing: facing,
      },
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    };
    var selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        setTotalImgCount(e.target.files.length);
        const file = selectedFiles[i];
        const exifInfo = await extractExif(file);
        lineFeature.geometry.coordinates.push([exifInfo.Longi, exifInfo.Lati]);
        let feature = {
          type: "Feature",
          properties: {
            id: i,
            direction: exifInfo.heading,
            timestamp: exifInfo.timestamp,
            index: i,
            facing: facing,
            type: "temp",
          },
          geometry: {
            type: "Point",
            coordinates: [exifInfo.Longi, exifInfo.Lati],
          },
        };
        if (imagetype !== "drive") {
          feature.properties.facing = "";
        }
        temp_geoJson.features.push(feature);
      }
      if (imagetype === "drive") {
        temp_geoJson.features.push(lineFeature);
      }
      setTempImageGeoJson(temp_geoJson);
      if (mapRef && mapRef.current) {
        mapRef.current.getMap().flyTo({
          center: [
            temp_geoJson.features[0].geometry.coordinates[0],
            temp_geoJson.features[0].geometry.coordinates[1],
          ],
          duration: 2000,
          zoom: 18,
        });
      }
      let urls = await extractImgUrl(selectedFiles);
      for (let i = 0; i <= urls.length - 1; i++) {
        temp_geoJson.features[i].properties["img_url"] = urls[i];
      }
      setTempImageUrls(urls);
      let tempJson = updateGeoJsonWithUrls(temp_geoJson, urls, imagetype);
      setTempImageGeoJson(tempJson);
    }
  };

  const updateGeoJsonWithUrls = (geojson, urls, imagetype) => {
    let updatedGeoJson = { ...geojson };
    if (urls.length > 0) {
      let lastIndex;
      lastIndex = urls.length - 1;
      for (let i = 0; i <= lastIndex; i++) {
        updatedGeoJson.features[i].properties.img_url = urls[i];
      }
    }
    return updatedGeoJson;
  };

  const extractImgUrl = async (files) => {
    const filesArray = Array.from(files);
    const previews = await Promise.all(
      filesArray.map(async (file, index) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            let previewUrl = e.target.result;
            resolve(previewUrl);
            setCurrentImgCount((prevCount) => prevCount + 1);
          };

          reader.readAsDataURL(file);
        });
      }),
    );
    return previews;
  };

  const extractExif = async (file) => {
    return new Promise((resolve, reject) => {
      EXIF.getData(file, function () {
        var exifData = EXIF.pretty(this);
        if (exifData) {
          let lat = EXIF.getTag(this, "GPSLatitude");
          let latRef = EXIF.getTag(this, "GPSLatitudeRef");
          let Lati = ConvertDMSToDD(lat[0], lat[1], lat[2], latRef);
          let lon = EXIF.getTag(this, "GPSLongitude");
          let lonRef = EXIF.getTag(this, "GPSLongitudeRef");
          let Longi = ConvertDMSToDD(lon[0], lon[1], lon[2], lonRef);
          let timestamp = EXIF.getTag(this, "DateTimeOriginal");
          let heading = EXIF.getTag(this, "GPSImgDirection");
          heading = heading.numerator / heading.denominator;
          resolve({ Lati, Longi, heading, timestamp });
        } else {
          reject(new Error("No EXIF data found in image '" + file.name + "'."));
        }
      });
    });
  };

  const ProgressBar = (props) => {
    const percentage = (props.current / props.total) * 100;
    return (
      <div
        style={{
          position: "absolute",
          top: "20vh",
          left: "10vw",
          width: "30vw",
          height: "20vh",
          backgroundColor: "lightgrey",
        }}
      >
        {percentage}
        <div
          className="progress-fill"
          style={{
            width: `${percentage}%`,
            height: "10vh",
            backgroundColor: "green",
          }}
        ></div>
      </div>
    );
  };

  function ConvertDMSToDD(degrees, minutes, seconds, direction) {
    var dd = degrees + minutes / 60 + seconds / (60 * 60);
    if (direction == "S" || direction == "W") {
      dd = dd * -1;
    }
    return dd;
  }

  // RESET ORG UPLOAD BOOKMARKS //
  const clear_upload_bookmark = () => {
    let clearBookmarkURL = "upload/clear_upload_bookmark";
    let outpack = {
      trip_id: tripSelected,
    };
    poster(outpack, clearBookmarkURL).then((response) => {
      if (response.status === 200) {
        alert(response.message);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  //FETCH ALL ORG TEAMS
  const fetchAdminTeams = () => {
    let outpack = {};
    let fetchTeamsURL = "team/fetch_admin_teams";
    poster(outpack, fetchTeamsURL).then((response) => {
      if (response.status === 200) {
        setEditingTeams(response.editing_teams);
        setDriveTeams(response.drive_teams);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  const MAX_RETRY = 3;
  const downloadImagesAndZip = async (imageUrls, videoFilename) => {
    const zip = new JSZip();

    await Promise.all(
      imageUrls.map(async (imageUrl) => {
        let retryCount = 0;
        let success = false;

        while (retryCount < MAX_RETRY && !success) {
          try {
            const response = await fetch(imageUrl, {
              responseType: "arraybuffer",
            });
            const arrayBuffer = await response.arrayBuffer();

            const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
            const blob = new Blob([arrayBuffer]);

            zip.file(filename, blob);
            success = true;
          } catch (error) {
            console.error(`Error downloading image from ${imageUrl}:`, error);
            retryCount++;
          }
        }
      }),
    );

    const content = await zip.generateAsync({ type: "blob" });

    if (videoFilename !== "N/A") {
      try {
        let fileNameWithoutExtension = videoFilename
          .replace(/^.*[\\/]/, "")
          .split(".")
          .slice(0, -1)
          .join(".");

        saveAs(content, fileNameWithoutExtension);
      } catch {
        saveAs(content, "images.zip");
      }
    } else {
      saveAs(content, "images.zip");
    }
    toggleIsLoading(false);
  };

  // FETCH SEQUENCE IMAGES URLS //
  const fetch_image_urls = async (sequence_id) => {
    let fetchImagesURL = "sequences/fetch_selected_images_urls";
    let outpack = {
      sequence_id: sequence_id,
    };
    toggleIsLoading(true);
    poster(outpack, fetchImagesURL).then((response) => {
      if (response.status === 200) {
        const imageUrls = response.image_urls;
        let videoFilename = response.video_filename;
        downloadImagesAndZip(imageUrls, videoFilename);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  //FETCH trip fly to  coordinates
  const fetchFlyToCoords = (tripId) => {
    let outpack = { trip_id: tripId };
    let fetchTeamsURL = "project/fetch_trip_fly_to_location";
    poster(outpack, fetchTeamsURL).then((response) => {
      if (response.status === 200) {
        handleSetFlyToCoords(response.fly_to_cords);
        let lon = response.fly_to_cords[0];
        let lat = response.fly_to_cords[1];
        setCurrentZoom(8);
        // if (mapRef && mapRef.current) {
        //   console.log("setting viewport")
        setViewport({
          longitude: lon,
          latitude: lat,
          zoom: 8,
        });
        // }
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
        }
      }
    });
  };

  // FETCH BOTH ASSIGNED AND UNASSIGNED TEAMS //
  const fetch_assignment_teams = (e = "none") => {
    let fetchTeamsURL = "team/assignment_teams";
    let outpack = {
      target_user_id: e,
    };
    poster(outpack, fetchTeamsURL).then((response) => {
      if (response.status === 200) {
        setAssignedTeams(response.assigned);
        setUnassignedTeams(response.unassigned);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // ASSIGN TEAM TO TRIP //
  const assignTeam = (trip_id) => {
    let assignTeamURL = "team/assign_team";
    let outpack = {
      team_id: teamSelected,
      trip_id: trip_id,
    };
    poster(outpack, assignTeamURL).then((response) => {
      if (response.status === 200) {
        fetchProjectTeams();
        fetchOrgTrips("new");
        fetchOrgTrips("ready");
        fetchOrgTrips("archived");
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  //UNASSIGN TEAM FROM PROJECT //
  const unAssignTeam = (team_id, trip_id) => {
    let assignTeamURL = "team/unassign_team";
    let outpack = {
      team_id: team_id,
      trip_id: trip_id,
    };
    poster(outpack, assignTeamURL).then((response) => {
      if (response.status === 200) {
        fetchProjectTeams();
        fetchOrgTrips("new");
        fetchOrgTrips("ready");
        fetchOrgTrips("archived");
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        console.log(`Error:`, response);
      }
    });
  };

  //UNASSIGN TEAM FROM PROJECT //
  const testApi = (cloud_path) => {
    let assignTeamURL = "upload/add_single_image";
    let outpack = {
      cloud_path: cloud_path,
    };
    poster(outpack, assignTeamURL).then((response) => {
      if (response.status === 200) {
        alert(response.message);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // MODIFY TEAM DETAILS //
  const modifyTeamDetails = (team_id, team_name, team_description) => {
    let modifyTeamURL = "team/modify_team_details";
    let outpack = {
      team_id: team_id,
      team_name: team_name,
      team_description: team_description,
    };
    poster(outpack, modifyTeamURL).then((response) => {
      if (response.status === 200) {
        alert(response.message);
        fetchAdminTeams();
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // DELETE TEAM ///
  const deleteTeam = (t_id) => {
    let deleteTeamURL = "team/delete_team";
    let outpack = {
      id: t_id,
    };
    poster(outpack, deleteTeamURL).then((response) => {
      if (response.status === 200) {
        fetchAdminTeams();
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // FETCH USERS ASSIGNED TO TEAM //
  const fetchTeamMembers = (team_id) => {
    let fetchUsersURL = "organization/fetch_team_members";
    let outpack = {
      team_id: team_id,
    };
    poster(outpack, fetchUsersURL).then((response) => {
      if (response.status === 200) {
        setTeamMembers(response.team_users);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // FETCH USERS UNASSIGNED TO SELECTED TEAM //
  const fetchUnassignedMembers = (team_id) => {
    let fetchUsersURL = "organization/fetch_unassigned_users";
    let outpack = {
      team_id: team_id,
    };
    poster(outpack, fetchUsersURL).then((response) => {
      if (response.status === 200) {
        setUnassignedTeamMembers(response.team_users);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // FETCH PROJECT TEAMS //
  const fetchProjectTeams = (selector = null) => {
    let fetchURL;
    if (selector === "unassigned") {
      fetchURL = "team/fetch_unassigned_teams";
    } else {
      fetchURL = "team/fetch_project_teams";
    }
    let outpack = {
      trip_id: tripSelected,
    };
    poster(outpack, fetchURL).then((response) => {
      if (response.status === 200) {
        if (selector === "unassigned") {
          setUnassignedTeams(response.unassigned_teams);
        } else {
          setProjectTeams(response.assigned_teams);
        }
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  //CREATE NEW TEAM//
  const createTeam = (teamName, teamDescription, type) => {
    let createTeamURL = "team/create_team";
    let outpack = {
      name: teamName,
      desc: teamDescription,
      type: type,
    };

    console.log(outpack);
    poster(outpack, createTeamURL).then((response) => {
      if (response.status === 200) {
        fetchAdminTeams();
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // FETCH PROJECT GROUPS //
  const fetchProjectGroups = (project_id) => {
    let fetchProjectGroupsURL = "group/fetch_project_groups";
    let outpack = {
      project_id: project_id,
    };
    poster(outpack, fetchProjectGroupsURL).then((response) => {
      if (response.status === 200) {
        setProjectGroups(response.project_groups);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // CREATE NEW TRIP //
  const createTrip = (
    visibility,
    name,
    desc = null,
    country = null,
    city = null,
    vehicles = null,
  ) => {
    let outpack = {
      visibility: visibility,
      name: name,
      desc: desc,
      country: country,
      city: city,
      vehicles: vehicles,
      driveTeam: driveTeam,
      startDate: tripStartDate,
      endDate: tripEndDate,
      backupFolder: backupFolder,
    };
    poster(outpack, "project/create_trip")
      .then((response) => {
        if (response.status === 200) {
          fetchOrgTrips("new");
          fetchOrgTrips("ready");
          fetchOrgTrips("archived");
          setTripSelected(response.new_trip_id);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  //EDIT TRIP DETAILS
  const modifyTripDetails = (
    trip_id,
    trip_name,
    trip_desc,
    trip_city,
    trip_country,
  ) => {
    let outpack = {
      id: trip_id,
      name: trip_name,
      desc: trip_desc,
      city: trip_city,
      country: trip_country,
    };

    poster(outpack, "project/modify_trip")
      .then((response) => {
        if (response.status === 200) {
          fetchOrgTrips("new");
          fetchOrgTrips("ready");
          fetchOrgTrips("archived");
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH TRIP VEHICLES //
  const fetchTripVehicles = (trip_id) => {
    let outpack = {
      trip_id: trip_id,
    };
    poster(outpack, "project/fetch_trip_vehicles")
      .then((response) => {
        if (response.status === 200) {
          setTripVehicles(response.vehicles);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const editVehicle = (
    vehicle_id,
    vehicle_name,
    vehicle_make,
    vehicle_model,
    vehicle_cameras,
    vehicle_start_date,
    vehicle_end_date,
    vehicle_days_traveled,
  ) => {
    let outpack = {
      vehicle_id: vehicle_id,
      vehicle_name: vehicle_name,
      vehicle_make: vehicle_make,
      vehicle_model: vehicle_model,
      vehicle_cameras: vehicle_cameras,
      vehicle_start_date: vehicle_start_date,
      vehicle_end_date: vehicle_end_date,
      vehicle_days_traveled: vehicle_days_traveled,
    };

    poster(outpack, "project/editVehicle")
      .then((response) => {
        if (response.status === 200) {
          fetchOrgTrips("new");
          fetchOrgTrips("ready");
          fetchOrgTrips("archived");
          fetchTripVehicles(tripSelected);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const addVehicle = (
    vehicle_name,
    vehicle_make,
    vehicle_model,
    vehicle_cameras,
    vehicle_start_date,
    vehicle_end_date,
    vehicle_days_traveled,
  ) => {
    let outpack = {
      trip_id: tripSelected,
      vehicle_name: vehicle_name,
      vehicle_make: vehicle_make,
      vehicle_model: vehicle_model,
      vehicle_cameras: vehicle_cameras,
      vehicle_start_date: vehicle_start_date,
      vehicle_end_date: vehicle_end_date,
      vehicle_days_traveled: vehicle_days_traveled,
    };

    poster(outpack, "project/addVehicle")
      .then((response) => {
        if (response.status === 200) {
          fetchOrgTrips("new");
          fetchOrgTrips("ready");
          fetchOrgTrips("archived");
          fetchTripVehicles(tripSelected);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH VEHICLE DAYS //
  const fetchVehicleDays = (vehicle_id) => {
    let outpack = {
      vehicle_id: vehicle_id,
    };

    poster(outpack, "project/fetch_vehicle_days")
      .then((response) => {
        if (response.status === 200) {
          setVehicleDays(response.days);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH VEHICLE CAMERAS //
  const fetchVehicleCameras = (vehicle_id, day_id) => {
    let outpack = {
      vehicle_id: vehicle_id,
      day_id: day_id,
    };

    poster(outpack, "project/fetch_vehicle_cameras")
      .then((response) => {
        if (response.status === 200) {
          setVehicleCameras(response.cameras);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const fetch_day_sequences = (day_id, camera_id) => {
    let outpack = {
      day_id: day_id,
      camera_id: camera_id,
    };

    poster(outpack, "sequences/fetch_day_sequences")
      .then((response) => {
        if (response.status === 200) {
          setDaySequences(response.sequences);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const fetch_sequence_images = (sequence_id) => {
    let outpack = {
      sequence_id: sequence_id,
    };

    poster(outpack, "sequences/fetch_sequence_images")
      .then((response) => {
        if (response.status === 200) {
          setSequenceImages(response.images);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH TRIP VEHICLES //
  const fetchTripInfo = () => {
    let outpack = {
      trip_id: tripSelected,
    };
    if (tripSelected !== null) {
      poster(outpack, "project/fetch_trip_info")
        .then((response) => {
          if (response.status === 200) {
            setTripVehiclesFilters(response.vehicles);
            setTripCamerasFilters(response.cameras);
            // Sort response.days by day_of_travel
            const sortedDays = response.days.sort((a, b) => {
              // Assuming day_of_travel is in the format "Day X"
              const dayA = parseInt(a.day_of_travel.split(" ")[1]);
              const dayB = parseInt(b.day_of_travel.split(" ")[1]);
              return dayA - dayB;
            });
            setTripDaysFilters(response.days);

            let updatedParameters = filterParameters;

            updatedParameters = updatedParameters.concat(
              response.vehicles.map((vehicle) => vehicle.id),
            );
            updatedParameters = updatedParameters.concat(
              response.cameras.map((camera) => camera.facing),
            );
            updatedParameters = updatedParameters.concat(
              response.days.map((day) => day.day_of_travel),
            );

            setFilterParameters(updatedParameters);
          } else if (response.status === 304) {
            setUser(null);
            return <Navigate push to={"/login"} />;
          } else if (!response.ok) {
            if (response.status === 524) {
              alert("RequestTimeout: Please try again later.");
            } else {
            }
          }
        })
        .catch((error) => {
          console.log(`Network error:`, error);
        });
    }
  };

  const deleteTrip = (trip_id) => {
    let outpack = {
      trip_id: trip_id,
    };
    poster(outpack, "project/delete_trip")
      .then((response) => {
        if (response.status === 200) {
          fetchOrgTrips("new");
          fetchOrgTrips("ready");
          fetchOrgTrips("archived");
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const deleteDay = (bool) => {
    let outpack = {
      day_id: selectedDay,
      delete_originals: bool,
    };
    poster(outpack, "/delete_day")
      .then((response) => {
        if (response.status === 200) {
          fetchVehicleDays(selectedVehicle);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const deleteCamera = (bool) => {
    let outpack = {
      camera_id: selectedCamera,
      delete_originals: bool,
    };
    poster(outpack, "/delete_camera")
      .then((response) => {
        if (response.status === 200) {
          // fetchVehicleDays(selectedVehicle);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const archiveTrip = (trip_id) => {
    let outpack = {
      trip_id: trip_id,
    };
    poster(outpack, "project/archive_trip")
      .then((response) => {
        if (response.status === 200) {
          fetchOrgTrips("new");
          fetchOrgTrips("ready");
          fetchOrgTrips("archived");
          alert(`Trip ${trip_id} has been moved to the archives`);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const completeTrip = (trip_id) => {
    let outpack = {
      trip_id: trip_id,
    };
    poster(outpack, "project/complete_trip")
      .then((response) => {
        if (response.status === 200) {
          fetchOrgTrips("new");
          fetchOrgTrips("ready");
          fetchOrgTrips("archived");
          alert(`Trip ${trip_id} has been moved to the completed table`);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const uploadingTrip = (trip_id) => {
    let outpack = {
      trip_id: trip_id,
    };
    poster(outpack, "project/uploading_trip")
      .then((response) => {
        if (response.status === 200) {
          fetchOrgTrips("new");
          fetchOrgTrips("ready");
          fetchOrgTrips("archived");
          alert(`Trip ${trip_id} has been moved to the completed table`);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  function sortOrgTrips(arr) {
    const { length } = arr;
    for (let i = 0; i < length; i++) {
      for (let j = 0; j < length - 1 - i; j++) {
        if (arr[j].id <= arr[j + 1].id) {
          let temp = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = temp;
        }
      }
    }
    return arr;
  }

  const fetchPublicTrips = () => {
    fetcher("project/fetch_public_trips").then((r) => {
      if (r.status === 200) {
        if (r.public_trips != null) {
          setPublicTrip(r.public_trips[0]);
        }
      } else {
        alert("Error fetching trips");
      }
    });
  };

  // FETCH ORGANIZATION PROJECTS //
  const fetchOrgTrips = (upload_complete) => {
    let outpack = {
      upload_complete: upload_complete,
    };
    poster(outpack, "project/fetch_org_trips")
      .then((response) => {
        if (response.status === 200) {
          if (upload_complete === "new") {
            setNewTrips(response.data);
          }
          if (upload_complete === "ready") {
            // Sorting ready trips so the one with the highest id will be selected for the map page
            const orgTrips = sortOrgTrips(response.data);
            setReadyTrips(orgTrips);
          }
          if (upload_complete === "archived") {
            setArchivedTrips(response.data);
          }
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH PROJECT SEQUENCES //
  const fetchUserTrips = () => {
    let outpack = {};
    let fetchUserTripsURL = "project/fetch_user_trips";
    poster(outpack, fetchUserTripsURL)
      .then((response) => {
        if (response.status === 200) {
          setUserTrips(response.data);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // Fetch lists of projects assigned and unassigned to the currently selected team
  const fetch_assignment_projects = (e) => {
    let outpack = {
      team_id: e,
    };
    poster(outpack, "project/fetch_assignment_projects")
      .then((response) => {
        if (response.status_code === 200) {
          setAssignedProjects(response.assigned);
          setUnassignedProjects(response.unassigned);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const fetch_admin_dash_info = () => {
    let outpack = {};
    poster(outpack, "project/fetch_admin_dash_info")
      .then((response) => {
        if (response.status_code === 200) {
          setAdminDashboardInfo(response.data);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH ORGANIZATION USERS //
  const fetchOrgUsers = () => {
    let outpack = {};
    let fetchUsersURL = "organization/fetch_users";
    poster(outpack, fetchUsersURL)
      .then((response) => {
        if (response.status === 200) {
          setOrgUsers(response.users);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // MODIFY USER ROLE - not currently used //
  const modifyUser = (id, role) => {
    let modifyUsersURL = "organization/modify_users";
    let outpack = {
      target_user_id: id,
      role: role,
    };
    poster(outpack, modifyUsersURL)
      .then((response) => {
        if (response.status === 200) {
          fetchOrgUsers();
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // REMOVE USER FROM ORGANIZATION //
  const removeUser = (id) => {
    let removeUsersURL = "organization/remove_users";
    let outpack = {
      target_user_id: id,
    };
    poster(outpack, removeUsersURL)
      .then((response) => {
        if (response.status === 200) {
          fetchOrgUsers();
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // MODIFY USER ROLE //
  const inviteUser = (email) => {
    let inviteUserURL = "user/invite_user";
    let outpack = {
      email: email,
    };
    poster(outpack, inviteUserURL)
      .then((response) => {
        if (response.status === 200) {
          alert(response.message);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // ASSIGN USER //
  const assignUser = (user_id, team_id) => {
    let assignUserURL = "user/assign_user";
    let outpack = {
      target_user_id: user_id,
      team_id: team_id,
    };
    poster(outpack, assignUserURL)
      .then((response) => {
        if (response.status === 200) {
          fetchTeamMembers(team_id);
          fetchAdminTeams();
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // UNASSIGN USER //
  const unassignUser = (user_id, team_id) => {
    let unassignUserURL = "user/unassign_user";
    let outpack = {
      target_user_id: user_id,
      team_id: team_id,
    };
    poster(outpack, unassignUserURL)
      .then((response) => {
        if (response.status === 200) {
          fetchTeamMembers(team_id);
          fetchAdminTeams();
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH PROJECT SEQUENCES //
  const fetchProjectDirectionSequences = (project_id, direction) => {
    let fetchSequencesURL = "sequences/fetch_project_direction_sequences";
    const outpack = {
      project_id,
      direction,
    };
    poster(outpack, fetchSequencesURL)
      .then((response) => {
        if (response.status === 200) {
          setProjectDirectionSequences(response.sequences);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // DELETE SEQUENCE //
  const deleteSequence = (delete_originals = false) => {
    let deleteSequenceURL = "sequences/delete_sequence";
    let outpack = {
      sequence_id: selectedSequence,
      delete_originals: delete_originals,
    };
    // console.log('TRIGGERED',outpack)
    poster(outpack, deleteSequenceURL)
      .then((response) => {
        if (response.status === 200) {
          alert("Sequence Deleted");
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH IMAGE NOTES //
  const delete_image = (image_id) => {
    let outpack = {
      image_id: image_id,
    };
    poster(outpack, "sequences/delete_image")
      .then((response) => {
        if (response.status === 200) {
          alert(response.message);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const fetch_image_details = () => {
    let outpack = {
      image_id: selectedImage,
    };
    poster(outpack, "sequences/fetch_image_details")
      .then((response) => {
        if (response.ok) {
          setImageDetails(response.image_details);
        }
        // else if (response.status === 304) {
        //     setUser(null);
        //     return <Navigate push to={"/login"}/>;
        // }
        else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  // FETCH IMAGE URL //
  const fetchImageUrl = (image_id) => {
    let fetchURLURL = "sequences/fetch_img_url";
    let outpack = {
      image_id: image_id,
    };
    poster(outpack, fetchURLURL)
      .then((response) => {
        if (response.status === 200) {
          setCurrentImageUrl(response.img_url);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const checkCloudConnection = (service, type) => {
    let DOconnectURL = "upload/checkCloudConnection";
    let outpack = {
      service: service,
      type: type,
    };
    poster(outpack, DOconnectURL)
      .then((response) => {
        if (response.status === 200) {
          if (response.connection_status === true) {
            setConnectingText(`Connected to Bucket`);
            setCloudFolderContents(response.contents.folders);
            setCloudFileContents(response.contents.files);
            toggleCloudConnected(true);
          } else {
            setConnectingText(`Connection to Bucket Failed`);
            toggleCloudConnected(false);
          }
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const listCloudFilesAndFolders = (path, media_type) => {
    let DOconnectURL = "upload/ListObjectsDigitalOcean";
    let outpack = {
      path,
      media_type,
    };
    toggleIsLoading(true);
    poster(outpack, DOconnectURL)
      .then((response) => {
        if (response.status === 200) {
          setCloudFolderContents(response.folders);
          setCloudFileContents(response.files);
          toggleIsLoading(false);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const addTripVehicle = (name, cameras, days) => {
    let addVehicleURL = "project/addVehicle";
    let outpack = {
      trip_id: tripSelected,
      name: name,
      cameras: cameras,
      days: days,
    };
    toggleIsLoading(true);
    poster(outpack, addVehicleURL)
      .then((response) => {
        if (response.status_code === 200) {
          fetchTripVehicles(tripSelected);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const deleteTripVehicle = (vehicle_id) => {
    let addVehicleURL = "project/deleteVehicle";
    let outpack = {
      trip_id: tripSelected,
      vehicle_id: vehicle_id,
    };
    toggleIsLoading(true);
    poster(outpack, addVehicleURL)
      .then((response) => {
        if (response.status_code === 200) {
          fetchTripVehicles(tripSelected);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const editCamera = (camera_id, make = null, model = null) => {
    let URL = "project/edit_camera";
    let outpack = {
      camera_id: camera_id,
      camera_make: make,
      camera_model: model,
    };
    poster(outpack, URL)
      .then((response) => {
        if (response.ok) {
          alert("Camera Updated");
          toggleIsLoading(true);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
    toggleIsLoading(false);
  };

  const addCamera = (camera_make, camera_model, camera_facing, day_id) => {
    let URL = "project/add_camera";
    let outpack = {
      camera_facing: camera_facing,
      camera_make: camera_make,
      camera_model: camera_model,
      day_id: day_id,
    };
    poster(outpack, URL)
      .then((response) => {
        if (response.ok) {
          alert("Camera Added");
          toggleIsLoading(true);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
    toggleIsLoading(false);
  };

  const editTripVehicle = (vehicle_id, name, cameras, days) => {
    let addVehicleURL = "project/editVehicle";
    let outpack = {
      trip_id: tripSelected,
      vehicle_id: vehicle_id,
      name: name,
      cameras: cameras,
      days: days,
    };
    toggleIsLoading(true);
    poster(outpack, addVehicleURL)
      .then((response) => {
        if (response.status_code === 200) {
          fetchTripVehicles(tripSelected);
        } else if (response.status === 304) {
          setUser(null);
          return <Navigate push to={"/login"} />;
        } else if (!response.ok) {
          if (response.status === 524) {
            alert("RequestTimeout: Please try again later.");
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(`Network error:`, error);
      });
  };

  const processCloudFiles = (
    path,
    files,
    trip_id,
    processSubfolders = false,
    selectedFolder = null,
    mediaType = null,
  ) => {
    let ProcessCloudFilesURL;
    // // if (processSubfolders) {
    // //   ProcessCloudFilesURL = "/upload_trip_to_mapillary";
    // // } else {
    // //   ProcessCloudFilesURL = "upload/process_cloud_files";
    // // }
    ProcessCloudFilesURL = "process/trip_sequences";
    let outpack = {
      trip_id: trip_id,
      day_id: selectedDay,
      camera_facing: selectedCameraFacing,
      camera_id: selectedCamera,
      vehicle_id: selectedVehicle,
      path: path,
      files: files,
      processSubfolders: processSubfolders,
      selectedFolder: selectedFolder,
      mediaType: mediaType,
    };
    if (isLoading === false) {
      toggleIsLoading(true);
      poster(outpack, ProcessCloudFilesURL)
        .then((response) => {
          if (response.status === 200) {
            toggleIsLoading(false);
          } else if (response.status === 304) {
            setUser(null);
            return <Navigate push to={"/login"} />;
          } else if (!response.ok) {
            if (response.status === 524) {
              alert("RequestTimeout: Please try again later.");
            } else {
            }
          }
        })
        .catch((error) => {
          alert(`Network error${error}`);
        });
    }
  };

  const exportGPX = (selection, facing = null, sequence_id = null) => {
    let downloadGPXURL = `api/project/export_GPX?selection=${selection}&facing=${facing}&sequence_id=${sequence_id}&project_id=${tripSelected}`;
    if (sequence_id === null) {
      sequence_id = "ALL";
    }
    if (facing === null) {
      facing = selectedView;
    }
    let fileName = `Project-${tripSelected}-Sequence-${sequence_id}-Facing-${facing}.gpx`;
    toggleIsLoading(true);
    return fetch(downloadGPXURL, { method: "GET" })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, fileName))
      .then(toggleIsLoading(false));
  };

  const handleIsLoading = (e) => {
    let loadingTimeout;
    toggleIsLoading(e);
    if (e === true) {
      loadingTimeout = setTimeout(() => {
        toggleIsLoading(false);
      }, 20000);
    } else {
      clearTimeout(loadingTimeout);
    }
  };

  const makePicture = (properties, geometry) => {
    return {
      ...geometry,
      ...properties,
      ...{ timestamp: new Date(properties.timestamp) },
    };
  };

  const null_feature_states = () => {
    handleSetSelectedPictureDirection(null);
    setVectorFeatureIndex(null);
    setSelectedPictureDirection(null);
    setSelectedFeature(null);
    setNewtripSelected(null);
  };
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);

  const [isCalling, setIsCalling] = useState(false);
  function handle_cycle_feature(type) {
    if (!isCalling) {
      setIsCalling(true);
      cycle_feature(type)
        .then(() => {
          setIsCalling(false);
        })
        .catch((error) => {
          console.error("Error cycling feature:", error);
          setIsCalling(false);
        });
    }
  }

  const cycle_feature = async (type) => {
    if (!currentFeature) {
      return;
    }
    let lng = currentFeature.geometry.coordinates[0];
    let lat = currentFeature.geometry.coordinates[1];
    let timestamp = currentFeature.properties.timestamp;
    let direction = currentFeature.properties.facing;
    // Find potential nodes
    let feature = await find_next_feature(
      lat,
      lng,
      100,
      type,
      timestamp,
      direction,
    );

    if (feature !== undefined) {
      await select_feature(feature);
    } else {
      console.log("No feature found");
    }
  };

  const handleSetSelectedView = async (direction) => {
    if (currentFeature) {
      let lng = currentFeature.geometry.coordinates[0];
      let lat = currentFeature.geometry.coordinates[1];
      let timestamp = currentFeature.properties.timestamp;
      let feature = await find_next_feature(
        lat,
        lng,
        10,
        "equal",
        timestamp,
        direction,
      );

      if (feature !== undefined) {
        select_feature(feature);
      } else {
        console.log("No feature found");
      }
    }
  };

  async function select_feature(feature) {
    let pic = {};
    try {
      pic = {
        img_url: feature.properties.img,
        coordinates: feature.geometry.coordinates,
        facing: feature.properties.facing,
        direction: feature.properties.heading,
      };
    } catch (error) {
      console.error("Error Selecting Feature", error);
      console.log(feature);
      return;
    }
    setCurrentFeature(feature);
    setSelectedView(feature.properties.facing);
    setSelectedPicture(pic);
    try {
      if (mapRef && mapRef.current) {
        mapRef.current.getMap().easeTo({
          center: feature.geometry.coordinates,
          duration: 100,
          easing(t) {
            if (t === 1) {
              update_url(feature);
            }
            return t;
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  const [isPlaying, setIsPlaying] = useToggle(false);
  const [autoplayTimer, setAutoplayTimer] = useState(2000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isPlaying) {
        handle_cycle_feature("up");
      }
    }, autoplayTimer);

    return () => clearInterval(intervalId);
  }, [isPlaying, currentFeature]);

  // HANDLES TOGGLING AUTOPLAY ON/OFF //
  const handle_autoplay = () => {
    setIsPlaying();
  };

  const handleSetAutoplayTimer = (e) => {
    setAutoplayTimer(e.target.value);
  };

  //SETS BOOLEAN IF MAP COMPONENT LOADED
  const isMapStyleLoaded = () =>
    mapRef && mapRef.current && mapRef.current.getMap().isStyleLoaded();

  //MOSTLY NON BLOCKING SLEEP TIMER FOR AUTOPLAY FEATURE
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  //CREATES EMPTY GEOJSON TEMPLATE TO CLEAR POLYGON FROM MAP
  const clearPolygonTemplate = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          category: null,
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [0.0, 0.0],
              [0.1, 0.1],
              [0.0, 0.0],
            ],
          ],
        },
      },
    ],
  };

  // RESETS ALL SEQUENCE JSONS AND CURRENT MAP SELECTIONS WHEN NEW PROJECT SELECTED
  const resetViewJsons = () => {
    setFrontJson(null);
    setLeftJson(null);
    setRightJson(null);
    setRawData(null);
    setTempId(null);
    setSelectedFeature(null);
    setSelectedView(null);
    setSelectedPicture(null);
  };

  //FLIES THE MAP TO THE CURRENTLY SELECTED IMAGE
  const fly_to_selected = () => {
    if (selectedPicture) {
      let lat = selectedPicture.coordinates[1];
      let lng = selectedPicture.coordinates[0];
      if (mapRef && mapRef.current) {
        mapRef.current.getMap().easeTo({
          center: [lng, lat],
          zoom: currentZoom,
          duration: 2000,
        });
      }
    }
  };

  //HANDLES REMOVING AN ITEM FROM AN ARRAY AT A KNOWN INDEX
  const spliceArray = (inlist, index) => {
    inlist.splice(index, 1);
    return inlist;
  };

  //HANDLES FINDING THE INDEX OF AN ITEM IN AN ARRAY BY THE ITEM'S ID
  const findIndexById = (array, id) => {
    return array.find((obj) => obj.id === id);
  };

  //HANDLES RESETTING THE ZOOM LEVEL OF THE STANDARD IMAGE VIEWER
  const onClickZoomOutFully = () => {
    try {
      prismaZoom.current.reset();
    } catch {}
  };

  //FUNCTION FOR GENERATING A RANDOM INTEGER
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  //JOSM COMMAND HANDLER
  const josmPictureCommand = async (command, picture) => {
    if (picture) {
      try {
        let josmRemoteControl = "http://127.0.0.1:8111";
        await fetch(josmRemoteControl + "/version")
          .then((response) => response.json())
          .then((json) => json.version)
          .then((version) => sendJosmCommand(version, picture, command));
      } catch (e) {
        console.log(e);
      }
    }
  };

  // SEND VIEW SELECTED FEATURE IN JOSM REMOTE CONTROL COMMAND
  async function sendJosmCommand(version, selectedPicture, zoomCommand) {
    let josmRemoteControl = "http://127.0.0.1:8111";
    const buffer = 0.0007;
    let coords = selectedPicture.coordinates;
    let zoomProps = {
      left: coords[0] - buffer,
      right: coords[0] + buffer,
      bottom: coords[1] - buffer,
      top: coords[1] + buffer,
    };
    const zoomUrl = new URL(josmRemoteControl + zoomCommand);
    Object.keys(zoomProps).forEach((key) =>
      zoomUrl.searchParams.append(key, zoomProps[key]),
    );
    await fetch(zoomUrl);
    if (version > 17534) {
      const loadCommand = "/open_file";
      const loadPictureUrl = new URL(josmRemoteControl + loadCommand);
      let loadPictureParams = { filename: selectedPicture.img_url };
      Object.keys(loadPictureParams).forEach((key) =>
        loadPictureUrl.searchParams.append(key, loadPictureParams[key]),
      );
      await fetch(loadPictureUrl);
    }
  }

  const update_url = (feature) => {
    if (window.location.hash.includes("&key=")) {
      window.location.hash = window.location.hash.replace(
        /key=.*/,
        "key=" + JSON.stringify(feature),
      );
    } else {
      window.location.hash += "&key=" + JSON.stringify(feature);
    }
  };

  const handleToggleMapMenus = () => {
    toggleLayerMenuOpen(false);
    toggleFilterMenuOpen(false);
    toggleSearchOpen(false);
    togglePolygonOpen(false);
    toggleAddImagesOpen(false);
    toggleToolMenuOpen(false);
  };

  //STANDARD CLOUD STORAGE SERVER ENDPOINTS
  const S3_regions = [
    { value: "us-east-1", label: "us-east-1 : Ohio" },
    { value: "us-east-2", label: "us-east-2 : N.Virginia" },
    { value: "us-west-1", label: "us-west-1 : N. California" },
    { value: "us-west-2", label: "us-west-2 : Oregon" },
    { value: "af-south-1", label: "af-south-1 : Cape Town" },
    { value: "ap-east-1", label: "ap-east-1 : Hong Kong" },
    { value: "ap-southeast-3", label: "ap-southeast-3 : Jakarta" },
    { value: "ap-south-1", label: "ap-south-1 : Mumbai" },
    { value: "ap-northeast-3", label: "ap-northeast-3 : Osaka" },
    { value: "ap-northeast-2", label: "ap-northeast-2 : Seoul" },
    { value: "ap-southeast-1", label: "ap-southeast-1 : Singapore" },
    { value: "ap-southeast-2", label: "ap-southeast-2 : Sydney" },
    { value: "ap-northeast-1", label: "ap-northeast-1 : Tokyo" },
    { value: "ca-central-1", label: "ca-central-1 : Central Canada" },
    { value: "cn-north-1", label: "cn-north-1 : Beijing" },
    { value: "cn-northwest-1", label: "cn-northwest-1 : Ningxia" },
    { value: "eu-central-1", label: "eu-central-1 : Frankfurt" },
    { value: "eu-west-1", label: "eu-west-1 : Ireland" },
    { value: "eu-west-2", label: "eu-west-2 : London" },
    { value: "eu-south-1", label: "eu-south-1 : Milan" },
    { value: "eu-west-3", label: "eu-west-3 : Paris" },
    { value: "eu-north-1", label: "eu-north-1 : Stockholm" },
    { value: "sa-east-1", label: "sa-east-1 : São Paulo" },
    { value: "me-south-1", label: "me-south-1 : Bahrain" },
    { value: "us-gov-east-1", label: "us-gov-east-1 : US-East" },
    { value: "us-gov-west-1", label: "us-gov-west-1 : US-West" },
  ];

  //EXPORTS
  const value = {
    //REFS
    mapRef,
    pannellumRef,
    //DATA
    urlsToFetch,
    S3_regions,
    prismaZoom,
    history,
    clearPolygonTemplate,
    //TOGGLE STATES
    sidebarOpen,
    toggleSidebar,
    activeMenuItem,
    toggleActiveMenuItem,
    hasImagery,
    toggleHasImagery,
    followInJosm,
    toggleFollowInJosm,
    cloudConnected,
    toggleCloudConnected,
    isLoading,
    toggleIsLoading,
    metaBlockOpen,
    toggleMetaBlockOpen,
    LayerMenuOpen,
    toggleLayerMenuOpen,
    filterMenuOpen,
    toggleFilterMenuOpen,
    toolMenuOpen,
    toggleToolMenuOpen,
    alertOpen,
    toggleAlertOpen,
    followCheckboxClicked,
    toggleFollowCheckboxClicked,
    searchOpen,
    toggleSearchOpen,
    polygonOpen,
    togglePolygonOpen,
    addImagesOpen,
    toggleAddImagesOpen,
    //STATES AND SETTERS
    data: rawData,
    setData: setRawData,
    projectID,
    setProjectID,
    tempId,
    setTempId,
    orgUsers,
    setOrgUsers,
    orgTeams,
    setOrgTeams,
    editingTeams,
    setEditingTeams,
    orgGroups,
    setOrgGroups,
    readyTrips,
    setReadyTrips,
    osm,
    setOsm,
    machine_learning,
    setML,
    detailsOpen,
    setDetailsOpen,
    fetching,
    setFetching,
    assignedProjects,
    setAssignedProjects,
    unassignedProjects,
    setUnassignedProjects,
    orgSequences,
    setOrgSequences,
    projectGroups,
    setProjectGroups,
    assignedTeams,
    setAssignedTeams,
    unassignedTeams,
    setUnassignedTeams,
    otherTeams,
    setOtherTeams,
    driveTeams,
    setDriveTeams,
    selectedPicture,
    setSelectedPicture,
    assignedGroups,
    setAssignedGroups,
    unassignedGroups,
    setUnassignedGroups,
    orgImages,
    setOrgImages,
    imageNotes,
    setImageNotes,
    mapReload,
    setMapReload,
    sequenceLoaded,
    setSequenceLoaded,
    currentImageUrl,
    setCurrentImageUrl,
    selectedFeature,
    setSelectedFeature,
    license,
    setLicense,
    alertText,
    setAlertText,
    selectedMapIndex,
    setSelectedMapIndex,
    frontJson,
    setFrontJson,
    leftJson,
    setLeftJson,
    rightJson,
    setRightJson,
    regionSelected,
    setRegionSelected,
    bucket,
    setBucket,
    aws_access_key,
    setAccessToken,
    aws_secret_key,
    setSecretKey,
    path,
    setBasePath,
    projectDirectionSequences,
    setProjectDirectionSequences,
    projectTeams,
    setProjectTeams,
    teamSelected,
    setTeamSelected,
    teamMembers,
    setTeamMembers,
    unassignedTeamMembers,
    setUnassignedTeamMembers,
    userSelected,
    setUserSelected,
    userSelectedName,
    setUserSelectedName,
    uploadMethod,
    setUploadMethod,
    selectedView,
    setSelectedView,
    currentFeatureCollectionIndex,
    setcurrentFeatureCollectionIndex,
    TILE_URL,
    setTILE_URL,
    tripSelected,
    setTripSelected,
    selectedSequenceLength,
    setSelectedSequenceLength,
    autoPlay,
    setAutoPlay,
    selectedPictureDirection,
    setSelectedPictureDirection,
    selectedSequence,
    setSelectedSequence,
    selectedSequenceId,
    setSelectedSequenceId,
    vectorFeatureIndex,
    setVectorFeatureIndex,
    selectedSequenceIndex,
    setSelectedSequenceIndex,
    imageryXoffset,
    setImageryXoffset,
    imageryYoffset,
    setImageryYoffset,
    lastZoomedPicture,
    setLastZoomedPicture,
    currentImagery,
    setCurrentImagery,
    sequenceSelected,
    setSequenceSelected,
    projectSelected,
    setProjectSelected,
    rotateOffset,
    setRotateOffset,
    similarProjects,
    setSimilarProjects,
    layerIds,
    setLayerIds,
    currentYaw,
    setCurrentYaw,
    tile_url_list,
    set_tile_url_list,
    currentSelectedTripDays,
    setCurrentSelectedTripDays,
    zoom,
    setZoom,
    newtripSelected,
    setNewtripSelected,
    vectorSourceIds,
    setVectorSourceIds,
    currentPictureIndex,
    setCurrentPictureIndex,
    newNote,
    setNewNote,
    uploadingTrips,
    setUploadingTrips,
    imageBrightness,
    setImageBrightness,
    GPXfile,
    setGPXfile,
    minimapOpen,
    setMinimapOpen,
    archivedTrips,
    setArchivedTrips,
    newTrips,
    setNewTrips,
    selectedTripDays,
    setSelectedTripDays,
    isMapSwap,
    setisMapSwap,
    connectingText,
    setConnectingText,
    cloudFolderContents,
    setCloudFolderContents,
    cloudFileContents,
    setCloudFileContents,
    tripVehicles,
    setTripVehicles,
    userTrips,
    setUserTrips,
    vehicleDays,
    setVehicleDays,
    selectedVehicle,
    setSelectedVehicle,
    selectedDay,
    setSelectedDay,
    vehicleCameras,
    setVehicleCameras,
    selectedCamera,
    setSelectedCamera,
    dayOfTravel,
    setDayOfTravel,
    selectedVehicleName,
    setSelectedVehicleName,
    selectedCameraFacing,
    setSelectedCameraFacing,
    selectedTripCloudPath,
    setSelectedTripCloudPath,
    daySequences,
    setDaySequences,
    sequenceImages,
    setSequenceImages,
    imageDetails,
    setImageDetails,
    selectedImage,
    setSelectedImage,
    tripVehiclesFilters,
    setTripVehiclesFilters,
    tripCamerasFilters,
    setTripCamerasFilters,
    tripDaysFilters,
    setTripDaysFilters,
    filterParameters,
    setFilterParameters,
    adminDashboardInfo,
    setAdminDashboardInfo,
    searchResults,
    setSearchResults,
    drawPolyOpen,
    setDrawPolyOpen,
    displayBoundary,
    setDisplayBoundary,
    area,
    setArea,
    centroid,
    setCentroid,
    drawMode,
    setDrawMode,
    polyArray,
    setPolyArray,
    stringArray,
    setStringArray,
    pointArray,
    setPointArray,
    firstFeature,
    setfirstFeature,
    geoJson,
    setGeoJson,
    displayGeoJson,
    setDisplayGeoJson,
    centroidGeojson,
    setCentroidGeojson,
    tempImages,
    setTempImages,
    tempImageGeoJson,
    setTempImageGeoJson,
    selectedImageNotes,
    setSelectedImageNotes,
    tempImageUrls,
    setTempImageUrls,
    driveTeam,
    setDriveTeam,
    //HANDLERS
    handleSetOsm,
    handleSetML,
    handleSetSelectedPictureDirection,
    handleSetVectorSourceIds,
    handleSetMiniMapState,
    handleIsMapSwap,
    handleSetSelectedSequenceLength,
    handle_change_imagery,
    handleSetSelectedMapIndex,
    handleSetCurrentPictureId,
    handleSetMapReload,
    handleSetSidebarState,
    handleAvtiveMenuItemState,
    handleSetDetailPanelState,
    handleSetRawData,
    handleSetProjectID,
    handle_License_change,
    handleToggleMetaBlock,
    handleSetSelectedSequenceID,
    handle_set_layer_list,
    handleSetCurrentSelectedTripDays,
    handleCheckboxChange,
    handleSetFacing,
    handleSetCurrentPictureIndex,
    handleSetTripSelected,
    handleSetSelectedSequenceIndex,
    handleSetTempImages,
    handleToggleMapMenus,
    //FETCHERS
    fetchUnassignedMembers,
    fetchTeamMembers,
    fetchProjectTeams,
    fetchProjectDirectionSequences,
    fetch_assignment_projects,
    fetchImageUrl,
    fetchAdminTeams,
    fetchProjectGroups,
    fetchOrgTrips,
    fetchOrgUsers,
    fetch_assignment_teams,
    fetchUserTrips,
    fetchTripVehicles,
    fetchVehicleDays,
    fetchVehicleCameras,
    fetch_day_sequences,
    fetch_sequence_images,
    fetch_image_details,
    fetchTripInfo,
    fetch_admin_dash_info,
    // OTHER API CALL FUNCTIONS
    addVehicle,
    editVehicle,
    uploadingTrip,
    editTripVehicle,
    deleteTripVehicle,
    addTripVehicle,
    deleteSequence,
    makePicture,
    inviteUser,
    assignUser,
    removeUser,
    unassignUser,
    modifyUser,
    modifyTeamDetails,
    assignTeam,
    unAssignTeam,
    deleteTeam,
    createTeam,
    createTrip,
    deleteDay,
    deleteCamera,
    modifyTripDetails,
    exportGPX,
    archiveTrip,
    completeTrip,
    deleteTrip,
    processCloudFiles,
    delete_image,
    testApi,
    // OTHER FUNCTIONS
    josmPictureCommand,
    checkCloudConnection,
    spliceArray,
    findIndexById,
    listCloudFilesAndFolders,
    sleep,
    null_feature_states,
    onClickZoomOutFully,
    fly_to_selected,
    isMapStyleLoaded,
    resetViewJsons,
    getRandomInt,
    clear_upload_bookmark,
    ProgressBar,
    setStillsJson,
    backupFolder,
    setBackupFolder,
    tripStartDate,
    setTripStartDate,
    tripEndDate,
    setTripEndDate,
    ThreesixtyLoading,
    toggleThreeSixtyLoading,
    // SSO_Login,
    currentImgCount,
    totalImgCount,
    stillsJson,
    select_feature,
    selectionType,
    setSelectionType,
    sequenceFilename,
    setSequenceFilename,
    sequenceDate,
    setSequenceDate,
    fetch_image_urls,
    deleteOriginals,
    toggleDeleteOriginals,
    editCamera,
    addCamera,
    fools,
    toggleFools,

    fetchPublicTrips,
    publicTrip,
    setPublicTrip,
    toggleHeartbeat,
    handle_autoplay,
    handleSetAutoplayTimer,
    isPlaying,
    handle_cycle_feature,
    currentFeature,
    selectedFeatureIcon,
    handleSetSelectedView,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    update_url,
    setUnfilteredViews,
    unfilteredViews,
  };

  return value ? (
    <DataContext.Provider value={value}>{children}</DataContext.Provider>
  ) : null;
};
