import React, { useContext, useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AuthContext } from "../../common/AuthContext";
import { DataContext } from "../../common/DataContext";
import { NewTripTab } from "../TableHubs/TripHub/newTripTab";
import { ReadyTripTab } from "../TableHubs/TripHub/readyTripTab";
import { ArchivedTripTab } from "../TableHubs/TripHub/ArchiveTripTab";
import { Navigate } from "react-router-dom";
import "./styles.css";
import { AddTripModal } from "../TableHubs/TripHub/addTripModal";
import {
  ButtonDivComponent,
  ConfirmModalCommon,
  TopDiv,
} from "../commonComponents/commonComponents";
import { ModifyTripModal } from "../TableHubs/TripHub/modifyTripModal";
import useToggle from "../../hooks/useToggle";
import { DeleteTripModal } from "../TableHubs/TripHub/tripComponents";

export const AdminTripPage = () => {
  const {
    tripSelected,
    handleSetTripSelected,
    archiveTrip,
    completeTrip,
    uploadingTrip,
    deleteTrip,
    setSelectedView,
    toggleHasImagery,
    setcurrentFeatureCollectionIndex,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);

  const [redirect, setRedirect] = useState(false);
  const [tabKey, setTabKey] = useState(0);
  const [openAddTripModal, setOpenAddTripModal] = useState(false);
  const [openModifyTripModal, toggleModifyTripModal] = useToggle(false);
  const [tripStatus, setTripStatus] = useState("");
  const [tripName, setTripName] = useState("");
  const [tripCity, setTripCity] = useState("");
  const [tripCountry, setTripCountry] = useState("");
  const [tripDescription, setTripDescription] = useState("");
  const [imagePreviewOpen, toggleImagePreviewOpen] = useToggle(false);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [confirmOpen, toggleConfirmOpen] = useToggle(false);
  const [statusAction, setStatusAction] = useState(null);
  const [deleteOpen, toggleDeleteOpen] = useToggle(false);

  useEffect(() => {
    if (user === null || user.role !== "admin") {
      window.location.href = "/login";
    }
  }, []);

  const handleTabChange = (newTabKey) => {
    setTabKey(newTabKey);
  };
  const handleOpenModal = () => {
    setOpenAddTripModal(true);
  };

  const handleModifyOpen = () => {
    if (tripSelected !== null) {
      //If the modal is already open reset states before closing
      if (openModifyTripModal) {
        setTripName("");
        setTripCity("");
        setTripCountry("");
        setTripDescription("");
        handleSetTripSelected(null);
      }
      toggleModifyTripModal();
    } else {
      alert("No Trip Selected");
    }
  };

  const handleDeleteOpen = () => {
    if (tripSelected !== null) {
      toggleDeleteOpen();
    } else {
      alert("No Trip Selected");
    }
  };

  const handle_delete_trip = () => {
    deleteTrip(tripSelected);
    handleSetTripSelected(null);
    handleDeleteOpen();
  };

  const handleConfirmOpen = () => {
    toggleConfirmOpen();
  };

  const handleSetStatusAction = (e) => {
    setStatusAction(e);
  };

  const handleImagePreviewOpen = (url) => {
    toggleImagePreviewOpen(true);
    setSelectedImageURL(url);
  };

  const handleUploadingTrip = () => {
    uploadingTrip(tripSelected);
    toggleConfirmOpen(false);
    toggleModifyTripModal();
  };
  const handleCompleteTrip = () => {
    completeTrip(tripSelected);
    toggleConfirmOpen(false);
    toggleModifyTripModal();
  };
  const handleArchiveTrip = () => {
    archiveTrip(tripSelected);
    toggleConfirmOpen(false);
    toggleModifyTripModal();
  };

  const handleClickSelectTrip = (id, name, desc, city, country, status) => {
    if (id !== tripSelected) {
      setTripName(name);
      setTripCity(city);
      setTripCountry(country);
      handleSetTripSelected(id);
      setTripDescription(desc);
      setTripStatus(status);
    } else {
      if (tripSelected !== null) {
        setTripName(null);
        handleSetTripSelected(null);
        setTripStatus(null);
      }
    }
  };

  // SETS SELECTED PROJECT TO STATE WHEN PROJECT ROW IS CLICKED //
  const view_all_project_sequences = (e) => {
    setRedirect(!redirect);
  };

  return (
    <>
      <AddTripModal
        addOpen={openAddTripModal}
        toggleAddOpen={setOpenAddTripModal}
      />

      <ModifyTripModal
        role={user.role}
        modifyOpen={openModifyTripModal}
        toggleModifyOpen={toggleModifyTripModal}
        tripName={tripName}
        tripCity={tripCity}
        tripCountry={tripCountry}
        tripDescription={tripDescription}
        tripId={tripSelected}
        tripStatus={tripStatus}
        tripSelected={tripSelected}
        handleModifyOpen={handleModifyOpen}
        archive_button={false}
        completed_button={true}
        handleConfirmOpen={handleConfirmOpen}
        handleSetStatusAction={handleSetStatusAction}
        archiveTrip={archiveTrip}
        completeTrip={completeTrip}
        uploadingTrip={uploadingTrip}
        handleImagePreviewOpen={handleImagePreviewOpen}
      />

      <DeleteTripModal
        deleteOpen={deleteOpen}
        tripName={tripName}
        selected_projects={tripSelected}
        handle_delete_trip={handle_delete_trip}
        handleDeleteOpen={handleDeleteOpen}
      />

      <ConfirmModalCommon
        modal_open={confirmOpen}
        interrogative={`Are you sure you want to move Project: ${tripName} to the ${statusAction} Tab?`}
        button_1_text={"Cancel"}
        button_2_text={"Confirm"}
        button_1_action={handleConfirmOpen}
        button_2_action={
          statusAction === "Uploading"
            ? (e) => handleUploadingTrip(tripSelected)
            : statusAction === "Ready"
              ? (e) => handleCompleteTrip(tripSelected)
              : statusAction === "Archived"
                ? (e) => handleArchiveTrip(tripSelected)
                : null
        }
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {user.id === 25 || user.id === 232 ? (
            <button
              style={{
                boxShadow: "1px 1px 6px 2px gray",
                textAlign: "center",
                lineHeight: "2.0em",
                height: "4vh",
                backgroundColor: "#203124",
                color: "white",
              }}
            >
              Clear Bookmarks
            </button>
          ) : null}
        </div>

        <Tabs onSelect={handleTabChange}>
          <TopDiv>
            <TabList>
              <Tab>
                <strong>Ready</strong>
              </Tab>
              <Tab>
                <strong>New / Uploading</strong>
              </Tab>
              <Tab>
                <strong>Archived</strong>
              </Tab>
            </TabList>
            <ButtonDivComponent
              role={"admin"}
              button1={1 === tabKey}
              button2={true}
              button3={true}
              button1_text={"Add"}
              button2_text={"Edit"}
              button3_text={"Delete"}
              button1_action={handleOpenModal}
              button2_action={handleModifyOpen}
              button3_action={handleDeleteOpen}
            />
          </TopDiv>

          <TabPanel>
            <ReadyTripTab
              role={"admin"}
              tab={"ready"}
              handleClickSelectTrip={handleClickSelectTrip}
              view_all_project_sequences={view_all_project_sequences}
            />
          </TabPanel>

          <TabPanel>
            <NewTripTab
              role={"admin"}
              tab={"new"}
              handleClickSelectTrip={handleClickSelectTrip}
              view_all_project_sequences={view_all_project_sequences}
            />
          </TabPanel>

          <TabPanel>
            <ArchivedTripTab
              role={"admin"}
              tab={"archived"}
              handleClickSelectTrip={handleClickSelectTrip}
              view_all_project_sequences={view_all_project_sequences}
            />
          </TabPanel>
        </Tabs>
        {!redirect ? null : <Navigate push to="/viewer" />}
      </div>
    </>
  );
};
