import styled from "styled-components";

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background-color: white;
`;

export const PlaceholderImage = styled.img`
  width: 45%;
  height: auto;
`;

export const Title = styled.h1`
  font-size: 50px;
`;
