import React, { useEffect, useState } from "react";
import Sidebar from "./components/sidebar/sidebar";
import { Outlet, useLocation } from "react-router-dom";

export const Root = () => {
  const location = useLocation();
  const [margin, setMargin] = useState("1.5vw");

  useEffect(() => {
    if (location.pathname === "/viewer") {
      setMargin("0vw");
    } else {
      setMargin("1.5vw");
    }
  }, [location.pathname]);
  return (
    <>
      <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
        <Sidebar />

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            margin: margin,
          }}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
};
