import styled from "styled-components";

export const DirectionImage = styled.img`
  width: 5%;
  height: 3%;
  opacity: 0.8;
  transform-origin: bottom;
  transform: rotate(${(props) => props.rotation});
`;
export const ProjectIcon = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.74);
  border-radius: 6px;
  margin-bottom: 2px;
  margin-left: 2px;
  padding: 3px;
  z-index: 9999;
`;

export const Input = styled.input`
  box-sizing: inherit;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
  width: 90%;
  display: flex;
  align-self: center;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 6px;
`;

export const SelectWrapper = styled.div`
  margin-left: 2em;
  margin-right: 2em;
`;
